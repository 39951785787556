import Heading from 'new-components/Heading'
import CardFaq from 'new-components/FaqComponents/CardFaq'
import { gene02, productsFaq, secondCardImage } from 'assets/img'
import { CardBox, StartYouJourneySection } from './styles'

function ContinueYourJourney() {
  return (
    <StartYouJourneySection>
      <Heading fontWeight={600} verticalSpace="small">
        Continue sua jornada com a <span>Cannect</span>:
      </Heading>

      <CardBox>
        <CardFaq
          title="Cannect Produtos"
          image={productsFaq}
          link="https://www.cannect.life/produtos/1?translated_name="
        />
        <CardFaq title="Cannect Cuida" image={secondCardImage} link="https://www.cannect.life/cuida" />
      </CardBox>
    </StartYouJourneySection>
  )
}

export default ContinueYourJourney
