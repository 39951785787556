import styled, { css } from 'styled-components'

interface ButtonUploadProps {
  isValue: boolean
  status: 'pending' | 'approved' | 'denied'
}

const colorVariant = {
  approved: () => css`
    background-color: #0f5132;
  `,
  denied: () => css`
    background-color: #9f0b26;
  `,
  pending: () => css`
    background-color: #dc9909;
  `
} as any

export const Wrapper = styled.main`
  width: 100%;
  background: #f6f6f6;
  border-radius: 8px;
  overflow: hidden;
`

export const UploadWrapper = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 160px;
  min-height: 110px;
  width: 100%;

  .upload-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 70%;
    padding: 16px;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8px 0;
    font-size: 10px;
    color: #fff;
    padding: 4px 0;
    width: 140px;
    ${({ status }) => {
      return colorVariant[status] || colorVariant.pending
    }};
    border-radius: 10px;
  }
`

export const TitleUpload = styled.h2<any>`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;

  svg {
    margin-left: 5px;
    color: #91908f;

    :hover {
      cursor: pointer;
      color: rebeccapurple;
    }
  }
`

export const NameFile = styled.p`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-top: 19px;
  color: #646464;
  width: 100%;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const ButtonUpload = styled.button<any>`
  background: ${({ isValue }) => (isValue ? '#115850' : 'rgba(196,113,18,0.71)')};
  display: flex;
  font-family: 'Lato';
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  width: 100px;
  height: 100%;
  color: white;

  .button {
    height: 100%;
  }

  svg {
    color: white;
    width: 19px;
    height: 19px;
    margin-bottom: 16px;
  }
`
