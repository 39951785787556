/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import AppProvider from './hooks'
import Routes from './routes'
import CookiesConsent from 'components/CookiesConsent'
import ModalPrescriberTerms from 'components/ModalPrescriberTerms'
import { QueryClientProvider } from '@tanstack/react-query'
import ReactGA from 'react-ga4'
import { queryClient } from './query-client'

Sentry.init({
  dsn: 'https://5847ca586bf3cf52c09a68e9d7aebbd8@o4506678724198400.ingest.us.sentry.io/4506678744645632',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://www.cannect.life/'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0
})

function App() {
  useEffect(() => {
    ReactGA.initialize('G-CL2XYTV96X')
  }, [])

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AppProvider>
          <Routes />
          <CookiesConsent />
          <ModalPrescriberTerms />
        </AppProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App
