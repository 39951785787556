import Checkbox from 'components/Checkbox'
import { Input } from 'components/Input'
import { InputSearch } from 'components/InputSearch'
import React, { useState } from 'react'
import * as Style from './styles'

interface PrescriberFilterTypes {
  filter: {
    name: string
    expertise: string
    patology: string
  }
  checkboxFilter: {
    health_insurance: boolean
    online_appointment: boolean
    presencial_appointment: boolean
  }
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleCheckbox: (type: 'health_insurance' | 'online_appointment' | 'presencial_appointment') => void
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

function PrescriberFilter(props: PrescriberFilterTypes) {
  const { name, expertise, patology } = props.filter
  const { presencial_appointment, health_insurance, online_appointment } = props.checkboxFilter
  const { handleChange, onSubmit, handleCheckbox } = props

  return (
    <Style.Container>
      <form onSubmit={onSubmit}>
        <Style.TextInputContainer>
          <InputSearch
            placeholder="Pesquisar por nome"
            value={name}
            width="48%"
            outlined
            name="name"
            onChange={handleChange}
          />

          <Input
            placeholder="Filtrar por especialidade"
            value={expertise}
            width="23%"
            name="expertise"
            onChange={handleChange}
          />

          <Input
            placeholder="Filtrar por patologia"
            width="23%"
            value={patology}
            name="patology"
            onChange={handleChange}
          />
        </Style.TextInputContainer>

        <Style.CheckboxContainer>
          <Style.CheckboxOption>
            <p>Tipo de atendimento:</p>
            <Style.CheckboxWrapper>
              <Checkbox
                checked={online_appointment}
                onClick={() => handleCheckbox('online_appointment')}
                label="Teleatendimento"
              />
              <Checkbox
                checked={presencial_appointment}
                onClick={() => handleCheckbox('presencial_appointment')}
                label="Atendimento presencial"
              />
            </Style.CheckboxWrapper>
          </Style.CheckboxOption>
        </Style.CheckboxContainer>
        <button type="submit">Filtrar</button>
      </form>
    </Style.Container>
  )
}

export default PrescriberFilter
