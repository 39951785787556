import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { LuChevronDown } from 'react-icons/lu'

import { cn } from '@cannect/lib/utils'
import { forwardRef, ComponentPropsWithoutRef, ElementRef } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'

const { Root } = AccordionPrimitive

const Item = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn('border-b border-solid border-transparent border-b-gray-200', className)}
    {...props}
  />
))
Item.displayName = 'AccordionItem'

const Trigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header className="flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'group flex flex-1 items-center justify-between border-transparent bg-transparent py-4 font-sans text-sm font-medium text-gray-900 underline-offset-8 transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
        className
      )}
      {...props}>
      {children}
      <FiPlusCircle className="h-6 w-6 shrink-0 transition-transform duration-200 group-data-[state=open]:hidden" />
      <FiMinusCircle className="h-6 w-6 shrink-0 transition-transform duration-200 group-data-[state=closed]:hidden" />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
Trigger.displayName = AccordionPrimitive.Trigger.displayName

const Content = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden border-b-2 text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}>
    <div className={cn('pb-4 pt-0', className)}>{children}</div>
  </AccordionPrimitive.Content>
))

Content.displayName = AccordionPrimitive.Content.displayName

export { Root, Item, Trigger, Content }
