import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'

const MiniCheckoutContext = createContext(null as any)

interface TokenApiData {
  tokenApi: string | null
  setTokenApi: Dispatch<SetStateAction<string | null>>
  deleteTokenApi: () => void
}

function MiniCheckoutProvider({ children }: { children: ReactNode }) {
  const [modalCheckout, setModalCheckout] = useState(false)

  const closeCheckout = () => {
    setModalCheckout(false)
  }

  const openCheckout = () => {
    setModalCheckout(true)
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <MiniCheckoutContext.Provider value={{ modalCheckout, closeCheckout, openCheckout }}>
      {children}
    </MiniCheckoutContext.Provider>
  )
}

function useMiniCheckout() {
  return useContext(MiniCheckoutContext)
}

export { useMiniCheckout, MiniCheckoutProvider }
