import React from 'react'
import { Button } from 'design-cannect'
import { useHistory } from 'react-router'
import { Container } from './styles'
import Text from '../../Text'

interface AccountHeaderProps {
  title: string
}
function AccountHeader({ title }: AccountHeaderProps) {
  const detalhes = title === 'Detalhes de conta'
  const pedidos = title === 'Pedidos' || title === 'Detalhes do pedido' || title === 'Documentos faltantes'
  const receitas = title === 'Receitas'
  const dependentes = title === 'Dependentes'

  const history = useHistory()

  function handleRedirect(path: string) {
    history.push(path)
  }
  return (
    <Container>
      <div className="links">
        <Button secondary={detalhes} onClick={() => handleRedirect('/conta')}>
          Detalhes de conta
        </Button>
        {/* <Button secondary={dependentes}>Dependentes</Button> */}
        <Button secondary={receitas} onClick={() => handleRedirect('/minhas-receitas')}>
          Receitas
        </Button>
        {/* <Button secondary={pedidos}>Pedidos</Button> */}
      </div>
      {/* <Text type="h2" bold color="tertiary">
        {title}
      </Text> */}
    </Container>
  )
}

export default AccountHeader
