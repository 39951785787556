import { forwardRef, useState } from 'react'

import { Spin, Upload, UploadProps } from 'antd'
import { MdOutlineFileUpload } from 'react-icons/md'
import { ImSpinner2 } from 'react-icons/im'
import { toast } from 'react-toastify'
import { Button } from '@cannect/new-components/atoms'
import { TFileButtonProps, defaultAcceptFormats } from './types'
import { filterAcceptFormats, formatFileSize, typeErrors } from './utils'
import { twMerge } from 'tailwind-merge'

export const FileButton = forwardRef(
  (
    {
      buttonLabel = 'enviar',
      isDisabled = false,
      maxSize,
      onUploadFile,
      customTrigger,
      acceptFormats = defaultAcceptFormats,
      onError,
      buttonClasses,
      variant = 'default',
      size,
      showIcon = true
    }: TFileButtonProps,
    _
  ) => {
    const [isLoading, setIsLoading] = useState(false)

    const acceptString = acceptFormats.map(item => item).join(', ')

    const uploadProps: UploadProps = {
      disabled: isDisabled,
      openFileDialogOnClick: !customTrigger,
      itemRender: () => null,
      accept: acceptString,
      multiple: false,
      beforeUpload: (file: File) => {
        const [size, unit] = formatFileSize(file?.size).split(' ')
        const isBiggerThanMaxSize = maxSize
          ? ['GB', 'TB'].includes(unit) || (unit === 'MB' && parseInt(size, 10) > maxSize)
          : false

        const isInvalidFormat = !Object.keys(filterAcceptFormats(acceptFormats)).includes(file.type)

        if (isBiggerThanMaxSize) {
          toast.error('Não é possível enviar arquivos com mais de 20MB.')
          onError?.(typeErrors.SIZE)
          return false
        }
        if (isInvalidFormat) {
          toast.error('Formato de arquivo não aceito.')
          onError?.(typeErrors.FORMAT)
          return false
        }

        return true
      },

      async customRequest({ file }) {
        try {
          setIsLoading(true)
          await onUploadFile(file as File)

          onError?.(undefined)
        } catch (error) {
          onError?.(typeErrors.DEFAULT)
        } finally {
          setIsLoading(false)
        }
      }
    }

    return (
      <Button
        size={size ?? undefined}
        variant={variant}
        data-testid="custom-trigger"
        onClick={customTrigger || undefined}
        className={twMerge('relative cursor-pointer border-none outline-none', buttonClasses)}
      >
        <Upload {...uploadProps} data-testid="upload-button">
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin tip="Carregando..." indicator={<ImSpinner2 className="animate-spin text-white" />} />
            </div>
          ) : (
            <div className="flexCenter h-full w-full flex-col text-white">
              <p>{buttonLabel}</p>
              {showIcon && <MdOutlineFileUpload className="hidden md:flex" />}
            </div>
          )}
        </Upload>
      </Button>
    )
  }
)
