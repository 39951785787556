import { ButtonProps } from '../Button'

export type TFormats =
  | '.pdf'
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.csv'
  | '.xlsx'
  | '.mp4'
  | '.zip'
  | '.doc'
  | '.docx'
  | '.txt'

export const acceptDefaults = {
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'video/mp4': ['.mp4'],
  'application/zip': ['.zip'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt']
}

export type TAcceptDefaults = typeof acceptDefaults

export type TAcceptFormats = keyof TAcceptDefaults

export const defaultAcceptFormats: TFormats[] = ['.pdf', '.jpg', '.jpeg', '.png']

export type TFileButtonProps = {
  buttonClasses?: string
  onUploadFile: (file: File) => void
  buttonLabel?: string
  isDisabled?: boolean
  maxSize?: number
  customTrigger?: () => void
  acceptFormats?: TFormats[]
  onError?: (error?: TItemError) => void
  showIcon?: boolean
} & Pick<ButtonProps, 'variant' | 'size'>

export type TErrorType = 'DEFAULT' | 'FORMAT' | 'SIZE'

export type TItemError = {
  message: string
}
