import { ReactNode, useState } from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { Button } from 'design-cannect'
import { Modal } from 'components/Modal'
import * as Style from './styles'

interface FilterByProps {
  children: ReactNode
}
export default function FilterBy({ children }: FilterByProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [isFilterByModalOpened, setIsFilterByModalOpened] = useState(false)

  return (
    <Style.FilterByContainer>
      {!isMobile ? (
        children
      ) : (
        <>
          <button type="button" onClick={() => setIsFilterByModalOpened(state => !state)}>
            Filtrar por:
          </button>
          <Modal isOpened={isFilterByModalOpened} onRequestClose={() => setIsFilterByModalOpened(false)}>
            {children}
          </Modal>
        </>
      )}
    </Style.FilterByContainer>
  )
}
