import RadioInput from 'components/RadioInput'
import { Input } from 'design-cannect'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import InvisibleButton from 'components/InvisibleButton'
import { svgArrow } from 'assets/svg'
import { ButtonsContainer } from 'pages/CannectAssistant/styles'
import { useState } from 'react'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import Loading from 'components/Loading'
import { Link } from 'react-router-dom'
import SectionsWrapper from '../SectionsWrapper'
import Section from '../SectionsWrapper/Section'
import * as Style from './styles'
import * as NeedHelpStyle from '../NeedHelp/styles'
import CbdForm from './CbdForm'
import ThcForm from './ThcForm'
import CbgForm from './CbgForm'
import { ButtonBack } from './styles'
import Button from 'new-components/Button'

export default function InitialQuestions() {
  const {
    administrationWay,
    setAdministrationWay,
    isCbdPredominant,
    isCbgPredominant,
    isCbdEqualsThcPredominant,
    isThcPredominant,
    handlePredominantCannabinoidChange,
    dailyDosage,
    setDailyDosage,
    dailyFrequency,
    setDailyFrequency,
    isChosenQuestionsFinished,
    setIsChosenQuestionsFinished,
    setStep,
    fetchAlreadyKnowProducts,
    fetchNeedHelpProducts,
    loading
  } = useCannectAssistant()
  const [isErrorDialogueOpened, setIsErrorDialogueOpened] = useState(false)
  const [isNoProductFoundDialogueOpened, setIsNoProductFoundDialogueOpened] = useState(false)

  async function handleNeedHelpContinueClick() {
    if (administrationWay === 'systemic' && (dailyDosage.length === 0 || dailyFrequency.length === 0)) {
      setIsErrorDialogueOpened(true)
      return
    }
    const quantityOfProductsFound = await fetchNeedHelpProducts(1)

    if (quantityOfProductsFound === 0) {
      setIsNoProductFoundDialogueOpened(true)
    } else {
      setStep(2)
    }
  }

  function handleIsCbdEqualsThcPredominantChange() {
    handlePredominantCannabinoidChange('cbdEqualsThc')
    setIsChosenQuestionsFinished(true)
  }

  return (
    <Style.InitialQuestionsContainer>
      <SectionsWrapper>
        <Section>
          <h2>Qual a via de administração?</h2>
          <NeedHelpStyle.InputsWrapper>
            <RadioInput
              name="topic"
              id="topic"
              label="Tópico"
              checked={administrationWay === 'topic'}
              onChange={() => setAdministrationWay('topic')}
            />
            <RadioInput
              name="systemic"
              id="systemic"
              label="Sistêmico"
              checked={administrationWay === 'systemic'}
              onChange={() => setAdministrationWay('systemic')}
            />
          </NeedHelpStyle.InputsWrapper>
        </Section>
        <Section>
          <h2 style={{ marginBottom: '6px' }}>Qual o canabinoide predominante?</h2>
          <p className="link">QUERO CONHECER OS CANNABINÓIDES E SUAS COMPROVAÇÕES</p>
          <NeedHelpStyle.InputsWrapper>
            <RadioInput
              name="cbd"
              id="cbd"
              label="CBD"
              checked={isCbdPredominant}
              onChange={() => handlePredominantCannabinoidChange('cbd')}
            />
            <RadioInput
              name="thc"
              id="thc"
              label="THC"
              checked={isThcPredominant}
              onChange={() => handlePredominantCannabinoidChange('thc')}
            />
            <RadioInput
              name="cbg"
              id="cbg"
              label="CBG"
              checked={isCbgPredominant}
              onChange={() => handlePredominantCannabinoidChange('cbg')}
            />
            <RadioInput
              name="cbdEqualsThc"
              id="cbdEqualsThc"
              label="CBD = THC"
              checked={isCbdEqualsThcPredominant}
              onChange={() => handleIsCbdEqualsThcPredominantChange()}
            />
          </NeedHelpStyle.InputsWrapper>
        </Section>
        {isCbdPredominant && <CbdForm />}
        {isThcPredominant && <ThcForm />}
        {isCbgPredominant && <CbgForm />}

        {administrationWay === 'systemic' && isChosenQuestionsFinished && (
          <>
            <Section>
              <h2>Qual a posologia diária do canabinoide predominante? (mg/dia)</h2>
              <NeedHelpStyle.InputsWrapper>
                <Input value={dailyDosage} onChange={event => setDailyDosage(event.target.value)} />
              </NeedHelpStyle.InputsWrapper>
            </Section>
            <Section>
              <h2>Qual a frequência diária?</h2>
              <NeedHelpStyle.InputsWrapper>
                <Input
                  value={dailyFrequency}
                  onChange={event => setDailyFrequency(event.target.value)}
                  onKeyPress={event => event.code === 'Enter' && handleNeedHelpContinueClick()}
                />
              </NeedHelpStyle.InputsWrapper>
            </Section>
          </>
        )}

        <Section>
          <ButtonsContainer>
            <Button background="black" onClick={() => setStep(0)}>
              Voltar
            </Button>

            <Button
              background="green"
              onClick={() => handleNeedHelpContinueClick()}
              disabled={!isChosenQuestionsFinished}
            >
              Continuar
            </Button>
          </ButtonsContainer>
        </Section>
      </SectionsWrapper>

      {isErrorDialogueOpened && (
        <ConfirmationDialogue
          isOpened={isErrorDialogueOpened}
          onRequestClose={() => setIsErrorDialogueOpened(false)}
          onConfirmationClick={() => setIsErrorDialogueOpened(false)}
          title="Há campos obrigatórios não preenchidos!"
          description="Os campos de posologia e frequência diária são obrigatórios."
          confirmationButtonText="Entendi!"
          confirmationOnlyDialogue
        />
      )}

      {isNoProductFoundDialogueOpened && (
        <ConfirmationDialogue
          isOpened={isNoProductFoundDialogueOpened}
          onRequestClose={() => setIsNoProductFoundDialogueOpened(false)}
          onConfirmationClick={() => setIsNoProductFoundDialogueOpened(false)}
          title="Nenhum produto encontrado!"
          description="Nenhum produto foi encontrado para a pesquisa inserida."
          confirmationButtonText="Entendi!"
          confirmationOnlyDialogue
        />
      )}

      {loading && <Loading loading={loading} />}
    </Style.InitialQuestionsContainer>
  )
}
