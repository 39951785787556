import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import { FormEvent, useEffect, useState } from 'react'
import { pngVidaaSLogo } from 'assets/img'
import { clearMask, formatCpf, keepOnlyDigits } from 'utils/formatters'
import api from 'services/api'
import Loading from 'components/Loading'
import { DigitalSignatureToken } from 'hooks/useCannectAssistant/types'
import * as Style from './styles'

const VIDAAS_ID_TOKEN = '@CANNECT_VIDAAS_TOKEN'

type Expiration = 3600 | 7200 | 14400 | 28800 | 43200 | 86400

type VidaasProps = {
  onSign: (success: boolean) => void
  onCertificateChoose: (choice: string) => void
}

type FetchAuthorizationResponse = {
  code: string
}

type FetchAuthenticationResponse = {
  token: string
}

export default function VidaaS({ onSign, onCertificateChoose }: VidaasProps) {
  const [expirationTime, setExpirationTime] = useState<Expiration>(86400)
  const [cpf, setCpf] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('@CANNECT_SIGNED_USER')) {
      const connectedUser = JSON.parse(localStorage.getItem('@CANNECT_SIGNED_USER') as string)
      setCpf(connectedUser.cpf)
    }

    if (localStorage.getItem('@CANNECT_VIDAAS_TOKEN')) {
      const vidaasToken: DigitalSignatureToken = JSON.parse(localStorage.getItem('@CANNECT_VIDAAS_TOKEN') as string)

      const currentDate = new Date().getTime()

      if (Number(vidaasToken.expiration) > currentDate) {
        onSign(true)
      }
    }

    const handleMessageFromIframe = (event: MessageEvent) => {
      if (event.data.type === 'success') {
        const code = event.data.data
        handleSignature(code)
      }
    }

    window.addEventListener('message', handleMessageFromIframe)

    return () => {
      window.removeEventListener('message', handleMessageFromIframe)
    }
  }, [])

  async function handleSignature(code: string) {
    setLoading(true)
    try {
      const response = await api.post<FetchAuthenticationResponse>(`/signature_authentication`, {
        username: clearMask(cpf),
        password: code,
        lifetime: expirationTime,
        provider: 'vidaas',
        qrcode: true
      })
      const tokenObject = {
        token: response.data.token,
        expiration: calculateExpiration()
      }
      localStorage.setItem(VIDAAS_ID_TOKEN, JSON.stringify(tokenObject))
      onSign(true)
    } catch (err) {
      console.error(err)
      onSign(false)
    }
    setLoading(false)
  }

  function calculateExpiration() {
    let currentDate = new Date().getTime()
    currentDate += expirationTime * 1000
    return currentDate
  }

  const handleCertificateType = () => {
    onCertificateChoose('vidaas')
  }

  return (
    <Style.VidaasContainer>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <iframe title="qrcode" src={import.meta.env.VITE_APP_VIDAS_URL} height="600" width="700" frameBorder="0" />
      </div>
      <Style.NotValidateContent>
        <Style.NotValidateText>
          Validou e não foi redirecionado?{' '}
          <Style.NotValidateLink onClick={handleCertificateType}>Clique aqui</Style.NotValidateLink>
        </Style.NotValidateText>
      </Style.NotValidateContent>
      {loading && <Loading loading={loading} />}
    </Style.VidaasContainer>
  )
}
