/* eslint-disable react-hooks/rules-of-hooks */
import { Control, FieldValues, Path } from 'react-hook-form'
import React from 'react'
import { type VariantProps } from 'class-variance-authority'
import { useIMask } from 'react-imask'
import { FormController } from '@cannect/new-components/atoms'
import { Input, InputProps } from '@cannect/new-components/atoms/Form/Input'
import { formItemVariants } from '@cannect/new-components/atoms/FormController'
import { FactoryArg } from 'imask'

type InputFieldProps = Omit<InputProps, 'form'> & {
  label?: string
  description?: string
  isLoading?: boolean
  mask?: FactoryArg[] | string[]
  unmaskOnChange?: boolean
} & VariantProps<typeof formItemVariants>

export const InputField = <TFieldValues extends FieldValues>({
  control,
  name,
  isLoading = false,
  label,
  description,
  size: inputSize,
  variant: inputVariant,
  alignment: formItemAlignment,
  mask,
  unmaskOnChange = false,
  ...props
}: InputFieldProps & {
  name: Path<TFieldValues>
  control: Control<TFieldValues, any>
}) => {
  const LABEL_SIZES = {
    default: 'text-sm',
    small: 'text-sm',
    medium: 'text-lg'
  }

  return (
    <FormController.FormField
      control={control}
      name={name}
      data-testid="input-field"
      render={({ field, fieldState }) => {
        const { ref: inputRef, unmaskedValue } = useIMask<HTMLInputElement>(
          {
            mask
          },
          {
            onAccept: (value: string) => {
              if (!mask) return
              // when input has mask, it should use onAccept to update the value
              field.onChange(unmaskOnChange ? unmaskedValue : value)
            }
          }
        )
        return (
          <FormController.FormItem alignment={formItemAlignment}>
            {label && (
              <FormController.FormLabel className={`${inputSize ? LABEL_SIZES[inputSize] : 'text-sm'} text-muted`}>
                {label}
              </FormController.FormLabel>
            )}
            <FormController.FormControl>
              <Input
                isInvalid={!!fieldState.error || !!fieldState.invalid}
                size={inputSize}
                variant={inputVariant}
                onBlur={field.onBlur}
                name={name}
                ref={inputRef}
                onChange={!mask ? field.onChange : undefined}
                value={field.value}
                {...props}
              />
            </FormController.FormControl>
            {description && <FormController.FormDescription>{description}</FormController.FormDescription>}
            <FormController.FormMessage />
          </FormController.FormItem>
        )
      }}
    />
  )
}

InputField.displayName = 'InputField'
