import { Button, Separator, Typography, Icons, TypeWriteText } from '@cannect/new-components/atoms'
import { LuCalendar } from 'react-icons/lu'
import Cards from '@cannect/new-components/molecules/Cards'
import { useHome } from './useHome'
import { treatmentCards, typeWriteTexts } from './constants'
import { parsePostImageAltText } from '@cannect/utils/strapi'
import { AutoCarousel, CardPhotoExpand, CommonQuestionsSection } from '@cannect/new-components/organisms'

export const HomeUI = ({ highlightedPrescribers, postsList, handleGoTo, brandsList }: ReturnType<typeof useHome>) => {
  return (
    <div className="w-full max-w-full bg-secondary-100">
      <section className="bg-hero-desktop bg-cover bg-center bg-no-repeat">
        <div className="container flex min-h-[calc(100vh-74px)] flex-col-reverse items-center justify-start gap-8 px-4 py-5 md:min-h-[calc(100vh-106px)] md:flex-row md:justify-between md:px-21 md:py-24">
          <div className="flex flex-col items-center gap-4 md:items-start">
            <Typography.Heading
              type="headingTwo"
              weight="semibold"
              className="hidden leading-10 text-primary-200 md:block">
              Cannect é <TypeWriteText text={typeWriteTexts} loop className="font-semibold text-white" />
            </Typography.Heading>

            <Typography.Heading
              weight="regular"
              type="headingFour"
              className="hidden max-w-[486px] text-gray-50 md:block">
              Descubra uma nova forma de se cuidar com o poder da cannabis medicinal. Saúde como deve ser, para você.
            </Typography.Heading>

            <Button
              onClick={() => handleGoTo('/agendamento')}
              className="w-[318px] max-w-full"
              size="lg"
              icon={<LuCalendar size={24} />}
              iconPlacement="left"
              variant="gray_light">
              Faça uma consulta
            </Button>
            <Button
              size="lg"
              variant="outline_white"
              className="mt-2 w-[318px] max-w-full"
              onClick={() => handleGoTo('/produtos/1')}>
              Já tenho prescrição médica
            </Button>
          </div>
          <div className="flex max-w-[336px] flex-col items-start gap-6 self-end">
            <Typography.Heading
              type="headingFour"
              level={4}
              className="font-ebGaramond leading-[28.8px] text-gray-50 md:text-3xl md:leading-[34.8px]">
              &ldquo;A vida melhorou depois que iniciei meu tratamento.&rdquo;
            </Typography.Heading>
            <Typography.Heading type="headingSix" level={6} weight="light" className="italic text-primary-300">
            Isabel, 50 anos.
            </Typography.Heading>
          </div>
        </div>
      </section>
      <section className="container flex flex-col items-center gap-[32px] py-section text-center lg:gap-[42px]">
        <Typography.Heading type="headingThree" className="leading-10 text-primary-700 lg:text-4xl">
          Cuidamos de você em todo o processo
        </Typography.Heading>
        <div className="flex w-[1164px] max-w-full flex-row items-start justify-start gap-[24px] overflow-x-auto">
          <Cards.HomeFeature
            icon={Icons.Clipboard}
            title="Acolhimento"
            subtitle="Nossa equipe está disponível diariamente para te acolher e dar toda a confiança necessária para embarcar numa rotina de cuidados."
          />
          <Cards.HomeFeature
            icon={Icons.FileCheck}
            title="Consulta médica"
            subtitle="Acompanhamento médico é a chave para um eficaz tratamento com cannabis, e os melhores prescritores estão aqui."
          />
          <Cards.HomeFeature
            icon={Icons.Medicine}
            title="Produtos importados"
            subtitle="Nossa curadoria atesta a qualidade do portfólio mais completo do Brasil. Com a sua receita em mãos, você garante o acesso aos mais  diversos produtos."
          />
          <Cards.HomeFeature
            icon={Icons.HeartPulse}
            title="Acompanhamento contínuo"
            subtitle="Com uma equipe especializada, o Cannect Cuida garante o acompanhamento profissional do seu tratamento."
          />
        </div>
      </section>
      <section className="container flex flex-col items-center gap-[32px] pb-section text-center lg:gap-[42px]">
        <Typography.Heading type="headingThree" className="leading-10 text-primary-700 lg:text-4xl">
          Coordenando o cuidado de sua saúde
        </Typography.Heading>
        <div className="w-full overflow-x-hidden rounded-ee-md rounded-es-md rounded-se-lg rounded-ss-lg">
          <CardPhotoExpand items={treatmentCards} />
        </div>
      </section>
      <section className="container flex flex-col items-center gap-[32px] pb-section text-center">
        <Typography.Heading type="headingThree" className="leading-10 text-primary-700 lg:text-4xl">
          Com médicos parceiros
        </Typography.Heading>

        <div className="grid grid-cols-1 gap-[24px] px-0 pb-2 pt-0 sm:grid-cols-2 2xl:grid-cols-4">
          {highlightedPrescribers &&
            highlightedPrescribers.length > 0 &&
            highlightedPrescribers.map((prescriber) => (
              <Cards.HighlitedPrescriber key={prescriber.id} prescriberDetail={prescriber} />
            ))}
        </div>
        <Button
          onClick={() => handleGoTo('/agendamento')}
          variant="outline"
          className="mt-4 w-full text-base md:w-[397px]">
          Escolher outros médicos
        </Button>
      </section>
      <section>
        <div className="flex flex-col items-center justify-center gap-6 md:gap-8">
          <Typography.Heading type="headingThree" className="px-4 text-center leading-10 text-neutral-700 lg:text-4xl">
            E marcas selecionadas
          </Typography.Heading>

          <div className="flex max-w-full overflow-x-auto md:gap-x-20 lg:gap-x-32">
            <AutoCarousel items={brandsList} />
          </div>
          <Button
            onClick={() => handleGoTo('/marcas')}
            variant="outline"
            className="mt-4 w-[calc(100%-2rem)] text-base md:w-[397px]">
            Ver todos parceiros
          </Button>
        </div>
      </section>
      <section className="container flex flex-col items-center gap-[32px] py-section text-center">
        <Typography.Heading type="headingTwo" className="leading-10 text-primary-700">
          Cannect blog
        </Typography.Heading>
        <div className="grid grid-cols-1 gap-[24px] pb-2 md:grid-cols-3 md:gap-[32px]">
          {postsList &&
            postsList.length > 0 &&
            postsList.map((post, index) => (
              <div className="flex items-center justify-between">
                <div className="flex h-[118px] gap-5 md:w-[404px]">
                  <img
                    className="h-[118px] w-[118px] min-w-[118px] rounded-[12px] object-cover"
                    src={`${import.meta.env.VITE_APP_STRAPI_URL}${
                      post?.attributes?.image?.data?.attributes?.formats?.large?.url ||
                      post?.attributes?.image?.data?.attributes?.formats?.thumbnail?.url
                    }`}
                    alt={parsePostImageAltText(post?.attributes?.image?.data?.attributes)}
                  />
                  <div className="flex flex-col items-start justify-between text-left">
                    <Typography.Text type="paragraphOne" className="line-clamp-3 font-bold text-primary-900">
                      {post?.attributes?.title}
                    </Typography.Text>
                    <Button
                      className="mt-3"
                      onClick={() => handleGoTo(`/blog/${post?.attributes?.slug}/${post?.attributes?.slug}`)}
                      variant="outline"
                      size="md">
                      Saiba mais
                    </Button>
                  </div>
                </div>
                <Separator
                  orientation="vertical"
                  className={index === postsList.length - 1 ? 'hidden' : 'mx-[64px] hidden h-[65px] bg-secondary-300'}
                />
              </div>
            ))}
        </div>
        <Button onClick={() => handleGoTo('/blog')} variant="outline" className="mt-4 w-full text-base md:w-[397px]">
          Ver outros artigos
        </Button>
      </section>
      <section className="bg-home-faq bg-center object-cover">
        <div className="container flex flex-col items-center justify-center py-section lg:py-[118px]">
          <Typography.Heading type="headingThree" className="leading-10 text-gray-50 lg:text-4xl">
            Dúvidas?
          </Typography.Heading>

          <Typography.Text type="paragraphTwo" className="mt-[16px] text-center text-gray-50">
            Envie uma mensagem para o <strong className="font-sans">time Cannect</strong>
          </Typography.Text>

          <Button
            className="mt-[24px] w-full text-base md:w-[397px]"
            variant="outline_white"
            onClick={() => window.open('https://atendimento.cannect.life/atendimento-cannect', '_blank')}>
            Falar com o atendimento
          </Button>
        </div>
      </section>
      <CommonQuestionsSection />
    </div>
  )
}
