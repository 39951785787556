/* eslint-disable import/no-unresolved */
/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState, useRef } from 'react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import * as S from './styles'
import api from '@cannect/services/api'
import DatePickerPopup from '@cannect/new-components/CustomDatePicker'
import { useFormContext } from 'react-hook-form'
import FormConfirmation from './FormConfirmation'
import TimeList from './TimeList'
import InfoContainer from './InfoContainer'
import EventConfirmation from './EventConfirmation'
import { toZonedTime } from 'date-fns-tz'

const USER_IDS = ['831745674390276045']

type EventInput = {
  title: string
  start: string
  end: string
}

const CalendarOnboardingContainer: React.FC = () => {
  const [events, setEvents] = useState<{ date: string; time: string; userId: string }[]>([])
  const [availableDates, setAvailableDates] = useState<string[]>([])
  const [selectedEvent, setSelectedEvent] = useState<EventInput | null>(null)
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [availableTimes, setAvailableTimes] = useState<string[]>([])
  const [selectedTime, setSelectedTime] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [timestamp, setTimestamp] = useState<number | null>(null)
  const [selectedEventDetails, setSelectedEventDetails] = useState<{
    date: string
    time: string
    userId: string
  } | null>(null)
  const [dateSelected, setDateSelected] = useState<string | null>()
  const [confirmationData, setConfirmationData] = useState<any | null>(null)

  const timeListRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLDivElement>(null)

  const { watch } = useFormContext()

  const currentDate = watch('scheduling_date')

  const [screenState, setScreenState] = useState<'selectFilter' | 'selectDate'>(
    currentDate ? 'selectDate' : 'selectFilter'
  )

  useEffect(() => {
    const fetchAvailabilities = async () => {
      const userPromises = USER_IDS.map(async userId => {
        try {
          const response = await api.get(`kalendme/users/${userId}`)
          if (response.data && response.data.success && response.data.availableDateTimes) {
            return {
              userId,
              availableDateTimes: response.data.availableDateTimes
            }
          }
          throw new Error(`availableDateTimes not found for user: ${userId}`)
        } catch (error) {
          console.error(`Error fetching data for user: ${userId}`, error)
          return null
        }
      })

      try {
        const availabilities = await Promise.all(userPromises)
        const validAvailabilities = availabilities.filter(availability => availability !== null)
        if (validAvailabilities.length === 0) {
          throw new Error('No valid availabilities found')
        }

        const unifiedAvailabilities: { [date: string]: { time: string; userId: string }[] } = {}

        validAvailabilities.forEach(({ userId, availableDateTimes }: any) => {
          availableDateTimes.forEach(({ date, times }: any) => {
            if (!unifiedAvailabilities[date]) {
              unifiedAvailabilities[date] = []
            }
            times.forEach((time: any) => {
              if (!unifiedAvailabilities[date].some((entry: any) => entry.time === time)) {
                unifiedAvailabilities[date].push({ time, userId })
              }
            })
          })
        })

        const events: any = []
        const extractedDates: any = []

        Object.keys(unifiedAvailabilities).forEach(date => {
          unifiedAvailabilities[date].forEach(({ time, userId }) => {
            events.push({ date, time, userId })
          })
          const [day, month, year] = date.split('/')
          extractedDates.push(`${year}-${month}-${day}`)
        })

        setEvents(events)
        setAvailableDates(extractedDates)
      } catch (error) {
        console.error('Error processing availabilities', error)
      }
    }

    fetchAvailabilities()
  }, [])

  useEffect(() => {
    if (timeListRef.current) {
      timeListRef.current.scrollTop = 0
    }
  }, [availableTimes])

  const handleTimeClick = (time: string) => {
    setSelectedTime(time)
    const event = events.find(
      event => event.date === format(selectedDate!, 'dd/MM/yyyy', { locale: ptBR }) && event.time === time
    )
    if (event) {
      const [day, month, year] = event.date.split('/')
      const [hours, minutes] = time.split(':')
      const date = new Date(
        parseInt(year, 10),
        parseInt(month, 10) - 1,
        parseInt(day, 10),
        parseInt(hours, 10),
        parseInt(minutes, 10)
      )
      setTimestamp(date.getTime())
      setSelectedEvent({ title: 'Onboarding', start: `${event.date} ${time}`, end: `${event.date} ${time}` })
    } else {
      setSelectedEvent(null)
    }
  }

  const confirmBooking = (time: string) => {
    setLoading(true)
    setShowForm(false)
    setSelectedEventDetails(null)

    const selectedDateInUserZone = toZonedTime(new Date(dateSelected!), 'America/Sao_Paulo')

    const selectedEventDetail = events.find(event => {
      const eventDate = new Date(event.date.split('/').reverse().join('-')) // Assuming DD/MM/YYYY format
      const formattedEventDate = format(eventDate, 'dd/MM/yyyy', { locale: ptBR })
      const formattedSelectedDate = format(selectedDateInUserZone, 'dd/MM/yyyy', { locale: ptBR })

      return formattedEventDate === formattedSelectedDate && event.time === time
    })

    if (selectedEventDetail) {
      setTimeout(() => {
        setLoading(false)
        setShowForm(true)
        setSelectedEventDetails(selectedEventDetail)
      }, 1000)
    } else {
      setLoading(false)
      console.error('Selected time not found for selected date:', dateSelected, time)
    }
  }

  const formatDateTitle = (date: Date) => {
    return format(date, "EEEE, 'dia' dd", { locale: ptBR })
  }

  const handleSelectDate = (date: string) => {
    setShowForm(false)
    setSelectedEventDetails(null)
    const [year, month, day] = date.split('-')
    const formattedDate = `${day}/${month}/${year}` // Formato DD/MM/YYYY
    const parsedDate = new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
    setSelectedDate(parsedDate)

    const dayEvents = events.filter(event => event.date === formattedDate)
    if (dayEvents.length > 0) {
      const sortedTimes = dayEvents
        .map(event => event.time)
        .sort((a, b) => {
          const [aHours, aMinutes] = a.split(':').map(Number)
          const [bHours, bMinutes] = b.split(':').map(Number)
          return aHours - bHours || aMinutes - bMinutes
        })
      setAvailableTimes(sortedTimes)
      setDateSelected(date)
    } else {
      setAvailableTimes([])
    }
  }

  const handleSuccess = (data: any) => {
    console.log('Event data received on success:', data)

    if (data && data.mainGuestName && data.startTimeUtc && data.guests && data.durationMinutes) {
      setConfirmationData(data)
    } else {
      console.error('Dados do evento incompletos:', data)
    }
  }

  return (
    <S.CalendarWrapper>
      <div className={`content-wrapper ${confirmationData ? 'column-layout' : 'row-layout'}`}>
        {confirmationData ? (
          <EventConfirmation eventData={confirmationData} />
        ) : (
          <>
            {/* <InfoContainer /> */}
            {/* <div className="divider" /> */}
            <div className="calendar-container">
              <S.SelectDateContent>
                <span className="select-date-title-content">Selecione a data</span>
                <DatePickerPopup
                  availableDates={availableDates}
                  onSelectDate={handleSelectDate}
                  isPopup={false}
                  withHeading={false}
                  onClose={() => setScreenState('selectFilter')}
                />
              </S.SelectDateContent>

              {selectedDate && (
                <div
                  className="time-list-or-form"
                  ref={timeListRef}
                  style={{
                    maxHeight: showForm ? 'auto' : '450px',
                    height: showForm ? 'auto' : '100%',
                    display: 'block'
                  }}
                >
                  {!showForm || !selectedEventDetails ? (
                    <TimeList
                      selectedDate={selectedDate}
                      availableTimes={availableTimes}
                      selectedTime={selectedTime}
                      handleTimeClick={handleTimeClick}
                      confirmBooking={confirmBooking}
                      loading={loading}
                      formatDateTitle={formatDateTitle}
                      formRef={formRef}
                    />
                  ) : (
                    selectedEventDetails && (
                      <FormConfirmation
                        formHeight={formRef.current?.offsetHeight || 'auto'}
                        timestamp={timestamp}
                        eventDetails={selectedEventDetails}
                        style={{ height: '100%' }}
                        onSuccess={handleSuccess}
                      />
                    )
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </S.CalendarWrapper>
  )
}

export default CalendarOnboardingContainer
