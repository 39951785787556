import React, { ComponentProps, ReactElement, cloneElement, forwardRef } from 'react'
import { VariantProps, tv } from 'tailwind-variants'
import { ImSpinner2 } from 'react-icons/im'
import { cn } from '@cannect/lib/utils'

export const input = tv({
  slots: {
    container: 'border-1 relative flex w-full items-center gap-4 rounded border-solid border-gray-300 px-4',
    containerInput:
      'peer block w-full appearance-none rounded border-none bg-transparent pr-4 text-sm text-gray-900 placeholder:text-gray-500 focus:border-blue-600 focus:outline-none focus:ring-0',
    containerIcon: 'text-gray-900'
  },
  variants: {
    variant: {
      filled: {
        container: 'border-none bg-gray-100'
      },
      filledDark: {},
      filledLight: {
        container: 'border border-gray-300 bg-white'
      }
    },
    size: {
      default: {
        container: 'h-[45px]',
        containerInput: 'text-sm',
        containerIcon: 'text-lg'
      },
      small: {
        container: 'h-8',
        containerInput: 'text-sm',
        containerIcon: 'text-xs'
      },
      medium: {
        container: 'h-10',
        containerInput: 'text-sm',
        containerIcon: 'text-[26px]'
      }
    },
    isInvalid: {
      true: {
        container: 'ring-1 ring-red-500'
      }
    },
    isDisabled: {
      true: {
        container: 'cursor-not-allowed opacity-60',
        containerInput: 'cursor-not-allowed text-gray-400'
      }
    }
  },

  defaultVariants: {
    variant: 'filled',
    size: 'default'
  }
})

export type InputProps = Omit<ComponentProps<'input'>, 'size'> &
  VariantProps<typeof input> & {
    rigthElement?: ReactElement
    leftElement?: ReactElement
    isLoading?: boolean
  }

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      isDisabled = false,
      isInvalid,
      leftElement: IconLeft,
      placeholder,
      isLoading = false,
      rigthElement: IconRigth,
      variant,
      className,
      size,
      name,
      ...props
    },
    ref
  ) => {
    const { container, containerInput, containerIcon } = input({
      isDisabled,
      isInvalid,
      variant,
      size,
      className
    })

    return (
      <div className={cn(container(), className)}>
        {IconLeft &&
          cloneElement(IconLeft as ReactElement, {
            className: containerIcon()
          })}

        <input
          name={name ?? undefined}
          data-testid="input"
          ref={ref}
          type="text"
          className={containerInput()}
          placeholder={placeholder}
          disabled={!!isDisabled}
          {...props}
        />
        {isLoading && <ImSpinner2 className="animate-spin text-base text-gray-900" />}
        {IconRigth && !isLoading && cloneElement(IconRigth as ReactElement, { className: containerIcon() })}
      </div>
    )
  }
)
