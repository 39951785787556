import { useHeader } from './useHeader'
import { LuMenu, LuFileSpreadsheet, LuLogOut } from 'react-icons/lu'
import { Sheet, Button, Navigation, Link, Icons, Separator } from '@cannect/new-components/atoms'

import { CounterBadge, LinkItem } from '@cannect/new-components/molecules'
import { AccountMenuItem } from './AccountMenuItem'

export const HeaderUI = ({
  menuConfig,
  signedUserName,
  handleLogout,
  isLogged,
  cartProductsQuantity,
  isAssistantFlow,
  handleAssistantContinue,
  isLoadingCartQuantity,
  isPublicFlow
}: ReturnType<typeof useHeader>) => {
  return (
    <header className="bg-primary-750 fixed top-0 z-50 flex h-header-mobile w-full items-center justify-center px-7 text-gray-50 md:h-header-desk md:px-10">
      {/* Public URL should render only the logo */}
      {isPublicFlow && (
        <Link to="/">
          <Icons.LogoWhite className="w-[96px] md:w-[200px]" />
        </Link>
      )}
      {!isPublicFlow && (
        <div className="flex w-full max-w-screen-2xl items-center justify-around gap-8 xl:justify-center">
          {/* Mobile sheet menu */}
          <Sheet.Root>
            <Sheet.Trigger asChild>
              <Button unstyled className="xl:hidden">
                <LuMenu className="!h-8 !w-8 text-gray-50" />
                <span className="sr-only">Toggle navigation menu</span> {/* sr-only */}
              </Button>
            </Sheet.Trigger>
            <Sheet.Content side="left" className="min-w-fit bg-primary-800 text-gray-50">
              <div className="mt-10 h-full w-full min-w-[289px] overflow-y-auto">
                <Navigation.Menu orientation="vertical">
                  <Navigation.MenuList className="flex-col items-start gap-6">
                    <div className="mb-2 flex flex-col gap-7">
                      <AccountMenuItem
                        signedUserName={signedUserName}
                        handleLogout={handleLogout}
                        isLogged={isLogged}
                        withSheetClose
                      />
                      {isLogged && (
                        <LinkItem icon={<LuLogOut size={18} />} onClick={handleLogout} withSheetClose title="Sair" />
                      )}
                    </div>
                    {menuConfig.map((item) => (
                      <Navigation.MenuItem key={item.title}>
                        <LinkItem withSheetClose {...item} />
                      </Navigation.MenuItem>
                    ))}
                  </Navigation.MenuList>
                </Navigation.Menu>
              </div>
            </Sheet.Content>
          </Sheet.Root>
          <Link to="/" className="self-end">
            <Icons.LogoWhite className="w-[96px] md:w-[200px]" />
          </Link>
          {/* Desktop items menu */}
          <Navigation.Menu className="gap-1">
            <Navigation.MenuList className="w-full gap-6">
              {menuConfig.map((item) => (
                <Navigation.MenuItem key={item.title} className="hidden xl:list-item">
                  <LinkItem {...item} />
                </Navigation.MenuItem>
              ))}
              <div className="flex items-center gap-2 self-end">
                <AccountMenuItem
                  className="hidden xl:flex"
                  signedUserName={signedUserName}
                  handleLogout={handleLogout}
                  isLogged={isLogged}
                />
                <Separator orientation="vertical" className="hidden h-6 bg-primary-600 xl:block" />
                {!isAssistantFlow && (
                  <Navigation.MenuItem className="header-icon-hover-effect">
                    <LinkItem icon={<Icons.ShoppingBag className="text-lg" />} title="" path="/carrinho" />
                    <CounterBadge quantity={cartProductsQuantity} isLoading={isLoadingCartQuantity} />
                    <span className="sr-only">Carrinho</span> {/* sr-only */}
                  </Navigation.MenuItem>
                )}
                {isAssistantFlow && (
                  <Navigation.MenuItem className={`header-icon-hover-effect ${!isAssistantFlow ? 'hidden' : ''}`}>
                    <LinkItem icon={<LuFileSpreadsheet size={26} />} title="" onClick={handleAssistantContinue} />
                    <CounterBadge quantity={cartProductsQuantity} isLoading={isLoadingCartQuantity} />
                    <span className="sr-only">Prescrição</span>
                    {/* sr-only */}
                  </Navigation.MenuItem>
                )}
              </div>
            </Navigation.MenuList>
          </Navigation.Menu>
        </div>
      )}
    </header>
  )
}
