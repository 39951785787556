import { Typography } from 'antd'
import { ParagraphProps } from 'antd/es/typography/Paragraph'
import { HtmlHTMLAttributes, PropsWithChildren } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

export const text = tv({
  base: '!mb-0 font-sans text-neutral-800',
  variants: {
    type: {
      paragraphOne: 'text-base',
      paragraphTwo: 'text-sm',
      captionOne: 'text-xs'
    },
    muted: {
      true: 'text-gray-500'
    },
    weight: {
      bold: 'font-bold',
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold'
    }
  },
  defaultVariants: {
    type: 'paragraphOne',
    fontFamily: 'default',
    weight: 'regular'
  }
})

export interface TTextProps extends HtmlHTMLAttributes<HTMLSpanElement>, VariantProps<typeof text> {
  ellipsis?: ParagraphProps['ellipsis']
}

export const Text = ({
  className,
  weight,
  type,
  children,
  ellipsis,
  muted,
  ...props
}: PropsWithChildren<TTextProps>) => {
  return (
    <Typography.Paragraph ellipsis={ellipsis} className={text({ type, weight, className, muted })} {...props}>
      {children}
    </Typography.Paragraph>
  )
}
