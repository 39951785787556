import { theme } from 'design-cannect'
import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const VidaasContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: 575px;
  width: 100%;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const ImageLogo = styled.img`
  width: 371px;

  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
`

export const ContentHeading = styled.div`
  width: 100%;
  text-align: center;

  h2 {
    margin-bottom: 45px;
    font-family: 'Spartan', sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #21976e;
  }

  p {
    font-family: 'Nunito', sans-serif;
    font-size: 24px;
    font-weight: 400;
    color: #000000;
  }

  @media ${MOBILE_WIDTH} {
    h2 {
      font-size: 22px;
      margin-bottom: 22px;
    }

    p {
      font-size: 16px;
    }
  }
`

export const QrCodeContent = styled.div``
export const NotValidateContent = styled.div`
  margin-top: 71px;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media ${MOBILE_WIDTH} {
    margin-top: 35px;
    text-align: center;
  }
`

export const NotValidateText = styled.p`
  font-family: 'Spartan', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #3d3d3d;
  display: flex;
  gap: 3px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${MOBILE_WIDTH} {
    font-size: 14px;
  }
`
export const NotValidateLink = styled.p`
  font-family: 'Spartan', sans-serif;
  font-size: 18px;
  font-weight: 400;

  cursor: pointer;
  color: ${temporaryTheme.colors.link};
  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }

  @media ${MOBILE_WIDTH} {
    font-size: 14px;
  }
`
