/* eslint-disable react/jsx-no-bind */
import Button from 'components/Button'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import Loading from 'components/Loading'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { FiShoppingCart } from 'react-icons/fi'
import api from 'services/api'
import Footer from 'components/BaseRecipeComponents/Footer'
import Header from 'components/BaseRecipeComponents/Header'
import RecipeProduct from './RecipeProduct'
import * as Style from './styles'
import PasswordModal from './PasswordModal'

export type BaseRecipePathParams = {
  token: string
}

export type BaseRecipeProduct = {
  id: number
  translated_name: string
  price: number
  prescription: string
  required_bottles?: number
}

type FetchBaseRecipeResponse = {
  recipe: BaseRecipeProduct[]
}

export type FetchRecipeResponse = {
  recipe_link: string
  success: boolean
}

export type DialogueReason = 'shareRecipe' | 'downloadRecipe'

export default function BaseRecipe() {
  const { token } = useParams<BaseRecipePathParams>()
  const history = useHistory()
  const [recipe, setRecipe] = useState<BaseRecipeProduct[]>([])
  const [linkToDownloadRecipe, setLinkToDownloadRecipe] = useState('')
  const [loading, setLoading] = useState(true)
  const [isErrorDialogueOpen, setIsErrorDialogueOpen] = useState(false)
  const [passwordModalComponent, setPasswordModalComponent] = useState<ReactNode>()

  useEffect(() => {
    async function fetchBaseRecipe() {
      try {
        const response = await api.get<FetchBaseRecipeResponse>(`baserecipe/${token}`)
        setRecipe(response.data.recipe)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }
    fetchBaseRecipe()
  }, [])

  function handleOpenDownloadModal(dialogueReason: DialogueReason) {
    if (linkToDownloadRecipe.length !== 0) {
      if (dialogueReason === 'downloadRecipe') {
        handleDownloadPdf(linkToDownloadRecipe)
      } else {
        handleOpenCart()
      }
      return
    }

    setPasswordModalComponent(
      <PasswordModal
        isOpen
        onRequestClose={() => setPasswordModalComponent(undefined)}
        setLoading={setLoading}
        dialogueReason={dialogueReason}
        token={token}
        onRecipeFetch={onFetchRecipe}
      />
    )
  }

  async function onFetchRecipe(dialogueReason: DialogueReason, fetchResult?: FetchRecipeResponse) {
    if (!fetchResult) {
      setIsErrorDialogueOpen(true)
      return
    }

    if (dialogueReason === 'downloadRecipe') {
      handleDownloadPdf(fetchResult.recipe_link)
    }
    setLinkToDownloadRecipe(fetchResult.recipe_link)
    setPasswordModalComponent(undefined)
  }

  function handleDownloadPdf(linkToDownload: string) {
    const anchorElement = document.createElement('a')
    anchorElement.href = linkToDownload
    anchorElement.target = '_blank'
    anchorElement.click()
  }

  async function handleOpenCart() {
    try {
      setLoading(true)
      const { data } = await api.post(`/order_recipe/${token}`)

      window.location.href = data.linkCart
    } catch (error) {
      console.log(error)
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Style.BaseRecipeContainer>
      <Style.ContentWrapper>
        {recipe.length !== 0 ? (
          <>
            <Style.SecondHeader>
              <span>{token}</span>
              <Button onClick={() => handleOpenDownloadModal('downloadRecipe')}>Baixar PDF</Button>
            </Style.SecondHeader>
            <Style.RecipesWrapper>
              {recipe.map((product, index) => (
                <Fragment key={product.id}>
                  <RecipeProduct product={product} />
                  {index !== recipe.length - 1 && <hr />}
                </Fragment>
              ))}
            </Style.RecipesWrapper>
          </>
        ) : (
          <p>Não foi possível localizar a receita informada</p>
        )}
        <Button outlined width="200px" alignSelf="center" onClick={() => history.push(`/receitas/${token}/ajuda`)}>
          Precisa de ajuda?
        </Button>
        <Button width="300px" alignSelf="center" onClick={() => handleOpenCart()}>
          Comprar produtos <FiShoppingCart size={20} />
        </Button>
      </Style.ContentWrapper>

      {loading && <Loading loading={loading} />}
      {passwordModalComponent}
      {isErrorDialogueOpen && (
        <ConfirmationDialogue
          isOpened={isErrorDialogueOpen}
          onRequestClose={() => setIsErrorDialogueOpen(false)}
          onConfirmationClick={() => setIsErrorDialogueOpen(false)}
          title="Houve um erro ao fazer o download PDF da receita!"
          description="A download do PDF não pôde ser feito devido a um erro interno. Tente novamente mais tarde."
          confirmationButtonText="Entendi!"
          confirmationOnlyDialogue
        />
      )}
    </Style.BaseRecipeContainer>
  )
}
