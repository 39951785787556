import Loading from 'components/Loading'
import { MouseEvent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import SearchSelectOption from './components/MultiSelectOption'
import SelectedPill from './components/SelectedPill'
import * as Styles from './styles'

export interface SelectOption {
  value: string
  label: string
  rqe: string
}

interface MultiSelectProps {
  options: SelectOption[]
  value: string[]
  onChange: (newValues: SelectOption[]) => void
  errorMessage?: string
  label?: string | ReactNode
  id: string
  disabled?: boolean
  optionsDisabled?: boolean
}

function MultiSelect({
  options: optionProp,
  value: selectedOptionsProp,
  onChange,
  errorMessage,
  id,
  label,
  disabled = false,
  optionsDisabled = false
}: MultiSelectProps) {
  const backgroundRef = useRef<HTMLDivElement>(null)
  const [showSelectionContainer, setShowSelectionContainer] = useState(false)
  const [search, setSearch] = useState('')
  const [options, setOptions] = useState<SelectOption[]>(optionProp)

  const selectedOptions = useMemo(() => {
    const newSelectedOptions: SelectOption[] = []

    selectedOptionsProp.forEach(selectedOption => {
      const option = options.find(option => option.value === selectedOption)

      if (option) {
        newSelectedOptions.push(option)
      }
    })

    return newSelectedOptions
  }, [options, selectedOptionsProp])

  useEffect(() => {
    setOptions(optionProp)
  }, [optionProp])

  useEffect(() => {
    setOptions(optionProp.filter(option => option.label.toLowerCase().includes(search.toLowerCase())))
  }, [search])

  const onBackgroundClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event.target === backgroundRef.current) {
      setShowSelectionContainer(false)
    }
  }

  const onRemoveOptionClick = (optionValue: string) => {
    onChange(selectedOptions.filter(option => option.value !== optionValue))
  }

  const onSelectOptionClick = (option: SelectOption, isOptionSelected: boolean) => {
    if (isOptionSelected) {
      onChange(selectedOptions.filter(selectedOption => selectedOption.value !== option.value))
    } else {
      onChange([...selectedOptions, option])
    }
  }

  return (
    <Styles.MultiSelectContainer>
      {showSelectionContainer && <Styles.MultiSelectBackground ref={backgroundRef} onClick={onBackgroundClick} />}

      {label && <label htmlFor={id}>{label}</label>}

      <Styles.SearchSelectContainer>
        <Styles.SearchInput
          id={id}
          value={search}
          onChange={event => setSearch(event.target.value)}
          onFocus={() => setShowSelectionContainer(true)}
          placeholder="Busque e selecione"
          hasError={errorMessage !== undefined && errorMessage.length > 0}
          autoComplete="off"
          disabled={disabled}
        />
        {errorMessage !== undefined && errorMessage.length > 0 && (
          <Styles.InputErrorMessage>{errorMessage}</Styles.InputErrorMessage>
        )}
        <Styles.SelectedPillsContainer>
          {selectedOptions.map(option => (
            <SelectedPill
              key={option.value}
              label={option.label}
              onClick={() => onRemoveOptionClick(option.value)}
              disabled={optionsDisabled}
            />
          ))}
        </Styles.SelectedPillsContainer>

        {showSelectionContainer && (
          <Styles.SearchSelectOptionsWrapper>
            {options.map(option => {
              const isOptionSelected = !!selectedOptions.find(selectedOption => selectedOption.value === option.value)
              return (
                <SearchSelectOption
                  key={option.value}
                  label={option.label}
                  isSelected={isOptionSelected}
                  onClick={() => onSelectOptionClick(option, isOptionSelected)}
                />
              )
            })}
          </Styles.SearchSelectOptionsWrapper>
        )}
      </Styles.SearchSelectContainer>
    </Styles.MultiSelectContainer>
  )
}

export default MultiSelect
