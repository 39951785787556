import { cn } from '@cannect/lib/utils'
import { HTMLAttributes } from 'react'
import { VariantProps, tv } from 'tailwind-variants'

const badgeVariants = tv({
  base: 'inline-flex items-center justify-center gap-2 border border-solid font-sans font-semibold leading-5 transition-colors focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2',
  variants: {
    variant: {
      default: 'border-transparent bg-primary-800 text-white hover:bg-primary-800/90',
      primary_light: 'border-transparent bg-primary-300 text-primary-700 hover:bg-primary-300/90',
      secondary: 'border-transparent bg-secondary text-white hover:bg-secondary/90',
      secondary_light: 'border-transparent bg-secondary-300 text-white hover:bg-secondary-300/90',
      neutral_light: 'border-transparent bg-neutral-200 text-white hover:bg-neutral-200/90',
      outline: 'border-secondary-800 text-secondary-800 hover:opacity-90'
    },
    size: {
      default: 'px-4 py-0.5 text-sm',
      lg: 'px-6 py-4 text-base'
    },
    isLoading: {
      true: 'animate-pulse bg-gray-200 text-transparent'
    },
    rounded: {
      full: 'rounded-full',
      lg: 'rounded',
      md: 'rounded-md',
      none: 'rounded-none'
    }
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    rounded: 'full'
  }
})

export interface BadgeProps extends HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {
  isLoading?: boolean
}

function Badge({ className, variant, size, rounded, isLoading = false, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant, isLoading, size, rounded }), className)} {...props} />
}

export { Badge, badgeVariants }
