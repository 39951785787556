import { AnimatePresence, motion } from 'framer-motion'
import { FileItem } from '../FileItem'
import { TFileItem } from '../FileItem/types'

type TListFiles = {
  list: TFileItem[]
  onRemove(index: number): void
  canRemove?: boolean
  canDownload?: boolean
  canView?: boolean
}

export function ListFiles({ list, onRemove, canRemove, canDownload, canView }: TListFiles) {
  return (
    <AnimatePresence>
      <div className="flex flex-col space-y-4">
        {list?.map((item, index) => (
          <motion.div
            key={item.id ?? index}
            initial={{ y: '100%', opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ ease: 'easeIn', duration: 0.4 }}
            exit={{ y: '100%', opacity: 0 }}>
            <FileItem
              {...item}
              dashed
              animate={false}
              remove={canRemove}
              download={canDownload}
              view={canView}
              onRemove={() => onRemove(index)}
            />
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  )
}
