/* eslint-disable no-plusplus */
import { TAcceptDefaults, TAcceptFormats, TErrorType, TFormats, TItemError, acceptDefaults } from './types'

/* eslint-disable no-restricted-syntax */

export const typeErrors: Record<TErrorType, TItemError> = {
  DEFAULT: {
    message: 'Houve um erro ao fazer o upload.'
  },
  FORMAT: {
    message: 'Formato de arquivo não aceito.'
  },
  SIZE: {
    message: 'Não é possível enviar arquivos com mais de 20MB'
  }
}
export function filterAcceptFormats(acceptFormats: TFormats[]): Partial<TAcceptDefaults> {
  const filteredAccepts: Partial<TAcceptDefaults> = {}

  for (const format of acceptFormats) {
    for (const [mime, extensions] of Object.entries(acceptDefaults)) {
      if (extensions.includes(format)) {
        filteredAccepts[mime as TAcceptFormats] = extensions

        break
      }
    }
  }

  return filteredAccepts
}

export function formatFileSize(fileSizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']

  let size = fileSizeInBytes

  let unitIndex = 0

  while (size > 1024 && unitIndex < units.length - 1) {
    size /= 1024

    unitIndex++
  }

  return `${size.toFixed(0)} ${units[unitIndex]}`
}
