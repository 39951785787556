import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { VscCloudUpload } from 'react-icons/vsc'
import api from 'services/api'
import Loading from 'components/Loading'
import { BsCheckLg } from 'react-icons/bs'

import { Input } from 'design-cannect'

import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import { useHistory } from 'react-router'
import { useTokenApi } from 'hooks/useTokenApi'
import { Container } from './styles'
import Text from '../Text'

interface InputFileProps {
  disabled?: boolean
  title?: string
  fullWidth?: boolean
  handleUpload: (files: Array<Blob>) => void
  updateComponent?: { name: string }[]
  handleReupload?: any
  type: string
  submit?: boolean
}

function InputFile({
  disabled,
  title,
  fullWidth,
  handleUpload,
  updateComponent,
  submit,
  handleReupload
}: InputFileProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { deleteTokenApi } = useTokenApi()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const [recipeId, setRecipeId] = useState<number>()
  const [error, setError] = useState<boolean>(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/png': [], 'image/jpeg': [], 'application/pdf': [] },
    multiple: true,
    onDrop: (acceptedFiles: any, fileRejections) => {
      setError(fileRejections.length > 0)
      onDrop(acceptedFiles)
      setFiles(acceptedFiles)
    }
  })

  const submitFiles = async (acceptFiles: string | any[]) => {
    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < acceptFiles.length; i++) {
      const file = acceptFiles[i]
      formData.append('file', file)
    }

    try {
      setLoading(true)
      const { data } = await api.post(`order/send_recipe`, formData)

      setRecipeId(data.recipeSalved.id)
      await createOrder()
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
      console.log(e)
      setLoading(false)
    }
  }

  const createOrder = async () => {
    if (recipeId) {
      const payload = {
        recipes: [{ id: recipeId }]
      }
      try {
        const { data } = await api.post(`order`, payload)

        const stringfiedOrderId = JSON.stringify(data.order.id)
        localStorage.setItem('@CANNECT:ORDERID', stringfiedOrderId)

        history.push('/receita-branca/selecao-produtos')
      } catch (e: any) {
        if (e?.response?.status === 401) {
          deleteTokenApi()
        }
        console.log(e)
      }
    }
  }

  const onDrop = (acceptedFiles: Array<Blob>) => {
    handleUpload(acceptedFiles)
  }

  useEffect(() => {
    if (submit && files.length) {
      submitFiles(files)
    }
  }, [submit, files])

  useEffect(() => {
    if (recipeId !== undefined) {
      createOrder()
    }
  }, [recipeId])

  useEffect(() => {
    if (handleReupload) {
      setFiles(handleReupload)
    }
  }, [handleReupload])

  return (
    <>
      <Loading loading={loading} />
      <Container disabled={disabled} fullWidth={fullWidth} isMobile={isMobile}>
        {title && (
          <div className="header">
            <Text type="md" bold color="tertiary">
              {title}
            </Text>
          </div>
        )}
        <div>
          <div className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {!error ? (
                updateComponent?.length === 0 ? (
                  <>
                    <input {...getInputProps()} />
                    <button type="button" className="action">
                      <VscCloudUpload />
                      Adicione ou arraste o documento aqui
                    </button>
                  </>
                ) : (
                  <div className="error">
                    <BsCheckLg />
                    <Text type="md" bold color="tertiary">
                      Arquivo enviado com sucesso.
                    </Text>
                  </div>
                )
              ) : (
                <div className="error">
                  <Text type="md" bold color="secondary">
                    Formato de Arquivo inválido
                  </Text>
                  <Text type="md">* tipo de aquivos validos png, jpeg ou pdf</Text>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default InputFile
