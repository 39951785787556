import React from 'react'

import { AuthProvider } from './AuthContext'
import { FilterProductProvider } from './FilterProductContext'
import { BlogFilterProvider } from './useBlogFilter'
import { CannectAssistantProvider } from './useCannectAssistant'
import { CannectCareProvider } from './useCannectCare'
import { CartCheckoutProvider } from './useCartCheckout'
import { IntrodutoryCourseProvider } from './useIntrodutoryCourse'
import { MarketPlaceProvider } from './useMarketPlace'
import { SignedUserProvider } from './useSignedUser'
import { TokenApiProvider } from './useTokenApi'
import { SchedulingProvider } from './useScheduling'
import { PrepareCartProvider } from './usePrepareCart'
import { MiniCheckoutProvider } from './useModalMiniCheckout'

interface AppProviderProps {
  children: React.ReactNode
}

function AppProvider({ children }: AppProviderProps) {
  return (
    <AuthProvider>
      <TokenApiProvider>
        <MiniCheckoutProvider>
          <SignedUserProvider>
            <IntrodutoryCourseProvider>
              <FilterProductProvider>
                <MarketPlaceProvider>
                  <CannectAssistantProvider>
                    <PrepareCartProvider>
                      <CartCheckoutProvider>
                        <CannectCareProvider>
                          <SchedulingProvider>
                            <BlogFilterProvider>{children}</BlogFilterProvider>
                          </SchedulingProvider>
                        </CannectCareProvider>
                      </CartCheckoutProvider>
                    </PrepareCartProvider>
                  </CannectAssistantProvider>
                </MarketPlaceProvider>
              </FilterProductProvider>
            </IntrodutoryCourseProvider>
          </SignedUserProvider>
        </MiniCheckoutProvider>
      </TokenApiProvider>
    </AuthProvider>
  )
}

export default AppProvider
