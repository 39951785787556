import { theme } from 'design-cannect'
import { CSSProperties, ReactNode } from 'react'
import { FiCheck } from 'react-icons/fi'
import * as Style from './styles'

interface CheckboxInputProps {
  label: string | ReactNode
  id?: string
  checked: boolean
  onChange: (e?: any) => void
  hasError?: boolean
  disabled?: boolean
  value?: any
  style?: CSSProperties
  color?: string
}

export default function CheckboxInput({
  label,
  id,
  checked,
  onChange,
  disabled,
  hasError,
  value,
  style,
  color = '#646464'
}: CheckboxInputProps) {
  return (
    <Style.CheckboxInputContainer disabled={disabled} hasError={hasError} style={style}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} value={value} />
      <Style.VisualInput htmlFor={id} disabled={disabled} hasError={hasError}>
        {checked && <FiCheck color={color} size={17} />}
      </Style.VisualInput>
      <label htmlFor={id}>{label}</label>
    </Style.CheckboxInputContainer>
  )
}
