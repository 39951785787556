/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
import { FileError } from 'react-dropzone'
import { TAcceptDefaults, TAcceptFormats, TFormats, acceptDefaults } from './types'

export function filterAcceptFormats(acceptFormats: TFormats[]): Partial<TAcceptDefaults> {
  const filteredAccepts: Partial<TAcceptDefaults> = {}

  for (const format of acceptFormats) {
    for (const [mime, extensions] of Object.entries(acceptDefaults)) {
      if (extensions.includes(format)) {
        filteredAccepts[mime as TAcceptFormats] = extensions

        break
      }
    }
  }

  return filteredAccepts
}

export function formatFileSize(fileSizeInBytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB']

  let size = fileSizeInBytes

  let unitIndex = 0

  while (size > 1024 && unitIndex < units.length - 1) {
    size /= 1024

    unitIndex++
  }

  return `${size.toFixed(0)} ${units[unitIndex]}`
}

export function validateFile(
  file: File | DataTransferItem,
  accept: Partial<TAcceptDefaults>
): FileError | FileError[] | null {
  if (file instanceof File) {
    const [size, unit] = formatFileSize(file.size).split(' ')

    if (['GB', 'TB'].includes(unit) || (unit === 'MB' && parseInt(size, 10) > 20)) {
      return { code: 'SIZE', message: 'File size is too large.' }
    }

    if (!Object.keys(accept).includes(file.type)) {
      return { code: 'UPLOAD', message: 'Unsupported file type.' }
    }
  }

  if (file instanceof DataTransferItem) {
    if (!Object.keys(accept).includes(file.type)) {
      return { code: 'UPLOAD', message: 'Unsupported file type.' }
    }
  }

  return null
}
