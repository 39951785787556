import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const ImageBox = styled.div`
  width: 100%;
  background: #c3c3c3;
  margin-bottom: 10px;
  overflow: hidden;

  &.podcasts {
    height: 318px;
    max-height: unset;
    min-height: unset;
  }

  &.dr_cannabis {
    height: 217px;
  }

  &.course {
    height: 217.36px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &.webinars {
    height: 112.09px;
    min-height: 112.09px;
  }

  &.whitepapers {
    height: 193.36px;
  }
`
export const Image = styled.img`
  width: 100%;
  object-fit: cover;
  transition: 0.5s;
`

export const Content = styled.div`
  padding: ${temporaryTheme.spacing.space2} ${temporaryTheme.spacing.space3};
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space2};
`
export const ModuleName = styled.span`
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: ${temporaryTheme.colors.darkGrey};
`
export const Title = styled.h3`
  font-family: 'Spartan', sans-serif;
  font-weight: 500;
  font-size: 15px;
  color: ${temporaryTheme.colors.darkGrey};
  min-height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

export const Description = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 15.0247px;
  color: ${temporaryTheme.colors.lightDark};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;

  p,
  strong {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
  }
`
export const LinkCustom = styled.a`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 15.0247px;
  color: ${temporaryTheme.colors.lightHoney};
  padding: 20px ${temporaryTheme.spacing.space3};
  gap: 28px;

  &:hover {
    color: ${temporaryTheme.colors.linkHover};
  }
`

export const Wrapper = styled.main`
  width: 320px;
  min-height: 231px;
  background: #fafafa;
  border-radius: 19.6475px;
  overflow: hidden;
  box-shadow: ${temporaryTheme.shadow.shad1};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${temporaryTheme.colors.sitePrimary};
    ${Image} {
      transform: scale(1.2);
    }
  }

  &.podcasts {
    height: 470px;
  }

  &.webinars {
    height: 231px;

    ${Description} {
      display: none;
    }
  }

  &.dr_cannabis {
    height: 359px;
  }

  &.course {
    height: 390px;
  }

  &.whitepapers {
    height: 390px;
  }
`
