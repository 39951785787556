import React from 'react';
import { Modal, Button } from 'antd';
import * as S from './styles';

interface SelectFlavorModalProps {
  open: boolean;
  handleCancel: () => void;
  isAuthenticated?: boolean;
  message: string;
  urlRedirect: string;
  onClick?: () => void;
}

export function ProductModalDisabled({
  open,
  handleCancel,
  message,
  urlRedirect,
  onClick
}: SelectFlavorModalProps) {

  const redirectLink = (link: string) => {
    window.open(link, '_blank', 'noopener')
  }

  const handleClose = () => {
    handleCancel();
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      centered
      footer={null}
      zIndex={1005}
      width={open ? '90%' : 850}
      style={{ maxWidth: 850 }}
    >
      <S.Wrapper>
        <S.Title>Cannect informa</S.Title>

        <S.ContentText>
          {message}
        </S.ContentText>

        <S.ActionsContent>
          <Button
            style={{ width: '200px', height: '40px', backgroundColor: '#464F31', color: 'white' }}
            onClick={() => redirectLink(urlRedirect)}
          >
            Quero suporte
          </Button>
          <Button
            style={{ width: '200px', height: '40px', backgroundColor: '#fff', color: '#464F31', border: '2px solid #464F31' }}
            onClick={() => {
              handleClose()
              if(onClick) onClick()
            }}
          >
            Seguir com meu pedido
          </Button>
        </S.ActionsContent>
      </S.Wrapper>
    </Modal>
  );
}
