/* eslint-disable jsx-a11y/label-has-associated-control */
import { forwardRef, ElementRef, ComponentPropsWithoutRef } from 'react'
import { Typography } from '../Typography'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { cn } from '@cannect/lib/utils'
import { LuCheck } from 'react-icons/lu'
import { tv, VariantProps } from 'tailwind-variants'

const checkboxVariants = tv({
  slots: {
    checkboxContainer:
      'data-[state=checked]:bg-gray50 peer shrink-0 rounded-md border-2 border-solid border-primary-700 bg-white outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:text-primary-700',
    iconIndicator: '',
    label: 'font-sans font-medium leading-none text-current peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
  },
  variants: {
    size: {
      md: {
        checkboxContainer: 'h-6 w-6',
        iconIndicator: 'h-4 w-4',
        label: 'text-base'
      },
      sm: {
        checkboxContainer: 'border-1 h-5 w-5',
        iconIndicator: 'h-3 w-3',
        label: 'text-[13px] font-normal'
      }
    },
    labelColor: {
      dark: {
        label: 'text-gray-700'
      },
      light: {
        label: 'text-gray-50'
      }
    }
  },
  defaultVariants: {
    size: 'md',
    labelColor: 'dark'
  }
})
type TCheckboxProps = {
  label: string
  message?: string
  isInvalid?: boolean
} & VariantProps<typeof checkboxVariants>

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  Omit<ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, 'onChange'> & TCheckboxProps
>(({ className, label, isInvalid, message, size, labelColor, ...props }, ref) => {
  const {
    checkboxContainer,
    iconIndicator,
    label: labelClass
  } = checkboxVariants({
    className,
    size,
    labelColor
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-start gap-2">
        <CheckboxPrimitive.Root ref={ref} className={checkboxContainer()} {...props}>
          <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
            <LuCheck className={iconIndicator()} />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <label className={labelClass()}>{label}</label>
      </div>

      {message && (
        <div className="w-full">
          <Typography.Text
            type="captionOne"
            weight="medium"
            className={`${isInvalid ? 'text-critical-500' : 'text-gray-700'} text-md mt-1 text-left`}>
            {message}
          </Typography.Text>
        </div>
      )}
    </div>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
