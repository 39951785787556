import { useMarketPlace } from 'hooks/useMarketPlace'
import useSignedUser from 'hooks/useSignedUser'
import { useTokenApi } from 'hooks/useTokenApi'
import Heading from 'new-components/Heading'
import { MedicalDataType } from 'pages/ProductDetails/typeProduct'
import { useEffect, useState } from 'react'
import { BsCart2 } from 'react-icons/bs'
import { IoIosAdd } from 'react-icons/io'
import { IoRemove } from 'react-icons/io5'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import { useHistory, useLocation } from 'react-router'
import { formatToBRL, NewFormatPrice } from 'utils/formatters'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { cannectDefaultProduct, cannectDefaultProductNoTarg, produto_sem_imagem } from '../../../../assets/img'
import { noop } from 'lodash'
import { toast } from 'react-toastify'
import { BiLoaderCircle } from 'react-icons/bi'
import When from 'utils/when'
import { ProductHeading } from './ProductHeading'
import * as Styles from './styles'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { ProductModalDisabled } from '@cannect/new-components/ProductModalDisabled'
import { ProductModalDisabledNutraceutico } from '@cannect/new-components/ProductModalDisabledNutraceutico'

export interface DetailsTypes {
  product?: any
  medicalData?: MedicalDataType
  getProduct?: (idSabor?: string) => void
}

export default function Details({ product, medicalData, getProduct = noop }: DetailsTypes) {
  const [quantity, setQuantity] = useState(1)
  const [loadingToCart, setLoadingToCart] = useState(false)
  const [mediaFile, setMediaFile] = useState([{ format: '', path: '' }])
  const { tokenApi } = useTokenApi()
  const history = useHistory()
  const location = useLocation()
  const { addToCart } = usePrepareCart()
  const { ChangeCoaFile } = useMarketPlace()
  const { signedUser } = useSignedUser()
  const [idSabor, setIdSabor] = useState('')
  const [activeLoading, setActiveLoading] = useState('')
  const [recommendedFlavor, setRecommendedFlavor] = useState('')
  const { prescription, setPath, setStep, addProductToPrescription } = useCannectAssistant()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [flavorModal, setFlavorModal] = useState(false)
  const [nutraceuticosModal, setNutraceuticosModal] = useState(false)
  const [productDisabled, setProductDisabled] = useState(false)
  const [anvisaModalMessage, setAnvisaModalMessage] = useState<string>("")
  const isPatientUserType = signedUser?.registration_type === 1

  const handleToCart = async (id: any, quantity: any) => {
    console.log('entrou na função')
    const listProductDisabledNutraceutico = [
      15436,
      15401,
      15437,
      15438,
      15439,
      15440,
      15441,
      15442,
      15443,
      15444,
      15445,
      15446,
      15447,
      15448,
      15449,
      15450,
      15451,
      15452,
      15453,
      15454,
      15455,
      15456,
      15457,
      15458,
      15459,
      15460,
      15461,
      15462,
      15463]

    const productDisabled = listProductDisabledNutraceutico.includes(product?.id)

    const listProductDisabledAnvisa = [14977, 14978, 14980, 14981, 14982, 14983]
    const brandDisabled = [23, 26, 49]
    const productDisabledAnvisa = listProductDisabledAnvisa.includes(product?.id) || brandDisabled.includes(product?.brand?.id)

    if(productDisabled) {
      setNutraceuticosModal(true)
    }

    if (productDisabledAnvisa) {
      setFlavorModal(true)
      setAnvisaModalMessage('Identificamos que uma ou mais marcas da sua prescrição estão temporariamente indisponíveis para importação pela Anvisa. Isso pode impactar o tempo de entrega do seu tratamento. Prefere seguir com o pedido ainda assim ou quer nosso suporte para entrar em contato com seu prescritor para uma possível troca por outras marcas disponíveis?')
    }

    if (!productDisabled && !productDisabledAnvisa) {
      setLoadingToCart(true)
      try {
        await addToCart(id, quantity)
      } catch (e) {
        console.log(e)
      } finally {
        setLoadingToCart(false)
      }
    } 
  }

  const determineRecommendedFlavor = () => {
    if (product?.flavors && product?.flavors.length > 0) {
      const maxBalance = Math.max(...product.flavors.map((item: any) => item.balance))
      const highBalanceFlavors = product.flavors.filter((item: any) => item.balance === maxBalance)

      if (highBalanceFlavors.length === 1) {
        setRecommendedFlavor(highBalanceFlavors[0].flavor)
      } else {
        const productFlavorMatch = highBalanceFlavors.find((item: any) => item.flavor === product.flavor)
        setRecommendedFlavor(productFlavorMatch ? productFlavorMatch.flavor : highBalanceFlavors[0].flavor)
      }
    }
  }

  useEffect(() => {
    if (product && product.flavors) {
      const defaultFlavorId = product.flavors[0]?.id || ''
      setIdSabor(defaultFlavorId)
    }
    determineRecommendedFlavor()
  }, [product])

  const handleAddQuantity = () => {
    setQuantity(quantity + 1)
  }

  const handleRemoveQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const handleLogin = () => {
    history.push({ pathname: '/login', state: { from: { pathname: location.pathname } } })
  }

  const redirectLink = (
    link = productDisabled ? `https://api.whatsapp.com/send/?phone=5511982711992&text=Oi%2C
                              +gostaria+de+iniciar+meu+tratamento%21&type=phone_number&app_absent=0`
      : 'https://atendimento.cannect.life/atendimento-cannect') => {
    window.open(link, '_blank', 'noopener')
  }

  const formatMediaFile = () => {
    const imageFiles = product?.media?.filter((item: any) => item?.format === 'IMG')
    const pdfFiles = product?.media?.filter((item: any) => item?.format === 'PDF')
    if (imageFiles) {
      setMediaFile(imageFiles)
    }
    if (pdfFiles) {
      ChangeCoaFile(pdfFiles)
    }
  }

  const redirectToProductPrescription = () => {
    setPath('alreadyKnow')
    setStep(2)
    const isProductAlreadyAdded = !!prescription.products.find(p => p.id === product.id)
    if (!isProductAlreadyAdded) {
      addProductToPrescription(product)
    }
    history.push('/assistente')
  }

  const defineMoreInfoButton = () => {
    if (signedUser?.registration_type === 1) {
      return <Styles.ButtonMoreInfo onClick={() => redirectLink()}>quero Agendar uma CONSULTA</Styles.ButtonMoreInfo>
    }
    if (
      signedUser?.prescriber?.council === 'CRM' ||
      signedUser.prescriber?.council === 'CRO' ||
      signedUser.prescriber?.council === 'RMS'
    ) {
      return (
        <Styles.ButtonMoreInfo onClick={() => redirectToProductPrescription()}>
          PRESCREVER ESSE PRODUTO
        </Styles.ButtonMoreInfo>
      )
    }
    return null
  }

  useEffect(() => {
    formatMediaFile()
  }, [product])

  const handleFlavor = (id: any, flavorName: string) => {
    setActiveLoading(flavorName)
    try {
      setIdSabor(id)
      getProduct(id)
    } catch (e) {
      toast.error('Erro ao selecionar um novo sabor')
    }
  }

  useEffect(() => {

  }, [])

  const handleCloseFlavorModal = () => {
    setFlavorModal(false)
  }

  const handleCloseNutraceuticoModal = () => {
    setNutraceuticosModal(false)
  }

  const promotion = product?.promotion?.length ? product?.promotion[0] : product?.promotion
  const validPromotion = new Date(promotion?.promotion_info?.start_date) <= new Date()
  const defaultImage = product?.supplier?.id === 18 ? cannectDefaultProduct : cannectDefaultProductNoTarg
  const selectedImage = isPatientUserType && !(product?.class?.id == 6) ? defaultImage : mediaFile[0]?.path

  return (
    <Styles.Wrapper>
      <When expr={isMobile}>
        <ProductHeading
          brand_name={product?.brand?.name}
          translated_name={product?.translated_name}
          sku={product?.id}
        />
      </When>

      <Styles.ImageContent>
        <Styles.SelectedImageBox>
          <Styles.SelectedImage src={selectedImage} alt="Imagem do produto padrão" />
        </Styles.SelectedImageBox>
      </Styles.ImageContent>

      <Styles.DetailsContent>
        <When expr={!isMobile}>
          <ProductHeading
            brand_name={product?.brand?.name}
            translated_name={product?.translated_name}
            sku={product?.id}
          />
        </When>

        {!!tokenApi && product?.supplier?.id === 18 && (
          <Styles.BadgePromotion isNational={product?.supplier?.id === 18}>
            <>
              Entrega de origem nacional, receba em até <span>72 horas</span> após a confirmação de pagamento.
            </>
          </Styles.BadgePromotion>
        )}

        {!!tokenApi && (
          <Styles.InfoBox>
            <Styles.PriceBox>
              <Styles.Price>
                {validPromotion && !isPatientUserType
                  ? formatToBRL(promotion?.promotional_price)
                  : formatToBRL(product?.real_price)}
              </Styles.Price>
            </Styles.PriceBox>
          </Styles.InfoBox>
        )}

        <Styles.FlavorBox>
          <Heading elementType="h5" fontWeight={400} defaultColor="dark">
            Sabor:
          </Heading>


          <Styles.FlavorList>
            {product?.flavors?.map((item: any) => {
              const isRecommended = item.flavor === recommendedFlavor;
              const isActive = item.flavor === product.flavor;
              const isLowStock = item.balance <= 0;

              return (
                item.status === 'A' && (
                  <div style={{ position: 'relative', marginBottom: '50px' }} key={item.id}>
                    {isLowStock ? (
                      <Styles.LowStockLabel>
                        Possível tempo maior
                        <br /> de entrega
                      </Styles.LowStockLabel>
                    ) : (
                      isRecommended && <Styles.RecommendedLabel>Recomendado</Styles.RecommendedLabel>
                    )}
                    <Styles.ItemFlavor
                      isActive={isActive}
                      isRecommended={isRecommended && !isActive && !isLowStock}
                      isLoading={activeLoading === item.flavor}
                      onClick={() => handleFlavor(item.id, item.flavor)}
                    >
                      <Styles.TextFlavor isActive={isActive}>{item.flavor}</Styles.TextFlavor>
                    </Styles.ItemFlavor>
                  </div>
                )
              );
            })}
          </Styles.FlavorList>

        </Styles.FlavorBox>

        <Styles.InfoBox>
          <Styles.TextDetails>
            Volume:
            <span>
              {medicalData?.volume} {medicalData?.unit_of_measurement}
            </span>
          </Styles.TextDetails>
        </Styles.InfoBox>

        <Styles.InfoBox>
          <Styles.TextDetails>
            Classe: <span> {product?.class?.name}</span>
          </Styles.TextDetails>
        </Styles.InfoBox>

        <Styles.InfoBox>
          <Styles.TextDetails>
            Forma: <span> {product?.shape?.name}</span>
          </Styles.TextDetails>
        </Styles.InfoBox>

        <Styles.InfoBox>
          <Styles.TextDetails>
            Via de administração: <span> {product?.administration?.name}</span>
          </Styles.TextDetails>
        </Styles.InfoBox>

        <Styles.InfoBox>
          <Styles.TextDetails>
            Patologia(s) associada(s) ao produto:
            {product?.pathology?.length > 0 &&
              product?.pathology?.map((item: any) => (
                <Styles.PathologyText key={item?.id} href={`/cannect-ciencia?pathology=${item?.id}`}>
                  {item?.name};
                </Styles.PathologyText>
              ))}
          </Styles.TextDetails>
        </Styles.InfoBox>

        {tokenApi ? (
          <Styles.ActionsBox>
            <Styles.QuantityBox notVolume={!medicalData?.volume}>
              <Styles.ButtonRemove onClick={handleRemoveQuantity}>
                <IoRemove />
              </Styles.ButtonRemove>
              <p>{quantity}</p>
              <Styles.ButtonAdd onClick={handleAddQuantity}>
                <IoIosAdd />
              </Styles.ButtonAdd>
            </Styles.QuantityBox>

            <Styles.ButtonAddCart
              onClick={() => handleToCart(product?.id, quantity || idSabor)}
              isLoading={loadingToCart}
            >
              {!!loadingToCart && (
                <div className="load-box">
                  <BiLoaderCircle />
                </div>
              )}
              adicionar ao carrinho
              <BsCart2 />
            </Styles.ButtonAddCart>

            {defineMoreInfoButton()}
          </Styles.ActionsBox>
        ) : productDisabled ? (
          <Styles.ActionsBox>
            <Styles.ButtonMoreInfo onClick={() => redirectLink()}>Clique aqui para saber mais</Styles.ButtonMoreInfo>
            {defineMoreInfoButton()}
          </Styles.ActionsBox>
        ) : (
          <Styles.ActionsBox>
            <Styles.ButtonMoreInfo onClick={() => redirectLink()}>quero consultar um médico</Styles.ButtonMoreInfo>
            <Styles.ButtonAddCart onClick={handleLogin}>fazer login para ver mais</Styles.ButtonAddCart>
          </Styles.ActionsBox>
        )}
      </Styles.DetailsContent>

      <ProductModalDisabledNutraceutico
        open={nutraceuticosModal}
        handleCancel={() => setNutraceuticosModal(false)}
        isAuthenticated={!!tokenApi}
      />

      <ProductModalDisabled
        open={flavorModal}
        handleCancel={handleCloseFlavorModal}
        isAuthenticated={!!tokenApi}
        onClick={async () => {
          setLoadingToCart(true)
          try {
            await addToCart(product?.id, quantity || Number(idSabor))
          } catch (e) {
            console.log(e)
          } finally {
            setLoadingToCart(false)
          }
        }}
        urlRedirect='https://api.whatsapp.com/send?phone=5511930321198&text=Ol%C3%A1,%20gostaria%20de%20suporte%20devido%20a%20indisponibilidade%20da%20Anvisa.%20'
        message={anvisaModalMessage}
      />
    </Styles.Wrapper>
  )
}
