import { useEffect, useState } from 'react'
import { VscPackage } from 'react-icons/vsc'
import { useHistory } from 'react-router'
import { OrdersContainer } from './styles'
import { InputSearch } from '../../components/InputSearch'
import Button from '../../new-components/Button'
import api from '../../services/api'
import { formatIsoDateToDisplay } from '../../utils/formatters'
import EmptyState from '../../components/EmptyState'
import LoadingContainer from '../../components/LoadingContainer'

type TOrder = {
  uuid: string
  id: number
  createdAt: string
  payments: {
    id: number
    status: string
    createdAt: string
  }[]
  person: {
    id: number
    name: string
    email: string
    cpf: string
  }
}

type UserOrderResponse = {
  orders: TOrder[]
}
function UserOrderList() {
  const [search, setSearch] = useState('')
  const [orders, setOrders] = useState<TOrder[]>([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const redirectToOrder = (id: number) => {
    history.push(`/detalhes-do-pedido/${id}`)
    /* window.location.href = `/analise-cannect-wa/?cart_id=${id}` */
  }

  const getOrders = async () => {
    try {
      setLoading(true)
      const { data } = await api.get<UserOrderResponse>('/my-account/my-orders')
      setOrders(data?.orders)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrders()
  }, [])

  const filteredOrders = orders.filter((order: TOrder) => {
    return order.id.toString().includes(search) || order.createdAt.toString().includes(search)
  })

  return (
    <OrdersContainer>
      <div className="order_wrapper">
        <div className="order_title">
          <VscPackage />
          <h1>Meus Pedidos</h1>
        </div>
        <div className="order_subtitle">
          <h3>
            Somente pedidos realizados a partir de <b>01/10/2023</b> serão exibidos nesta área
          </h3>
        </div>
        <div className="order_filter">
          <InputSearch
            value={search}
            onChange={e => setSearch(e.target.value)}
            width="50%"
            placeholder="Pesquisar pelo Numero do Pedido"
          />
          <p>{orders.length} compras</p>
        </div>

        <div className="order_list">
          <div className="card_order_header">
            <div className="card_order_id">Nº do pedido</div>
            <div className="card_order_date">Data da compra</div>
            <div className="card_order_status" />
          </div>
          <LoadingContainer loading={loading} />

          {!loading && filteredOrders.length > 0 ? (
            filteredOrders.map((order: TOrder) => {
              return (
                <div className="card_order">
                  <div className="card_order_id">{order.id}</div>
                  <div className="card_order_date">{formatIsoDateToDisplay(order.payments[0].createdAt)}</div>
                  <div className="card_order_status">
                    <Button onClick={() => redirectToOrder(order.id)}>Ver pedido completo</Button>
                  </div>
                </div>
              )
            })
          ) : (
            <EmptyState title="Não localizamos pedidos em seu nome" />
          )}
        </div>
      </div>
    </OrdersContainer>
  )
}

export default UserOrderList
