import { THistoryListItem } from 'pages/Account/components/pages/MyPatients/types'

interface LinkCellProps {
  row: THistoryListItem
}
const LinkCell = ({ row }: LinkCellProps) => {
  if (!row.link) return null

  return (
    <div className="flex min-w-40 items-center" style={{ contain: 'strict', height: '30px' }}>
      <a href={row.link} target="_blank" rel="noreferrer" className="flex-1 truncate text-xs text-link-500">
        {row.link}
      </a>
    </div>
  )
}

export default LinkCell
