import Pagination from 'components/Pagination'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { useState } from 'react'
import ProductsTable from '../../../ProductsTable'

export default function OtherIndications() {
  const { products, fetchNeedHelpProducts, totalPages } = useCannectAssistant()
  const [page, setPage] = useState(1)

  function handlePageChange(page: number) {
    setPage(page)
    fetchNeedHelpProducts(page)
  }

  return products?.length !== 0 ? (
    <>
      <ProductsTable products={products} addToPrescriptionMode="changePrescriptionList" />
      <Pagination
        forcePage={page - 1}
        onPageChange={({ selected }: any) => handlePageChange(selected + 1)}
        pageCount={totalPages}
      />
    </>
  ) : (
    <p>Não existem outras indicações para a pesquisa realizada</p>
  )
}
