import styled from 'styled-components'

export const MultiSelectBackground = styled.div`
  width: 100%;
  min-width: 100vw;
  height: 100%;
  min-height: 100vh;
  position: fixed;

  top: 0;
  left: 0;
  z-index: 4;
`

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
  font-family: 'Poppins', sans-serif;
  color: #3d3d3d;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1.5rem;
  }
`

export const SearchSelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
`

interface SearchInputProps {
  hasError: boolean
}
export const SearchInput = styled.input<SearchInputProps>`
  padding: 1rem;
  border-radius: 0.5rem;
  border: ${({ hasError }) => (hasError ? '1px solid #e24444' : '1px solid #d9d9d9')};

  height: 3.7rem;
  z-index: 5;
  transition: border 0.5s;

  &:focus {
    border: 1px solid #3d3d3d;
  }

  &::placeholder {
    color: #d9d9d9;
    opacity: 1;
    font-weight: 300;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const SelectedPillsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  margin-top: 0.5rem;
  max-height: 250px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: rgba(225, 225, 227, 0.6);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #21976e;
    border-radius: 0.5rem;
  }
`

export const SearchSelectOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  z-index: 6;
  border-radius: 0.25rem;

  box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
  max-height: 250px;

  width: 100%;
  top: 3.7rem;
  left: 0;

  gap: 0.25rem;
  background: #ffffff;

  * {
    color: #3d3d3d;
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }
  &::-webkit-scrollbar-track {
    background: rgba(225, 225, 227, 0.6);
    border-radius: 0.5rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #21976e;
    border-radius: 0.5rem;
  }
`

export const InputErrorMessage = styled.p`
  font-size: 1rem;
  color: #e24444;
  margin-top: 0.2rem;
  font-weight: 300;
`
