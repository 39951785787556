import { CollapsableMenuProps } from '../components/CollapsableMenu'
import { PATH_SELECTOR_ICONS } from './icons'
import { SHARED_OPTIONS_DESCRIPTIONS, SIDE_PANEL_HELP, SIDE_PANEL_MY_ORDERS } from './shared'

const OPTIONS_LIST = {
  profile: [
    {
      title: 'Dados pessoais',
      to: '/conta?pagina=perfil-dados-pessoais',
      enabled: true
    },
    {
      title: 'Alterar senha',
      to: '/conta?pagina=perfil-alterar-senha',
      enabled: true
    },
    {
      title: 'Documentos',
      to: '/conta?pagina=perfil-documentos',
      enabled: false
    },
    {
      title: 'Receitas',
      to: '/conta?pagina=perfil-receitas',
      enabled: false
    }
  ],
  addresses: [
    {
      title: 'Endereço de entrega',
      to: '/conta?pagina=enderecos-entrega',
      enabled: true
    },
    {
      title: 'Endereço de cobrança',
      to: '/conta?pagina=enderecos-cobranca',
      enabled: true
    }
  ]
}

export const PATIENT_SIDE_PANEL: CollapsableMenuProps[] = [
  {
    title: {
      label: 'Meu perfil',
      subItemsId: 'perfil',
      icon: PATH_SELECTOR_ICONS.profile,
      enabled: true
    },
    subItems: OPTIONS_LIST.profile
  },
  {
    title: {
      label: 'Endereços',
      subItemsId: 'enderecos',
      icon: PATH_SELECTOR_ICONS.address,
      enabled: true
    },
    subItems: OPTIONS_LIST.addresses
  },
  SIDE_PANEL_MY_ORDERS,
  {
    title: {
      label: 'Meus cartões',
      to: '/conta?pagina=cartoes',
      icon: PATH_SELECTOR_ICONS.card,
      enabled: false
    }
  },
  {
    title: {
      label: 'Minhas consultas',
      to: '/conta?pagina=consultas',
      icon: PATH_SELECTOR_ICONS.consult,
      enabled: true
    }
  }
  /* SIDE_PANEL_HELP */
]

export const PATIENT_LINKS_DESCRIPTIONS = {
  'Meu perfil': 'Altere ou consulte seus dados de acesso, dados pessoais, documentos e receitas',
  Endereços: 'Altere endereços cadastrados para entrega de pedidos e cobrança de faturas',
  'Meus cartões': 'Altere, consulte ou remova seus cartões cadastrados',
  'Minhas consultas': 'Verifique sua agenda de consultas',
  ...SHARED_OPTIONS_DESCRIPTIONS
}
