/* eslint-disable react/jsx-no-constructed-context-values */
import useStorageState from 'hooks/useStorageState'
import { useTokenApi } from 'hooks/useTokenApi'
import { createContext, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { fromResponseUserToUser, INITIAL_USER } from './helpers'
import { FetchUserResponse, SignedUserProviderProps, User } from './types'

const SignedUserContext = createContext({} as SignedUserContextValue)

interface SignedUserContextValue {
  signedUser: User
  isUserLoading: boolean
  refetchSignedUser: () => void
  shouldOpenPrescriberTerms: boolean
  setShouldOpenPrescriberTerms: (value: boolean) => void
}

export function SignedUserProvider({ children }: SignedUserProviderProps) {
  const history = useHistory()
  const [signedUser, setSignedUser] = useStorageState<User>('@CANNECT_SIGNED_USER', INITIAL_USER)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [shouldOpenPrescriberTerms, setShouldOpenPrescriberTerms] = useState(false)
  const { tokenApi, deleteTokenApi } = useTokenApi()

  const refetchSignedUser = async () => {
    setIsUserLoading(true)
    try {
      const response = await api.get<FetchUserResponse>(`/my-account/information`)
      setSignedUser(fromResponseUserToUser(response.data))
      if (
        response.data.prescriber &&
        response.data.user.accepted_terms_of_use === false &&
        response.data.prescriber.listed
      ) {
        setShouldOpenPrescriberTerms(true)
      }
    } catch (err: any) {
      console.log(err)
      if (err.response?.status === 401) {
        deleteTokenApi()
        setShouldOpenPrescriberTerms(false)
        history.push('/login')
        return
      }
      toast.error('Não foi possível atualizar os dados do usuário logado. Tente deslogar e logar novamente.')
    }
    setIsUserLoading(false)
  }

  useEffect(() => {
    if (!tokenApi) return
    refetchSignedUser()
  }, [tokenApi])

  return (
    <SignedUserContext.Provider
      value={{
        signedUser,
        refetchSignedUser,
        isUserLoading,
        shouldOpenPrescriberTerms,
        setShouldOpenPrescriberTerms
      }}
    >
      {children}
    </SignedUserContext.Provider>
  )
}

export default function useSignedUser() {
  return useContext(SignedUserContext)
}
