/* eslint-disable no-nested-ternary */
import { usePrepareCart } from 'hooks/usePrepareCart'
import { useTokenApi } from 'hooks/useTokenApi'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { FiShoppingCart } from 'react-icons/fi'
import { toast } from 'react-toastify'
import api from 'services/api'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'
import { formatPrice } from 'utils/formatters'
import useMediaQuery from 'hooks/useMediaQuery'
import When from '../../../utils/when'
import { cannectDefaultProduct, cannectDefaultProductNoTarg } from 'assets/img'
import { useEffect, useState } from 'react'
import { BiLoaderCircle } from 'react-icons/bi'
import { MinusCircle, Receipt } from 'phosphor-react'
import * as Styles from './styles'
import useSignedUser from 'hooks/useSignedUser'
import { SelectFlavorModal } from 'new-components/SelectFlavorModal'
import { ProductModalDisabled } from '@cannect/new-components/ProductModalDisabled'
import { ProductModalDisabledNutraceutico } from '@cannect/new-components/ProductModalDisabledNutraceutico'

export interface ProductCardProps {
  name?: string
  translated_name?: string
  isFavorite?: boolean
  real_price?: number
  image?: string
  handleFavorite?: () => void
  id: number
  supplier_id?: number
  importProduct?: (val: any) => void
  handleLogin?: any
  promotional?: any
  product?: any
  isAssistant?: boolean
  prescribedProduct?: boolean
  stock?: number
  isRelatedProduct?: boolean
}

export default function ProductCard({
  name,
  translated_name,
  isFavorite,
  handleFavorite = () => null,
  real_price,
  image,
  id,
  supplier_id,
  importProduct = () => null,
  handleLogin = () => null,
  promotional,
  product,
  isAssistant,
  prescribedProduct,
  isRelatedProduct,
  stock
}: ProductCardProps) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { signedUser } = useSignedUser()
  const { tokenApi } = useTokenApi()
  const { addToCart, loadingCupon, lastOrderCreated, AddToCartByFlavor } = usePrepareCart()

  const [products, setProducts] = useState<any[]>([])
  const [flavorModal, setFlavorModal] = useState(false)
  const [loadingOnSave, setLoadingOnSave] = useState(false)
  const [defaultSelectedFlavor, setDefaultSelectedFlavor] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [hasMultipleFlavors, setHasMultipleFlavors] = useState(false)
  const [shippingText, setShippingText] = useState('')
  const [stockValue, setstockValue] = useState<any>(0)
  const [productDisabled, setProductDisabled] = useState(false)
  const [nutraceuticosModal, setNutraceuticosModal] = useState(false)
  const [anvisaModal, setAnvisaModal] = useState<boolean>(false)
  const [anvisaModalMessage, setAnvisaModalMessage] = useState<string>("")

  useEffect(() => {
    if (lastOrderCreated?.items) {
      const essentialProductsData = products.map((product: any) => ({
        id: product.product.id,
        quantity: product.quantity
      }))
      setProducts(essentialProductsData)
    }
  }, [lastOrderCreated])

  useEffect(() => {
    setLoading(loadingCupon)
  }, [loadingCupon])

  useEffect(() => {
    if (stock !== undefined) {
      setstockValue(stock)
    }
  })

  useEffect(() => {
    const checkMultipleFlavors = async () => {
      const { data } = await api.get(`/get_product_flavors/${id}`)
      if (data?.success && data?.productFlavors?.length > 0) {
        setHasMultipleFlavors(true)
        if (product.supplier_id !== '18') {
          setShippingText('importado: entrega em até 25 dias')
          if (product.stock <= 0) {
            setShippingText('possível maior tempo de entrega')
          }
        } else {
          setShippingText('Entrega em até 72 horas')
          if (stockValue <= 0) {
            setShippingText('possível maior tempo de entrega')
          }
        }
      } else {
        setHasMultipleFlavors(false)
        if (supplier_id !== 18) {
          setShippingText('importado: entrega em até 25 dias')
          if (stockValue <= 0) {
            setShippingText('possível maior tempo de entrega')
          }
        } else {
          setShippingText('Entrega em até 72 horas')
          if (stockValue <= 0) {
            setShippingText('possível maior tempo de entrega')
          }
        }
      }
    }

    checkMultipleFlavors()
  }, [id, product?.stock])

  const handleSaveFlavor = async (items: any[]) => {
    setLoadingOnSave(true)
    const itemArray = Array.isArray(items) ? items : [items]

    if (isAssistant) {
      await importProduct(itemArray)
    } else {
      await AddToCartByFlavor(itemArray)
    }
  }

  const handleOk = () => {
    setLoadingOnSave(false)
    setFlavorModal(false)
  }

  const getProductDetails = () => {
    window.open(`/detalhes-produtos/${id}`, '_blank')
  }

  const handleFavoriteCard = () => {
    handleFavorite()
    try {
      api.post(`/product/favorite/${id}`)
    } catch (e) {
      toast.error('Erro ao favoritar')
      handleFavorite()
    }

    const msg = isFavorite ? 'Produto removido dos favoritos' : 'Produto adicionado aos favoritos'
    toast.success(msg)
  }

  const textAssistant = prescribedProduct ? 'Selecionado' : 'Prescrever'

  const infoBySupplier = {
    shippingText,
    buttonText: supplier_id === 18 || supplier_id === 27 ? 'Comprar' : 'Importar',
    defaultImage: supplier_id === 18 || supplier_id === 27 ? cannectDefaultProduct : cannectDefaultProductNoTarg
  }

  const verifyBrandProduct = () => {
    const listProductDisabled = [ 14977, 14978, 14980, 14981, 14982, 14983 ]
    const brandDisabled = [23, 26, 49]
    const productDisabled = listProductDisabled.includes(product?.id) || brandDisabled.includes(product?.brand?.id)
    return productDisabled ? true : false
  }

  const handleAddToCart = async (id: any) => {
    const listProductDisabled = [
      15436,
      15401,
      15437,
      15438,
      15439,
      15440,
      15441,
      15442,
      15443,
      15444,
      15445,
      15446,
      15447,
      15448,
      15449,
      15450,
      15451,
      15452,
      15453,
      15454,
      15455,
      15456,
      15457,
      15458,
      15459,
      15460,
      15461,
      15462,
      15463]
    const productDisabled = listProductDisabled.includes(product?.id)
    if (!productDisabled) {
    
    if(verifyBrandProduct()){
      setAnvisaModal(true)
      setFlavorModal(true)
      return
    }
    if (hasMultipleFlavors) {
      setAnvisaModal(false)
      setFlavorModal(true)
      setDefaultSelectedFlavor(product)
      setLoading(false)
    } else {
    setLoading(true)

      try {
        if (isAssistant) {
          await importProduct(product)
        } else {
          await addToCart(id)
        }
      } catch (e) {
        console.log('Erro na busca de sabores: ', e)
      } finally {
        setLoading(false)
      } 
    }
  }else {
    setNutraceuticosModal(true)
  }
  }

  const handleCloseFlavorModal = () => {
    setFlavorModal(false)
  }

  const validPromotion = new Date(promotional?.promotion_info?.start_date) <= new Date()
  const isPatientUserType = signedUser?.registration_type === 1
  const selectedImage = isPatientUserType && !(product?.class_id == 6) ? infoBySupplier.defaultImage : image

  useEffect(() => {
    const listProductDisabled = [14977, 14978, 14980, 14981, 14982, 14983]
    let productDisabled = listProductDisabled.includes(product?.id)
    setAnvisaModal(productDisabled ? true : false)
    setAnvisaModalMessage('Identificamos que uma ou mais marcas da sua prescrição estão temporariamente indisponíveis para importação pela Anvisa. Isso pode impactar o tempo de entrega do seu tratamento. Prefere seguir com o pedido ainda assim ou quer nosso suporte para entrar em contato com seu prescritor para uma possível troca por outras marcas disponíveis?')
  }, [])




  return (
    <Styles.Wrapper withAuth={!!tokenApi}>
      <Styles.DetailsContent>
        <When expr={!!tokenApi}>
          <Styles.FavoriteBox onClick={handleFavoriteCard}>
            {isFavorite ? <AiFillHeart color={temporaryTheme.colors.red} /> : <AiOutlineHeart color="#858585" />}
          </Styles.FavoriteBox>
        </When>

        <Styles.ImageContainer>
          <Styles.ImageBox>
            <Styles.Image src={selectedImage} alt={`imagem do produto ${name}`} />

            <Styles.MoreInfo>
              <Styles.ButtonMoreInfo onClick={getProductDetails}>detalhes</Styles.ButtonMoreInfo>
            </Styles.MoreInfo>
          </Styles.ImageBox>
        </Styles.ImageContainer>

        <Styles.TitleProductBox onClick={getProductDetails}>
          <Styles.TitleProduct>{translated_name || name}</Styles.TitleProduct>

          <When expr={!!tokenApi && isMobile && !isRelatedProduct && !isPatientUserType}>
            <Styles.PriceBox>
              <Styles.Price>
                {formatPrice({
                  value: validPromotion ? promotional?.promotional_price : real_price,
                  currencyDisplay: 'symbol',
                  currencyStyle: 'BRL',
                  type: 'pr-br'
                })}
              </Styles.Price>
            </Styles.PriceBox>
          </When>
        </Styles.TitleProductBox>
      </Styles.DetailsContent>

      <Styles.ProductSabor
        bgColor={supplier_id !== 18 && supplier_id !== 27 ? 'orange' : 'green'}
        fontColor={supplier_id !== 18 && supplier_id !== 27 ? 'orange' : 'green'}>
        {!!loading && (
          <div className="load-box">
            <BiLoaderCircle />
          </div>
        )}
        {supplier_id !== 27 ? 'Necessário prescrição' : 'Não é necessário prescrição'}
      </Styles.ProductSabor>

      <Styles.ActionsContent>
        <When expr={!!tokenApi && !isMobile && !isRelatedProduct && !isPatientUserType}>
          <Styles.PriceBox>
            <Styles.Price>
              {formatPrice({
                value: validPromotion ? promotional?.promotional_price : real_price,
                currencyDisplay: 'symbol',
                currencyStyle: 'BRL',
                type: 'pr-br'
              })}
            </Styles.Price>
          </Styles.PriceBox>
        </When>

        <Styles.ActionBox>
          {tokenApi ? (
            <div className="acc">
              {isAssistant ? (
                <Styles.ButtonAssistant
                  onClick={() => handleAddToCart(id)}
                  isLoading={loading}
                  prescribedProduct={!!prescribedProduct}>
                  {!!loading && (
                    <div className="load-box">
                      <BiLoaderCircle />
                    </div>
                  )}
                  {textAssistant}

                  {prescribedProduct ? <MinusCircle size={18} /> : <Receipt size={18} />}
                </Styles.ButtonAssistant>
              ) : (
                <Styles.ButtonProduct
                  onClick={() => handleAddToCart(id)}
                  isLoading={loading}
                  className="button-action_product"
                  bgColor={supplier_id === 18 || supplier_id === 27 ? 'green' : 'orange'}>
                  {!!loading && (
                    <div className="load-box">
                      <BiLoaderCircle />
                    </div>
                  )}
                  {infoBySupplier.buttonText} <FiShoppingCart />
                </Styles.ButtonProduct>
              )}
            </div>
          ) : (
            <Styles.ButtonProduct onClick={handleLogin}>Faça login para ver mais</Styles.ButtonProduct>
          )}
        </Styles.ActionBox>

        <When expr={!!tokenApi}>
          <Styles.DetailsTrack isEmpty={!shippingText}>{shippingText || ' '}</Styles.DetailsTrack>
        </When>
      </Styles.ActionsContent>

      
        {/* <SelectFlavorModal
          open={flavorModal}
          handleCancel={handleCloseFlavorModal}
          handleOk={() => handleOk()}
          isAssistant={isAssistant as boolean}
          defaultSelected={defaultSelectedFlavor}
          onSave={(items) => handleSaveFlavor(items)}
          loadingOnSave={loadingOnSave}
          isAuthenticated={!!tokenApi}
          imageSelector={(isAuthenticated, defaultSelected) =>
            isAuthenticated
              ? (isPatientUserType ? infoBySupplier.defaultImage : defaultSelected.image) ||
              'path/to/authenticated/default/image.jpg'
              : 'path/to/public/default/image.jpg'
          }
        /> */}
      

      <ProductModalDisabledNutraceutico
        open={nutraceuticosModal}
        handleCancel={() => setNutraceuticosModal(false)}
        isAuthenticated={!!tokenApi}
      />
        { anvisaModal ? (
          <ProductModalDisabled
            open={flavorModal}
            message={anvisaModalMessage}
            urlRedirect="https://api.whatsapp.com/send?phone=5511930321198&text=Ol%C3%A1,%20gostaria%20de%20suporte%20devido%20a%20indisponibilidade%20da%20Anvisa.%20"
            handleCancel={handleCloseFlavorModal}
            isAuthenticated={!!tokenApi}
            onClick={async () => {
              console.log(hasMultipleFlavors)
              if (hasMultipleFlavors) {
                setAnvisaModal(false)
                setFlavorModal(true)
                setDefaultSelectedFlavor(product)
                setLoading(false)
              } else {
                try {
                  if (isAssistant) {
                    await importProduct(product)
                  } else {
                    await addToCart(id)
                  }
                } catch (e) {
                  console.log('Erro na busca de sabores: ', e)
                } finally {
                  setLoading(false)
                }
              }
            }}
          />
        ) : (
          <SelectFlavorModal
            open={flavorModal}
            handleCancel={handleCloseFlavorModal}
            handleOk={() => handleOk()}
            isAssistant={isAssistant as boolean}
            defaultSelected={defaultSelectedFlavor}
            onSave={(items) => handleSaveFlavor(items)}
            loadingOnSave={loadingOnSave}
            isAuthenticated={!!tokenApi}
            imageSelector={(isAuthenticated, defaultSelected) =>
              isAuthenticated
                ? (isPatientUserType ? infoBySupplier.defaultImage : defaultSelected.image) ||
                  'path/to/authenticated/default/image.jpg'
                : 'path/to/public/default/image.jpg'
            }
          />
        )}
        </Styles.Wrapper>
  )
}
