/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

/**
 * Format Data with locale ptBr
 * @link https://date-fns.org
 * @param date string
 * @param toFormat string Ex: 'yyyy-MM-dd' more examples in @link https://date-fns.org/v2.30.0/docs/format
 * @returns string
 */

export const formatDate = (date: Date | string, toFormat: string) => {
  const dateObj = typeof date === 'string' ? parseISO(date) : date
  return format(dateObj, toFormat, {
    locale: ptBR
  })
}
