import styled, { css, keyframes } from 'styled-components';

export const RecommendedTag = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px; // Icon size

  @media (max-width: 768px) {
    text-align: center; // Centraliza o texto para melhor legibilidade em telas pequenas
    font-size: 8.5px;
  }
`;

export const Highlight = styled.div`
  background-color: #f0f9ff; // Light blue background for emphasis
  border-left: 3px solid #1890ff; // Blue border to indicate highlight
  padding: 5px;
  border-radius: 5px;
  margin: 2px 0; // Small margin to avoid visual clashing
  box-shadow: 0 2px 8px rgba(24, 144, 255, 0.5); // Subtle shadow for depth
`;

export const FlavorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; // Alinha os itens à esquerda
  width: 100%;
`;

export const FlavorTag = styled.span<{ selected: boolean }>`
  ${({ selected }) => css`
    color: rgb(25, 135, 84);
    border: 1px solid rgb(25, 135, 84);
    border-radius: 32px;
    padding: 3px 8px;
    cursor: pointer;

    ${selected &&
    css`
      background-color: #198754;
      color: white;
    `}

    @media (max-width: 768px) {
      max-width: 100px; // Limita a largura máxima da tag para evitar deslocamento
      white-space: nowrap; // Impede que o texto seja quebrado em várias linhas
      overflow: hidden; // Esconde o texto que excede a largura máxima
      text-overflow: ellipsis; // Adiciona reticências ao texto que excede a largura máxima
    }
  `}
`;

export const Wrapper = styled.main`
  width: 750px; // Aqui é onde você define a largura do wrapper
  padding-left: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  * {
    font-family: 'Nunito', sans-serif;
  }

  @media (max-width: 768px) {
    width: 100%; // Ajuste para design responsivo
    padding: 10px 0; // Remove a margem lateral para mais espaço
  }
`;

export const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: bold; // Deixa todo o texto em negrito
  color: #555358;
  text-align: center; // Centraliza o texto
  border-bottom: 1px solid #dadada;
  width: 100%;
  padding-bottom: 15px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 0 10px; // Adiciona um pouco de padding apenas no título
  }
`;

export const ProductTitle = styled.h3`
  font-size: 22px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const ProductDescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 8px 0;

  @media (max-width: 768px) {
    flex-direction: column; // Empilha os elementos verticalmente para visualização mobile
    align-items: center;
    padding: 0 10px; // Adiciona um pouco de padding apenas na descrição do produto
  }
`;

export const ProductImageBox = styled.div`
  width: 150px;
  height: 120px;

  @media (max-width: 768px) {
    width: 100px; // Ajusta para telas menores
    height: 100px;
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const DescriptionBox = styled.div`
  max-width: 500px;

  @media (max-width: 768px) {
    max-width: 100%; // Ajuste para design responsivo
    text-align: center; // Centraliza o texto para melhor legibilidade em telas pequenas
  }
`;

export const ProductName = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #3d3d3d;
  margin-bottom: 12px;
`;

export const QuantityStock = styled.p`
  color: #3d3d3d;
  font-weight: 700;
`;

export const ProductVariantWrapper = styled.div`
  position: relative; // Para posicionar a indicação de scroll

  @media (max-width: 768px) {
    width: 100%; // Ajuste para design responsivo
    overflow-x: auto; // Garante rolagem horizontal se necessário
    padding: 0 10px; // Adiciona um pouco de padding apenas na variante do produto

    &::after {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      color: #888;
      pointer-events: none; // Evita que o texto interfira na rolagem
    }
  }
`;


export const ProductHeaderList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dbdbdb;
  padding: 5px 0;
  margin-bottom: 12px;
  padding-right: 40px;
`;

export const ProductHeaderItem = styled.li`
  color: #6c757d;
  font-weight: 700;
  font-size: 14px;
`;

export const ContentList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
  overflow-y: auto;
  max-height: 160px;
  scrollbar-width: thin;
  scrollbar-color: #ccc transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 6px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #999;
  }
`;

export const ContentRow = styled.li`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`;

const BoxBase = styled.div`
  display: flex;
`;

export const QuantityBox = styled(BoxBase)`
  width: 100px; // Largura padrão para a versão web

  @media (max-width: 768px) {
    width: 60px; // Largura ajustada para a versão mobile
  }
`;

export const StockBox = styled(BoxBase)<any>`
  color: #6c757d;
  font-weight: 400;
  font-size: 13px;
  transform: translateX(17px);
`;

export const ActionsContent = styled.div`
  margin-top: 25px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-top: 1px solid #dbdbdb;

  @media (max-width: 768px) {
    flex-direction: row; // Alinha os botões horizontalmente para visualização mobile
    gap: 10px;
    button {
      flex: 1; // Faz os botões ocuparem o mesmo espaço
      width: auto;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  margin-top: 20px;
  border-spacing: 0 8px;
  margin: 0;

  .unit_value {
    min-width: unset;
  }

  .input_quantity {
    justify-content: center;
    width: 100%; // Largura padrão para a versão web
  }

  .unit_quantity {
    justify-content: center; // Garante que os valores fiquem centralizados na versão web
    text-align: center;
    padding-right: 20px; // Adiciona padding para ajustar o alinhamento
  }

  @media (max-width: 768px) {
    .unit_quantity {
      justify-content: flex-end;
      text-align: left; // Garante que os valores fiquem alinhados à esquerda no modo mobile
      padding-right: 0; // Remove o padding no modo mobile
    }

    .input_quantity {
      width: 50px; // Ajuste para diminuir a largura no modo mobile
      padding-left: 5px;
      margin-right: 13px;
    }

    overflow-x: auto; // Garante rolagem horizontal se necessário
  }
`;


export const TableHead = styled.thead``;

export const TableBody = styled.tbody`
  margin-top: 12px;
  min-height: 152px;
`;

export const TableHeadCell = styled.th`
  text-align: center; // Alinhamento padrão

  @media (max-width: 768px) {
    &.unit_price_header {
      text-align: left; // Alinhamento à esquerda no modo mobile
    }
  }
`;

export const TableRow = styled.tr`
  &:first-child {
    text-align: left;
    margin-left: 1rem;
  }

  .input_quantity {
    text-align: center;
  }

  .unit_quantity {
    text-align: center;
    margin-left: 4rem; // Adiciona padding para ajustar o alinhamento

    @media (max-width: 768px) {
      &.unit_quantity {
        text-align: center;
        margin-left: 0rem;
      }
    }
  }
`;

export const TableCell = styled.td`
  @media (max-width: 768px) {
    font-size: 12px; // Ajusta o tamanho da fonte para telas menores
  }
`;

// Estilos específicos para visualização mobile
const mobileStyles = css`
  ${Wrapper} {
    width: 100%; // Ajuste para design responsivo
    padding: 10px;
  }

  ${Title} {
    font-size: 1.1rem;
  }

  ${ProductTitle} {
    font-size: 18px;
  }

  ${ProductDescriptionWrapper} {
    flex-direction: column; // Empilha os elementos verticalmente para visualização mobile
    align-items: center;
  }

  ${ProductImageBox} {
    width: 100px; // Ajusta para telas menores
    height: 100px;
  }

  ${DescriptionBox} {
    max-width: 100%; // Ajuste para design responsivo
    text-align: center; // Centraliza o texto para melhor legibilidade em telas pequenas
  }

  ${ActionsContent} {
    flex-direction: row; // Alinha os botões horizontalmente para visualização mobile
    gap: 10px;
    button {
      flex: 1; // Faz os botões ocuparem o mesmo espaço
      width: auto;
    }
  }

  ${TableCell}, ${TableHeadCell} {
    font-size: 12px; // Ajusta o tamanho da fonte para telas menores
    white-space: nowrap; // Impede que o texto seja quebrado em várias linhas
  }

  .unit_price_header {
    text-align: left; // Alinha o texto à esquerda no modo mobile
  }

  ${RecommendedTag} {
    text-align: center; // Centraliza o texto para melhor legibilidade em telas pequenas
    display: block;
  }

  ${FlavorTag} {
    max-width: 100px; // Limita a largura máxima da tag para evitar deslocamento
    white-space: nowrap; // Impede que o texto seja quebrado em várias linhas
    overflow: hidden; // Esconde o texto que excede a largura máxima
    text-overflow: ellipsis; // Adiciona reticências ao texto que excede a largura máxima
  }
`;

// Aplicando media query para estilos mobile
const applyMobileStyles = css`
  @media (max-width: 768px) {
    ${mobileStyles}
  }
`;

export const GlobalStyle = styled.div`
  ${applyMobileStyles}
`;

