import { BsWhatsapp } from 'react-icons/bs'
import { useAuth } from 'hooks/AuthContext'
import { FloatButton } from 'antd'
import { redirectLink } from 'utils/redirectLink'
import { useHistory } from 'react-router-dom'

function WrapperWhatsapp() {
  const { role } = useAuth()
  const history = useHistory()

  const isOnTriagemPage = history.location.pathname === '/triagem'
  const isAssistantPage = history.location.pathname === '/assistente'

  const validateText = role === 1 || role === 0 ? 'Fale com um cannecter' : 'Fale com o relacionamento médico'

  const validLink = () => {
    let validateAction
    if (isOnTriagemPage) {
      validateAction = 'https://atendimento.cannect.life/triagem'
    } else if (role === 1 || role === 0) {
      validateAction = 'https://atendimento.cannect.life/atendimento-cannect'
    } else if (isAssistantPage) {
      validateAction = 'https://wa.me/5511964065425'
    } else {
      validateAction = 'https://wa.me/5511992208271'
    }

    return validateAction
  }

  return !window.location.pathname.includes('receitas') ? (
    <FloatButton.Group type="primary" style={{ right: 40, bottom: 20 }}>
      <FloatButton
        icon={<BsWhatsapp />}
        onClick={() => redirectLink(validLink())}
        tooltip={<div>{validateText}</div>}
      />
      <FloatButton.BackTop />
    </FloatButton.Group>
  ) : null
}

export default WrapperWhatsapp
