import styled from 'styled-components'

interface SearchSelectOptionContainerProps {
  isSelected: boolean
}
export const SearchSelectOptionContainer = styled.button<SearchSelectOptionContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isSelected }) => (isSelected ? 'space-between' : 'flex-start')};

  padding: 0.75rem 1.25rem;
  background: transparent;
  cursor: pointer;

  border: none;
  transition: background 0.3s;

  &:hover {
    background: #d9d9d9;
  }

  svg {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    min-height: 1.25rem;
  }
`
