import React from 'react'
import Button from 'components/Button'
import { Modal } from 'components/Modal'
import Text from '../../Text'

interface ModalFilterProps {
  isOpenFilterModal: boolean
  children: React.ReactNode
}

export default function ModalFilter({ isOpenFilterModal, children }: ModalFilterProps) {
  return (
    <Modal
      isOpened={isOpenFilterModal}
      onRequestClose={() => {
        console.log('a')
      }}
      className="modal-produtos"
    >
      {children}
    </Modal>
  )
}
