import { Button } from 'design-cannect'
import DeactivatableButton from 'pages/CannectCare/DeactivatableButton'
import { useCannectCare } from 'hooks/useCannectCare'
import ConfirmationDialogue from 'components/ConfirmationDialogue'
import { useState } from 'react'
import Disease from '../../Disease'
import OtherDisease from '../../OtherDisease'
import * as Style from '../styles'

export default function SideEffectsSelection() {
  const {
    sideEffects,
    toggleSideEffectSelection,
    otherSymptoms,
    addOtherSysmptom,
    changeOtherSymptomName,
    setPath,
    setStep,
    sendSideEffects
  } = useCannectCare()
  const [isErrorDialogueOpened, setIsErrorDialogueOpened] = useState(false)

  function handleGoBackClick() {
    setPath('')
    setStep(0)
  }

  async function handleSaveClick() {
    const successfulySent = await sendSideEffects()

    if (successfulySent) {
      setStep(1)
    } else {
      setIsErrorDialogueOpened(true)
    }
  }

  return (
    <>
      <h3>
        <strong>Selecione aqui quais efeitos colaterais você sentiu recentemente.</strong>
      </h3>
      <Style.DiseasesContainer>
        {sideEffects.map(sideEffect => (
          <Disease
            img={sideEffect.selected ? sideEffect.imgChecked : sideEffect.imgUnchecked}
            label={sideEffect.label}
            selected={sideEffect.selected}
            onClick={() => toggleSideEffectSelection(sideEffect.id)}
          />
        ))}
        {otherSymptoms.map(symptom => (
          <OtherDisease
            onChange={value => {
              changeOtherSymptomName(symptom.id, value)
            }}
            value={symptom.label}
          />
        ))}
        {/* <DeactivatableButton
          onClick={() => addOtherSysmptom()}
          className="add-other-symptom-button"
          disabled={
            otherSymptoms[otherSymptoms.length - 1] && otherSymptoms[otherSymptoms.length - 1].label.length === 0
          }
        >
          Adicionar outro sintoma
        </DeactivatableButton> */}
      </Style.DiseasesContainer>
      <Style.ActionsButtons>
        <Button outlined onClick={() => handleGoBackClick()}>
          Voltar
        </Button>
        <Button onClick={() => handleSaveClick()}>Salvar</Button>
      </Style.ActionsButtons>

      <ConfirmationDialogue
        isOpened={isErrorDialogueOpened}
        onRequestClose={() => setIsErrorDialogueOpened(false)}
        onConfirmationClick={() => setIsErrorDialogueOpened(false)}
        title="Erro ao enviar sintomas!"
        description="Houve um erro ao enviar os sintomas. Tente novamente mais tarde."
        confirmationButtonText="Entendi!"
        confirmationOnlyDialogue
      />
    </>
  )
}
