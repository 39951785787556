import { uploadSvg } from 'assets/svg'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import SimulatePdf from './SimulatePDF'
import * as Style from './styles'
import api from '../../../../../../services/api'
import Loading from '../../../../../../components/Loading'
import { useCannectAssistant } from '../../../../../../hooks/useCannectAssistant'
import useMediaQuery from '../../../../../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../../../../../utils/constants'

interface Document {
  id: number
  type: string
}

export type SignedUser = {
  id: number
  prescriber_id: string
  uuid: string
  photo: string
} | null

export default function PreviewRecipe() {
  const hiddenClinicLogoInput = React.useRef<null | any>(null)
  const [selectedImgStr, setSelectedImgStr] = useState('')
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const {
    prescription,
    loading: loadingPrescriptionData,
    setStep,
    changePrescriptionPrescriber,
    changeProfilePicture
  } = useCannectAssistant()

  const handleClick = (input: any) => {
    input?.current?.click()
  }

  const handleClinicLogoUpload = async (event: any) => {
    const { files } = event.target

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      formData.append('file', file)
    }

    try {
      setLoading(true)
      await api.put('/prescriber/prescriber_clinic_logo', formData)
      loadImagePreview(event.target.files[0])
    } catch (e) {
      toast.error('Houve um erro ao fazer upload do logo. Tente novamente.')
    } finally {
      setLoading(false)
    }
  }

  function loadImagePreview(img: File) {
    const oFReader = new FileReader()
    oFReader.readAsDataURL(img)

    oFReader.onload = oFREvent => {
      if (oFREvent.target === null) return
      if (oFREvent.target.result === null) return
      if (typeof oFREvent.target.result === 'object') return

      setSelectedImgStr(oFREvent.target.result)
      changeProfilePicture(oFREvent.target.result)
    }
  }

  const onLogoPositionChange = (newPosition: string) => {
    changePrescriptionPrescriber('logoPosition', newPosition)
    api.put('/prescriber/logo-position', {
      logoPosition: newPosition
    })
  }

  const onNextStepClick = () => {
    setStep(state => state + 1)
  }

  useEffect(() => {
    if (prescription.prescriber.profilePicture) {
      setStep(state => state + 1)
    }
  }, [loadingPrescriptionData])

  return (
    <>
      <Style.Title style={{ marginBottom: '4rem' }}>Pré-visualização da receita:</Style.Title>
      <Loading loading={loading || loadingPrescriptionData} />
      <Style.Container isMobile={isMobile}>
        <Style.ContainerLeft>
          <Style.UploadContainer
            isMobile={isMobile}
            onClick={() => handleClick(hiddenClinicLogoInput)}
            hasError={false}
          >
            <Style.ButtonUpload onClick={() => handleClick(hiddenClinicLogoInput)}>
              <img src={uploadSvg} alt="upload" />
            </Style.ButtonUpload>
            <input
              type="file"
              ref={hiddenClinicLogoInput}
              style={{ display: 'none' }}
              onChange={handleClinicLogoUpload}
            />
            <Style.ContentUpload isMobile={isMobile}>
              <h3>Clique para subir o arquivo da logo!</h3>
              <h4>Formatos aceitos: JPEG, BMP e PNG.</h4>
              <span>Máximo de 5 MB.</span>
            </Style.ContentUpload>
          </Style.UploadContainer>

          <Style.LogoWrapper>
            <Style.LogoContainer hasError={false}>
              {selectedImgStr ? <img src={selectedImgStr} alt="" /> : 'LOGO'}
            </Style.LogoContainer>
          </Style.LogoWrapper>

          <Style.Title style={{ fontSize: '20px' }}>Posicionamento da Logo *</Style.Title>

          <Style.SelectLogoPosition
            value={prescription.prescriber.logoPosition}
            onChange={event => onLogoPositionChange(event.target.value)}
          >
            <option value="left">Alinhado a esquerda</option>
            <option value="center">Alinhado ao centro</option>
            <option value="right">Alinhado a direita</option>
          </Style.SelectLogoPosition>
        </Style.ContainerLeft>
        <Style.ContainerRight>
          <SimulatePdf positionLogo={prescription.prescriber.logoPosition} img={selectedImgStr} />
        </Style.ContainerRight>
      </Style.Container>
      <Style.ContainerButtons>
        <Style.CancelButton onClick={() => setStep(state => state - 1)}>VOLTAR</Style.CancelButton>
        <Style.SaveButton onClick={onNextStepClick}>SALVAR</Style.SaveButton>
      </Style.ContainerButtons>
    </>
  )
}
