/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react'
import GlobalStyles from './styles/resetCSS'
import './styles/index.css'
import App from './App'
import { ToastContainer } from 'react-toastify'
import './assets/fonts/itc-avant-garde-gothic-demi.ttf'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom'

const container = document.getElementById('root')
/* const root = createRoot(container!) */
const contextClass = {
  success: 'bg-primary-400 text-primary',
  error: 'bg-red-500 text-white',
  info: 'bg-blue-500 text-white',
  warning: 'bg-orange-400 text-white',
  default: 'bg-gray-50 text-primary'
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
    <ToastContainer
      toastClassName={(context) =>
        `${contextClass[context?.type || 'default']} mb-4 rounded-2xl shadow-md font-semibold font-sans min-h-20 flex items-center justify-center gap-2 px-4 py-2`
      }
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      theme="colored"
      pauseOnHover
    />
  </React.StrictMode>,
  container
)

reportWebVitals()
