export default {
  counter: {
    spring: {
      type: 'spring',
      damping: 10,
      stiffness: 300,
      y: { duration: 0.15 },
      opacity: { duration: 0.15 },
      scale: { duration: 0.12 },
      filter: { duration: 0.15 }
    },
    slideUp: {
      y: '6px',
      opacity: 0.25,
      scale: 0.95,
      filter: 'blur(1px)'
    },
    slideDown: {
      y: '-6px',
      opacity: 0.25,
      scale: 0.95,
      filter: 'blur(1px)'
    }
  }
}
