import Loading from 'components/Loading'
import Button from 'new-components/Button'
import Input from 'new-components/Input'
import MultiSelect from 'new-components/MultiSelect'
import Select from 'new-components/Select'
import {
  GENDER_IDENTITY_LIST,
  NATIONALITY_LIST
} from 'pages/Registration/components/Forms/components/RegistrationForm/constants'
import { HAS_PRESCRIBED_BEFORE_LIST } from 'pages/Registration/components/Forms/components/ValidationForm/constants'
import { getFieldError } from 'pages/Registration/components/Forms/helpers'
import { MdInfoOutline } from 'react-icons/md'
import { dateMask, formatCpf, formatPhone } from 'utils/formatters'
import usePersonalData from './hooks/usePersonalData'
import * as Styles from './styles'

function PersonalData() {
  const {
    name,
    nationality,
    preferredName,
    setPreferredName,
    genderIdentity,
    cpf,
    rg,
    rne,
    birthdate,
    telephone,
    setTelephone,
    cellphone,
    specialties,
    hasPrescribedBefore,
    changeFieldValue,
    changeSpecialties,
    errors,
    specialtiesList,
    onRegistrationFormSubmit,
    signedUser,
    loading
  } = usePersonalData()

  return (
    <Styles.PersonalDataContainer onSubmit={onRegistrationFormSubmit}>
      <Loading loading={loading} />

      <Styles.InputsContainer>
        <Styles.VariableWidthContainer width="67%">
          <Input
            id="name"
            label="Nome completo*"
            placeholder="Digite seu nome completo"
            value={name}
            errorMessage={getFieldError('name', errors)}
            onChange={event => changeFieldValue('name', event.target.value)}
            disabled
          />
        </Styles.VariableWidthContainer>
        <Styles.VariableWidthContainer width="33%">
          <Select
            id="nationality"
            label="Nacionalidade*"
            options={NATIONALITY_LIST}
            value={nationality}
            errorMessage={getFieldError('nationality', errors)}
            onChange={newValue => changeFieldValue('nationality', newValue)}
          />
        </Styles.VariableWidthContainer>
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        <Input
          id="preferred-name"
          label="Nome de preferência"
          placeholder="Escolha como prefere ser chamado"
          value={preferredName}
          onChange={event => setPreferredName(event.target.value)}
        />
        <Select
          id="gender-identity"
          label="Identidade de gênero*"
          options={GENDER_IDENTITY_LIST}
          value={genderIdentity}
          errorMessage={getFieldError('genderIdentity', errors)}
          onChange={newValue => changeFieldValue('genderIdentity', newValue)}
        />
        <Input
          id="cpf"
          label="CPF*"
          placeholder="Digite seu CPF"
          value={cpf}
          errorMessage={getFieldError('cpf', errors)}
          onChange={event => changeFieldValue('cpf', formatCpf(event.target.value))}
          disabled
        />
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {nationality === 'estrangeira' ? (
          <Input
            id="rne"
            label="RNE*"
            placeholder="Digite seu RNE"
            value={rne}
            errorMessage={getFieldError('rne', errors)}
            onChange={event => changeFieldValue('rne', event.target.value)}
          />
        ) : (
          <Input
            id="rg"
            label="RG*"
            placeholder="Digite seu RG"
            value={rg}
            errorMessage={getFieldError('rg', errors)}
            onChange={event => changeFieldValue('rg', event.target.value)}
          />
        )}
        <Input
          id="birthdate"
          label="Data de nascimento*"
          placeholder="dd/mm/aaaa"
          value={birthdate}
          errorMessage={getFieldError('birthdate', errors)}
          onChange={event => changeFieldValue('birthdate', dateMask(event.target.value))}
          disabled
        />
        {(signedUser.registration_type === 1 ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
          signedUser.prescriber?.council.toUpperCase() === 'CRM') && (
          <Input
            id="telephone"
            label="Telefone"
            placeholder="(99) 9999-9999"
            value={telephone}
            onChange={event => setTelephone(formatPhone(event.target.value))}
          />
        )}
      </Styles.InputsContainer>

      <Styles.InputsContainer>
        {signedUser.registration_type === 2 ? (
          <>
            {signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
            signedUser.prescriber?.council.toUpperCase() === 'CRM' ? (
              <Styles.VariableWidthContainer width="67%">
                <MultiSelect
                  id="specialties"
                  label="Especialidade(s)*"
                  options={specialtiesList}
                  value={specialties}
                  disabled={signedUser.prescriber?.council.toUpperCase() === 'CRM'}
                  optionsDisabled={signedUser.prescriber?.council.toUpperCase() === 'CRM'}
                  errorMessage={getFieldError('specialties', errors)}
                  onChange={newValues => changeSpecialties(newValues)}
                />
              </Styles.VariableWidthContainer>
            ) : (
              <Input
                id="telephone"
                label="Telefone"
                placeholder="(99) 9999-9999"
                value={telephone}
                onChange={event => setTelephone(formatPhone(event.target.value))}
              />
            )}
            <Styles.VariableWidthContainer
              width={
                signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
                signedUser.prescriber?.council.toUpperCase() === 'CRM'
                  ? '33%'
                  : '100%'
              }
            >
              <Input
                id="cellphone"
                label="Celular/WhatsApp*"
                placeholder="(99) 99999-9999"
                value={cellphone}
                errorMessage={getFieldError('cellphone', errors)}
                onChange={event => changeFieldValue('cellphone', formatPhone(event.target.value))}
              />
            </Styles.VariableWidthContainer>
          </>
        ) : (
          <>
            <Input
              id="cellphone"
              label="Celular/WhatsApp*"
              placeholder="(99) 99999-9999"
              value={cellphone}
              errorMessage={getFieldError('cellphone', errors)}
              onChange={event => changeFieldValue('cellphone', formatPhone(event.target.value))}
            />
            <Styles.InvisibleContainer />
            <Styles.InvisibleContainer />
          </>
        )}
      </Styles.InputsContainer>

      {(signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
        signedUser.prescriber?.council.toUpperCase() === 'CRM' ||
        signedUser.prescriber?.council.toUpperCase() === 'CRMV') && (
        <Styles.InputsContainer style={{ marginTop: '1rem' }}>
          <Select
            id="has-prescribed-before"
            label="Já prescreveu cannabis medicinal?*"
            value={hasPrescribedBefore}
            onChange={newValue => changeFieldValue('hasPrescribedBefore', newValue)}
            errorMessage={getFieldError('hasPrescribedBefore', errors)}
            options={HAS_PRESCRIBED_BEFORE_LIST}
          />
          <Styles.InvisibleContainer />
        </Styles.InputsContainer>
      )}

      <Styles.ConfirmChangesContainer>
        <Styles.ChangeSensibleDataContainer>
          <MdInfoOutline />
          <p>
            Se deseja alterar CPF, nome, data de nascimento ou especialidades, por favor entre em contato com o{' '}
            <a href="https://whts.co/Cannect" target="_blank" rel="noreferrer">
              atendimento Cannect
            </a>
            .
          </p>
        </Styles.ChangeSensibleDataContainer>
        <Button type="submit">ATUALIZAR MEUS DADOS</Button>
      </Styles.ConfirmChangesContainer>
    </Styles.PersonalDataContainer>
  )
}

export default PersonalData
