import RocketLauch from 'assets/svg/rocket_lauch_icon.svg'
import UsersThreeIcon from 'assets/svg/UsersThreeIcon.svg'
import { CiDollar } from 'react-icons/ci'

export const AboutUs = `
<strong>Cannect é a maior rede de cannabis medicinal na América Latina.</strong>
Nós conectamos pacientes, profissionais da saúde e fornecedores
com a missão de viabilizar uma vida melhor através do tratamento
com cannabis.
<br/>
<br/>
Oferecemos pacotes de soluções para atender às necessidades
dos nossos pacientes, profissionais da saúde e marcas parceiras.
Através de um marketplace com mais de 800 produtos, hubs de distribuição
nos EUA e LatAm, além de uma vertical de educação (Dr. Cannabis) e de conteúdo (Cannalize).
`

export const Services = `
A Cannect oferece um conjunto de soluções para garantir<br/> a melhor experiência possível para vocês e seus pacientes.
`

export const AccordionHealth = [
  {
    title: 'Ciência',
    text: `
    O Cannect Ciência é uma ferramenta que <strong>reúne centenas de artigos científicos
    sobre o uso medicinal da cannabis.</strong> Sendo útil para profissionais da saúde, pesquisadores e
    pacientes que desejam aprofundar seu conhecimento sobre a cannabis medicinal e seu potencial
    terapêutico.`
  },
  {
    title: 'Assistente de Prescrição',
    text: `Nosso assistente é uma <strong>ferramenta de prescrição gratuita,
    desenhada para auxiliar e otimizar o fluxo de trabalho </strong> de profissionais
    da saúde na prática clínica diária. Permitindo o cadastro e uso de modelos
    de prescrição, e facilitando o processo de envio de receitas digitais aos pacientes,
    ao enviá-las de forma automatizada via e-mail e SMS.`
  },
  {
    title: 'Educa',
    text: `
    O Cannect Educa reúne uma ampla variedade de recursos
    educacionais através de <strong>whitepapers, webinários,
    podcasts e cursos</strong> desenvolvidos pela Cannect e sua vertical
    de educação (Dr. Cannabis). Sendo uma ferramenta valiosa
    para profissionais da saúde que desejam aprofundar seus
    conhecimentos sobre a cannabis medicinal e seu potencial
    terapêutico
      `
  },
  {
    title: 'Cuida',
    text: `
   <strong> É um serviço de cuidado coordenado,</strong>
    resguardado por um protocolo desenvolvido
    por nossa equipe médica, e sempre
    respaldado nos estudos científicos mais
    recentes, tirando dúvidas, acompanhando o
    tratamento dos pacientes em seu cotidiano,
    garantindo eficácia do tratamento e bem-estar
   dos pacientes.
      `
  }
]

export const DiferencePillars = [
  {
    title: 'Serviços de importação ',
    text: 'Ajudamos você e seus pacientes a importar produtos de suas prescrições',
    icon: <img src={RocketLauch} alt="rocket lauch icon" />
  },
  {
    title: ' Listagem e agendamento',
    text: `Seja listado em nossa base de profissionais e receba agendamentos de pessoas que buscam nossos serviços`,
    icon: <img src={UsersThreeIcon} alt="users three icon" />
  }
]

export const education = `
<strong>Prescrever canabinoides é legal desde 2015, </strong>
nossa vertical de educação, <strong>Dr. Cannabis</strong>,
oferece cursos práticos para que você possa desenvolver as
habilidades que precisa para dar seu próximo passo em direção à
prática clínica associada ao uso medicinal da cannabis.
`

export const consulting = `
Nosso time está disponível para <strong>auxiliar
você nos principais desafios do dia a dia
no consultório.</strong> Desenvolvemos uma jornada personalizada
para auxiliar durante todo o seu processo, oferecendo
ferramentas para facilitar seu entendimento e acolher
seus pacientes.
`
