import { theme } from 'design-cannect'
import { FiCheck } from 'react-icons/fi'
import * as Style from './styles'

interface CheckboxInputProps {
  label: string
  id?: string
  checked: boolean
  onChange: (e?: any) => void
  disabled?: boolean
  value?: any
}

export default function CheckboxInput({ label, id, checked, onChange, disabled, value }: CheckboxInputProps) {
  return (
    <Style.CheckboxInputContainer disabled={disabled}>
      <input id={id} type="checkbox" checked={checked} onChange={onChange} value={value} />
      <Style.VisualInput htmlFor={id} style={label.length !== 0 ? { marginTop: '-3px' } : {}} disabled={disabled}>
        {checked && <FiCheck color="#424242" size={17} />}
      </Style.VisualInput>
      <label htmlFor={id}>{checked ? <b>{label}</b> : label}</label>
    </Style.CheckboxInputContainer>
  )
}
