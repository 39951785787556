import { IRecipeProduct, Path, Product } from 'hooks/useCannectAssistant/types'
import useQueryString from 'hooks/useQueryString'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { fromPrescriptionToApiProducts } from '../helpers'

interface UseTemplateProps {
  setPath: (path: Path) => void
  setStep: (step: number) => void
}

export interface TemplateProduct {
  id: number
  prescription: string
  required_bottles: number
  parent_id: number
  template_id: number
  product_id: number
  product: Product
}

export interface TemplateProductWithAlternatives extends TemplateProduct {
  alternatives: TemplateProduct[]
}

export interface Template {
  id: number
  name: string
  recommendation: string
  products: TemplateProductWithAlternatives[]
}

interface FetchTemplateResponse {
  template: Omit<Template, 'products'>
  products: TemplateProductWithAlternatives[]
  success: boolean
}

export default function useTemplate({ setPath, setStep }: UseTemplateProps) {
  const history = useHistory()
  const {
    filters: { template: templateId }
  } = useQueryString()
  const [template, setTemplate] = useState<Template>()
  const [isTemplateLoading, setIsTemplateLoading] = useState(false)

  const redirectWithMessage = (messageType: 'error' | 'deleted' | 'changed' | 'created') => {
    switch (messageType) {
      case 'created':
        toast.success('Modelo de prescrição criado com sucesso!')
        break
      case 'deleted':
        toast.success('Modelo de prescrição excluído com sucesso!')
        break
      case 'changed':
        toast.success('Modelo de prescrição alterado com sucesso!')
        break
      default:
        toast.error('Erro ao carregar modelo de prescrição. Tente novamente mais tarde.')
    }
    if (messageType === 'deleted' || messageType === 'error') {
      history.push('/assistente')
      setPath('')
      setStep(0)
    }
  }

  const deleteTemplate = async () => {
    if (!template) return
    try {
      await api.delete(`/template/${template.id}`)
      redirectWithMessage('deleted')
    } catch (err) {
      console.error(err)
      redirectWithMessage('error')
    }
  }

  const updateTemplate = async (name: string, recommendation: string, newApiProducts: IRecipeProduct[]) => {
    if (!template) return
    try {
      const response = await api.put(`/template/${template.id}`, {
        name,
        recommendation,
        products: newApiProducts
      })
      if (!response.data.success) {
        redirectWithMessage('error')
        return
      }
      redirectWithMessage('changed')
    } catch (err) {
      console.error(err)
      redirectWithMessage('error')
    }
  }

  const saveTemplate = async (name: string, recommendation: string, apiProducts: IRecipeProduct[]) => {
    try {
      const response = await api.post(`/templates`, {
        name,
        recommendation,
        products: apiProducts
      })
      if (!response.data.success) {
        redirectWithMessage('error')
        return
      }
      redirectWithMessage('created')
    } catch (err) {
      console.error(err)
      redirectWithMessage('error')
    }
  }

  useEffect(() => {
    const fetchTemplate = async () => {
      setIsTemplateLoading(true)
      try {
        const {
          data: { success, template, products }
        } = await api.get<FetchTemplateResponse>(`/template/${templateId}`)
        if (!success) {
          redirectWithMessage('error')
          return
        }

        setTemplate({
          id: template.id,
          name: template.name,
          recommendation: template.recommendation,
          products: products.map(product => ({
            ...product,
            includedInPrescription: true
          }))
        })
      } catch (err) {
        console.error(err)
        redirectWithMessage('error')
      }
      setIsTemplateLoading(false)
    }
    if (templateId) {
      fetchTemplate()
    }
  }, [templateId])

  return {
    template,
    isTemplateLoading,
    saveTemplate,
    updateTemplate,
    deleteTemplate
  }
}
