import styled, { css } from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MAX_PAGE_WIDTH, MOBILE_WIDTH } from 'utils/constants'
import { BannerHealthProps } from '.'

type WrapperStyleProps = Pick<BannerHealthProps, 'backgroundImage'>

export const Wrapper = styled.main<WrapperStyleProps>`
  ${({ backgroundImage }) => css`
    background-image: url(${backgroundImage});
    width: 100%;
    height: 380px;
    min-height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${temporaryTheme.spacing.space4};

    @media (max-width: 900px) {
      height: unset;
      min-height: unset;
      padding: ${temporaryTheme.spacing.space4};
    }
  `}
`

export const Content = styled.div`
  width: 100%;
  max-width: 1366px;
`

export const TitleBanner = styled.h2`
  font-family: 'Spartan', sans-serif;
  font-weight: 400;
  max-width: 588px;
  color: ${temporaryTheme.colors.white};
  font-size: 45px;
  margin-bottom: 9px;
  span {
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
  }

  @media (max-width: 900px) {
    font-size: 28px;
  }
`

export const TextBanner = styled.p`
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
  font-size: 19px;
  color: ${temporaryTheme.colors.white};
  max-width: 626px;
  margin-bottom: 36px;
  @media (max-width: 900px) {
    font-size: 18px;
  }
`

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${temporaryTheme.spacing.space3};
  button {
    width: 332px;
    font-family: 'Spartan', sans-serif;
    font-weight: bold;
    line-height: 18px;
  }

  button:last-child {
    color: ${temporaryTheme.colors.white};
  }
`
