import { useEffect, useState } from 'react'
import Banner from 'components/Banner'
import { usePrepareCart } from 'hooks/usePrepareCart'
import api from 'services/api'
import useQueryString from 'hooks/useQueryString'
import LoadingContainer from 'components/LoadingContainer'
import { useHistory } from 'react-router'
import Step from '../NewCart/Steps'
import { Address } from './Address'
import {
  Container,
  DocumentNotificationBox,
  ContainerResumePay,
  TextDocument,
  ButtonContainer,
  BackCart
} from './styles'
import PaymentMethods from './PaymentMethod'
import useMediaQuery from '../../hooks/useMediaQuery'
import { MOBILE_WIDTH } from '../../utils/constants'
import { getEvents } from 'services/events'
import { OrderTypes } from 'types/OrderTypes'
import { StyledCheckbox } from '../AppointmentScheduling/FormSteps/CheckoutStep/PaymentStep/styles'
import { d } from 'msw/lib/glossary-de6278a9'

function NewCartDocuments() {
  const [isAllAddress, setIsAllAddress] = useState(false)
  const [error, setError] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [address, setAddress] = useState<any>({
    shippingAddress: {},
    billingAddress: {}
  })
  const { lastOrderCreated, loading: loadignOrdens, getLastOrder } = usePrepareCart()

  const [confirmDialogDocument, setConfirmDialogDocument] = useState(false)
  const isValidateSubmit = !isAllAddress || !confirmDialogDocument
  const [loadingContainer, setLoading] = useState(false)
  const history = useHistory()
  const { filters } = useQueryString()
  const cart_id = filters?.cart_id

  useEffect(() => {
    getLastOrder()
  }, [])

  const handleAddress = (type: 'shippingAddress' | 'billingAddress', data: any) => {
    setAddress({ ...address, [type]: data })
  }

  function onFinishPayment(orderInfos: OrderTypes, paymentMethod: string, paymentStatus?: string) {
    const itemsIds = orderInfos?.items?.map((item: any) => item.id)

    const eventData = {
      eventName: 'purchase',
      orderId: orderInfos?.id,
      amount: orderInfos?.amount,
      items_ids: itemsIds,
      patient_id: orderInfos?.patient_id,
      payment_method: paymentMethod || '',
      coupon_id: orderInfos?.coupon_id,
      payment_status: paymentStatus || ''
    }

    getEvents.eventSendDataLayer(eventData)
  }

  const finishOrderByPaymentMethod = async ({ type, creditCard }: any) => {
    const BASE_PAYMENT_URL = cart_id ? 'order_cart_public' : 'order_cart'
    const { documents, recipes } = lastOrderCreated
    const { shippingAddress, billingAddress } = address

    try {
      setLoading(true)
      const payload = {
        documents: (documents?.flat()?.length > 0 || documents.length > 0) && (documents?.documents_order || documents),
        recipes: [recipes[recipes.length - 1]],
        shippingAddress,
        billingAddress,
        creditCard,
        paymentMethod: { type },
        person_id: cart_id && lastOrderCreated?.person?.id
      }

      const { data } = await api.put(`/${BASE_PAYMENT_URL}/${lastOrderCreated.id}`, payload)
      if ( data.paymentInfos.status === "paid"){
        onFinishPayment(data?.order, type, data?.paymentInfos?.status)
      }
      return data
    } catch (error) {
      setError(true)
      return { error: true }
    } finally {
      setLoading(false)
    }
  }
  let interval: any

  function finishOrderByPaymentMethodInterval() {
    interval = setInterval(async () => {
      getLastOrder()
    }, 7000)
  }
  function clearIntervalOutside() {
    clearInterval(interval)
  }

  if (loadignOrdens) return <LoadingContainer loading />

  const backToCart = () => {
    const url = cart_id ? `/carrinho-wa?cart_id=${cart_id}` : `/carrinho`
    history.push(`${url}`)
  }

  return (
    <Container>
      <Step step={2} />
      {/* <Documents /> */}
      <DocumentNotificationBox>
        <TextDocument>
          Eu, declaro estar ciente de que, após efetuar o pagamento e a fim de permitir a continuidade do processo de
          importação dos produtos, assumo a responsabilidade por fornecer os seguintes documentos necessários conforme
          exigido pela ANVISA (Agência Nacional de Vigilância Sanitária).
          <br />
          <br />
          Os documentos mencionados podem ser enviados na próxima tela:
          <br />
          <br />
          .Pedido/Receita médica válida (válida por 6 meses) <br />
          .Documento de identidade (RG ou CNH) <br /> .Comprovante de residência <br />
          .Formulário ANVISA
          <br />
          <br />
          <StyledCheckbox onChange={e => setConfirmDialogDocument(e.target.checked)}>
              Li e compreendi a necessidade de envio dos documentos.
          </StyledCheckbox>
        </TextDocument>
      </DocumentNotificationBox>
      <ContainerResumePay isMobile={isMobile}>
        <Address setIsAllAddress={setIsAllAddress} handleAddress={handleAddress} />
        <PaymentMethods
          loadingContainer={loadingContainer}
          finishOrderByPaymentMethodInterval={() => finishOrderByPaymentMethodInterval()}
          clearIntervalOutside={() => clearIntervalOutside()}
          finishOrderByPaymentMethod={finishOrderByPaymentMethod}
          error={error}
          isValidateSubmit={isValidateSubmit}
        />
      </ContainerResumePay>
      {isValidateSubmit && (
        <Banner title="Para Finalizar a compra por favor preencha os seguintes campos" variant="negative">
          <div>
            {!isAllAddress && <p>Dados de entrega</p>}
            {!confirmDialogDocument && (
              <p>Por favor, confirme a leitura das informações sobre a documentação necessária</p>
            )}
          </div>
        </Banner>
      )}
      <ButtonContainer>
        <BackCart onClick={backToCart}>voltar para o carrinho</BackCart>
      </ButtonContainer>
      {/* {!patientIsUser && (
        <Modal isOpen={!patientIsUser} setOpenModal={handlePatientIsUser} title="AVISO!">
          <ContainerModal>
            <p>Atenção!</p>
            <AiFillInfoCircle size={50} />
            <span>Parece que você não é o titular da receita, por favor, suba os documentos do PACIENTE</span>
            <button className="action" onClick={handlePatientIsUser}>
              CONTINUAR
            </button>
          </ContainerModal>
        </Modal>
      )} */}
    </Container>
  )
}

export default NewCartDocuments
