import React, { useEffect, useState } from 'react';
import { Modal, Button, Skeleton, InputNumber } from 'antd';
import api from 'services/api';
import * as S from './styles';
import PromotionalPrice from 'new-components/MarketPlace/PromotionalPrice';
import { toast } from 'react-toastify';
import { usePrepareCart } from '@cannect/hooks/usePrepareCart';
import { useCannectAssistant } from '@cannect/hooks/useCannectAssistant';
import CardQuantity from '@cannect/components/CardQuantity';

interface ProductFlavor {
  balance: number;
  id: number;
  name: string;
  quantity: number;
  selected: boolean;
  status: string;
  real_price: number;
  promotion: {
    promotional_price?: number;
    regular_price?: number;
    discount_value?: number;
    discount_type?: string;
  };
}

interface SelectFlavorModalProps {
  open: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  defaultSelected: {
    id?: number;
    main_product_id?: number;
    image?: string;
    name?: string;
    real_price?: string;
    quantity?: number;
  };
  isAssistant: boolean;
  onSave: (selectedProducts: ProductFlavor[], mainProductId?: number) => void;
  loadingOnSave: boolean;
  isAuthenticated: boolean;
  imageSelector: (isAuthenticated: boolean, defaultSelected: { image?: string; name?: string }) => string;
}

const getUnitPriceHeader = () => {
  if (window.innerWidth <= 768) {
    return "Valor unit.";
  }
  return "Valor unitário";
};

const getQuantityHeader = () => {
  if (window.innerWidth <= 768) {
    return "Qnt.";
  }
  return "Quantidade";
};

export function SelectFlavorModal({
  open,
  handleOk,
  handleCancel,
  defaultSelected,
  onSave,
  loadingOnSave,
  isAssistant,
  isAuthenticated,
  imageSelector
}: SelectFlavorModalProps) {
  const [loading, setLoading] = useState(false);
  const [productFlavorList, setProductFlavorList] = useState<ProductFlavor[]>([]);
  const [initialProductFlavorList, setInitialProductFlavorList] = useState<ProductFlavor[]>([]);
  const [maxBalance, setMaxBalance] = useState(0);
  const [_, setIsMaxUnique] = useState(false);
  const [idReady, setIdReady] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [unitPriceHeader, setUnitPriceHeader] = useState(getUnitPriceHeader());
  const [quantityHeader, setQuantityHeader] = useState(getQuantityHeader());

  const { removeCart, AddToCartByFlavor, incrementOrDecrementCart } = usePrepareCart();
  const { addProductToPrescription} = useCannectAssistant()

  useEffect(() => {
    const handleResize = () => {
      setUnitPriceHeader(getUnitPriceHeader());
      setQuantityHeader(getQuantityHeader());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (defaultSelected?.id) {
      setIdReady(true);
    } else {
      setIdReady(false);
    }
  }, [defaultSelected?.id]);

  useEffect(() => {
    const fetchProductFlavors = async () => {
      if (open && idReady) {
        setLoading(true);
        try {
          const { data } = await api.get(`/get_product_flavors/${defaultSelected.main_product_id || defaultSelected.id}`);
          if (data && data.productFlavors && data.productFlavors.length > 0) {
            processFlavors(data.productFlavors);
          } else {
            console.error('No flavors found in the response');
            toast.error('Nenhum sabor encontrado. Por favor, tente novamente.');
          }
        } catch (error) {
          console.error('Error fetching flavors:', error);
          toast.error('Erro ao buscar os sabores. Por favor, tente novamente.');
        }
        setLoading(false);
      }
    };

    fetchProductFlavors();
  }, [open, idReady]);



  const processFlavors = (products: any) => {
    const flavorMap = new Map();
    products.forEach((product: any) => {
      if (product.flavors) {
        product.flavors.forEach((flavor: any) => {
          if (flavor && flavor.id && !flavorMap.has(flavor.id)) {
            flavorMap.set(flavor.id, {
              id: flavor.id,
              name: product.translated_name,
              flavor: flavor.flavor,
              image: defaultSelected.image,
              quantity: 0,
              balance: flavor.balance,
              selected: false,
              real_price: defaultSelected.real_price,
              promotion: product.promotion || {},
              status: flavor.status
            });
          }
        });
      }
    });
    const flavorArray = Array.from(flavorMap.values());
    updateFlavorQuantities(flavorArray);
  };

  const updateFlavorQuantities = (flavors: any) => {
    const storedProducts = !isAssistant
      ? JSON.parse(localStorage.getItem('selectedProducts') || '[]')
      : JSON.parse(localStorage.getItem('selectedProductsMedicine') || '[]');
    const updatedFlavors = flavors.map((flavor: any) => {
      const storedFlavor = storedProducts.find((sp: any) => sp.product && sp.product.id === flavor.id);
      return {
        ...flavor,
        quantity: storedFlavor ? storedFlavor.quantity : 0,
        selected: storedFlavor && storedFlavor.quantity > 0
      };
    });
    setProductFlavorList(updatedFlavors);
    setInitialProductFlavorList(updatedFlavors);
  };

  const handleQuantityChange = (flavorId: number, quantity: number) => {
    setProductFlavorList(prevList =>
      prevList.map(flavor => (flavor.id === flavorId ? { ...flavor, quantity, selected: quantity > 0 } : flavor))
    );
  };

  useEffect(() => {
    const balancesGreaterThanZero = productFlavorList
      .map((flavor: any) => flavor.balance)
      .filter(balance => balance > 0);
    const newMaxBalance = balancesGreaterThanZero.length > 0 ? Math.max(...balancesGreaterThanZero) : 0;

    const newIsMaxUnique = productFlavorList.filter((flavor: any) => flavor.balance === newMaxBalance).length === 1;
    setMaxBalance(newMaxBalance);
    setIsMaxUnique(newIsMaxUnique && newMaxBalance > 0);
  }, [productFlavorList]);

  const handleSave = async () => {
    setLoadingSave(true);
    const selectedProducts = productFlavorList.filter(flavor => flavor.selected);
    const storageKey = isAssistant ? 'selectedProductsMedicine' : 'selectedProducts';
    const storedProducts = JSON.parse(localStorage.getItem(storageKey) || '[]');

    // Map selected products to the correct structure
    const mappedSelectedProducts = selectedProducts.map((product: any) => ({
      id: product.id,
      quantity: product.quantity,
      image: product.image,
      price: product.real_price,
      name: product.name,
    }));

    // Update stored products based on selected products
    const updatedStoredProducts = storedProducts.map((storedProduct: any) => {
      const selectedProduct = mappedSelectedProducts.find(sp => sp.id === storedProduct.product_id);
      if (selectedProduct) {
        return { ...storedProduct, quantity: selectedProduct.quantity };
      }
      return storedProduct;
    });
    // Add new products to the storage
    const newStoredProducts = mappedSelectedProducts.filter(selectedProduct =>
      !updatedStoredProducts.some(sp => sp.product_id === selectedProduct.id)
    );

    const finalStoredProducts = [...updatedStoredProducts, ...newStoredProducts];
    // Filter products with zero quantity for removal
    const idsToRemove = initialProductFlavorList
      .filter(initialFlavor => {
        const currentFlavor = productFlavorList.find(flavor => flavor.id === initialFlavor.id);
        return initialFlavor.quantity > 0 && (!currentFlavor || currentFlavor.quantity === 0);
      })
      .map(flavor => flavor.id);

    localStorage.setItem(storageKey, JSON.stringify(finalStoredProducts));
    const idsToAdd = mappedSelectedProducts
      .filter(selectedProduct =>
        initialProductFlavorList.some(initialFlavor => initialFlavor.id === selectedProduct.id && initialFlavor.quantity >= 0)
      )
      .map(flavor => ({
        id: flavor.id,
        quantity: flavor.quantity,
        image: flavor.image,
        price: flavor.price,
        name: flavor.name,
      }));

    try {
      let addedCount = 0;
      let removedCount = 0;

      if (idsToAdd.length > 0 && !isAssistant) {
        await AddToCartByFlavor(idsToAdd);
      }

      if (idsToAdd.length > 0 && isAssistant) {
        idsToAdd.forEach(product => {
          addProductToPrescription(product)
        });
        addedCount = idsToAdd.length;
      }

      if (idsToRemove.length > 0) {
        await removeCart(idsToRemove);
        removedCount = idsToRemove.length;
      }

      if (isAssistant) {
        if (addedCount > 0) {
          toast.success(`${addedCount} produto(s) adicionado(s) à receita`, {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
          });
        }
        if (removedCount > 0) {
          toast.error(`${removedCount} produto(s) removido(s) da receita`, {
            position: 'bottom-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined
          });
        }
      } else {
        toast.success('Carrinho atualizado', {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined
        });
      }

    } catch (error) {
      console.error('Erro ao atualizar carrinho:', error);
      toast.error('Erro ao atualizar carrinho');
    } finally {
      setLoadingSave(false);
      handleOk();
    }
  };

  const handleClose = () => {
    handleCancel();
  };

  const isSaveDisabled = productFlavorList.every(flavor => flavor.quantity === 0);
  return (
    <Modal
      open={open}
      onOk={handleSave}
      onCancel={handleClose}
      centered
      footer={null}
      confirmLoading={loading}
      zIndex={1005}
      width={open ? '90%' : 850} // Adjust width for mobile view
      style={{ maxWidth: 850 }} // Ensure max width is 850px
    >
      <S.Wrapper>
        <S.Title>Selecione sabores diferentes para cada unidade de produto:</S.Title>
        <S.ProductTitle>Produto</S.ProductTitle>
        <S.ProductDescriptionWrapper>
          <S.ProductImageBox>
            <S.ProductImage
              src={imageSelector(isAuthenticated, defaultSelected)}
              alt={`Imagem do produto ${defaultSelected.name}`}
            />
          </S.ProductImageBox>
          <S.DescriptionBox>
            <S.ProductName>{defaultSelected.name}</S.ProductName>
          </S.DescriptionBox>
        </S.ProductDescriptionWrapper>

        <S.ProductVariantWrapper>
          <S.Table>
            <S.TableHead>
              <S.TableRow>
                <S.TableHeadCell>Sabor</S.TableHeadCell>
                <S.TableHeadCell />
                <S.TableHeadCell className="unit_price_header">{quantityHeader}</S.TableHeadCell>
                <S.TableHeadCell className="unit_quantity unit_price_header">{unitPriceHeader}</S.TableHeadCell>
              </S.TableRow>
            </S.TableHead>
            <S.TableBody>
              {loading
                ? Array.from({ length: 3 }).map((_, index) => (
                    <S.TableRow key={index}>
                      <S.TableCell>
                        <Skeleton.Button active />
                      </S.TableCell>
                      <S.TableCell>
                        <Skeleton.Input active />
                      </S.TableCell>
                      <S.TableCell>
                        <Skeleton.Input active />
                      </S.TableCell>
                      <S.TableCell>
                        <Skeleton.Input active />
                      </S.TableCell>
                    </S.TableRow>
                  ))
                :productFlavorList.map(flavor => (
                  flavor.status === 'A' && (
                    <S.TableRow key={flavor.id}>
                      <S.TableCell>
                        <S.FlavorBox>
                          <S.FlavorTag selected={flavor.selected}>{flavor.flavor}</S.FlavorTag>
                        </S.FlavorBox>
                      </S.TableCell>
                      <S.TableCell>
                        {flavor.balance === maxBalance && maxBalance > 0 && (
                          <S.RecommendedTag style={{ color: 'green' }}>Recomendado</S.RecommendedTag>
                        )}
                        {flavor.balance <= 0 && (
                          <S.RecommendedTag style={{ color: '#8F8F8F' }}>
                            Possível maior tempo de entrega
                          </S.RecommendedTag>
                        )}
                      </S.TableCell>
                      <S.TableCell>
                        <S.QuantityBox className="input_quantity">
                        <S.QuantityBox className="input_quantity">
                          <CardQuantity
                             defaultQuantity={flavor.id === defaultSelected.id ? defaultSelected.quantity : flavor.quantity}
                             onCardQuantity={defaultQuantity => handleQuantityChange( flavor.id, defaultQuantity )}
                             removeCart={() => removeCart(flavor.id)}
                           />
                        </S.QuantityBox>
                        </S.QuantityBox>
                      </S.TableCell>
                      <S.TableCell>
                        <S.QuantityBox className="unit_quantity">
                          <PromotionalPrice
                            new_price={flavor.promotion.promotional_price || flavor.real_price}
                            old_price={flavor.promotion.regular_price}
                            discount={flavor.promotion.discount_value}
                            discount_type={flavor.promotion.discount_type}
                          />
                        </S.QuantityBox>
                      </S.TableCell>
                    </S.TableRow>
                  )))}
            </S.TableBody>
          </S.Table>
        </S.ProductVariantWrapper>

        <S.ActionsContent>
          <Button
            style={{
              width: '200px',
              height: '40px',
              border: '1px solid #21976E',
              color: '#21976E',
              backgroundColor: 'white'
            }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{ width: '200px', height: '40px', backgroundColor: '#21976E', color: 'white' }}
            onClick={handleSave}
            disabled={isSaveDisabled}
            loading={loadingSave}
          >
            Salvar
          </Button>
        </S.ActionsContent>
      </S.Wrapper>
    </Modal>
  );
}
