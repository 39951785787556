/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from 'design-cannect'
import DeactivatableButton from 'pages/CannectCare/DeactivatableButton'
import { useCannectCare } from 'hooks/useCannectCare'
import { useState } from 'react'
import Disease from '../../Disease'
import OtherDisease from '../../OtherDisease'
import * as Style from '../styles'
import CantUncheckWarning from './CantUncheckWarning'

export default function SymptomsSelection() {
  const {
    symptoms,
    toggleSymptomSelection,
    otherSymptoms,
    addOtherSysmptom,
    changeOtherSymptomName,
    setPath,
    setStep
  } = useCannectCare()
  const [showCantUncheckWarning, setShowCantUncheckWarning] = useState(false)

  function handleGoBackClick() {
    setPath('')
    setStep(0)
  }

  return (
    <>
      <h3>
        Como seus sintomas estão <strong>nos últimos três dias?</strong>
      </h3>
      {showCantUncheckWarning && <CantUncheckWarning />}

      <Style.DiseasesContainer>
        {symptoms.map(symptom => (
          <Disease
            img={symptom.selected ? symptom.imgChecked : symptom.imgUnchecked}
            label={symptom.label}
            selected={symptom.selected}
            onClick={
              !symptom.cameFromApi ? () => toggleSymptomSelection(symptom.id) : () => setShowCantUncheckWarning(true)
            }
          />
        ))}
        {otherSymptoms.map(symptom => (
          <OtherDisease
            onChange={value => {
              changeOtherSymptomName(symptom.id, value)
            }}
            value={symptom.label}
          />
        ))}
        {/* <DeactivatableButton
          onClick={() => addOtherSysmptom()}
          className="add-other-symptom-button"
          disabled={
            otherSymptoms[otherSymptoms.length - 1] && otherSymptoms[otherSymptoms.length - 1].label.length === 0
          }
        >
          Adicionar outro sintoma
        </DeactivatableButton> */}
      </Style.DiseasesContainer>
      <Style.ActionsButtons>
        <Button outlined onClick={() => handleGoBackClick()}>
          Voltar
        </Button>
        <Button onClick={() => setStep(1)}>Próximo</Button>
      </Style.ActionsButtons>
    </>
  )
}
