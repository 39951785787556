import { z } from 'zod'

export default {
  nonEmptyStringSchema: z.string({ required_error: 'Campo obrigatório' }).min(1, 'Campo obrigatório'),
  requiredField: 'Campo obrigatório',
  invalidEmail: 'Digite um e-mail válido',
  invalidCep: 'Digite um CEP válido',
  invalidPhone: 'Digite um telefone válido',
  invalidCpf: 'Digite um CPF válido',
  invalidName: 'Digite um nome válido'
}
