/* eslint-disable import/no-duplicates */
import { GiStethoscope } from 'react-icons/gi'
import { temporaryTheme } from 'styles/theme'
import { Tooltip } from 'antd'
import { formatToBRL } from 'utils/formatters'
import { NameRow, TruncatedText } from '../../styles'
import { IoCalendarClearOutline } from 'react-icons/io5'
import * as Styled from '../styles'
import { useMemo } from 'react'

import { addMinutes, format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useScheduling } from 'hooks/useScheduling'
import { useFormContext } from 'react-hook-form'

function AppointmentResume() {
  const { appointmentFromB2C, selectedPaymentMethod } = useScheduling()

  const { watch } = useFormContext()

  const patientWatch = watch('patient')
  const selectedPrescriber = watch('prescriber')

  const currentPrescriber = useMemo(() => {
    if (!appointmentFromB2C) return selectedPrescriber

    return appointmentFromB2C?.prescriber
  }, [appointmentFromB2C, selectedPrescriber])

  const currentPatientName = useMemo(() => {
    if (!appointmentFromB2C) return patientWatch.name

    return appointmentFromB2C?.patient?.person?.name
  }, [appointmentFromB2C, patientWatch])

  const expertiseText = (currentPrescriber?.prescriber_expertises || []).map((e: any) => e.name).join(' • ')

  const startDate = parseISO(`${appointmentFromB2C?.date}T${appointmentFromB2C?.hour}`)

  const endDate = addMinutes(startDate, appointmentFromB2C?.duration || 0)

  const formattedStartDate = format(startDate, 'eeee, d MMMM · p', { locale: ptBR })
  const formattedEndDate = format(endDate, 'p', { locale: ptBR })
  const formattedDateTime = `${formattedStartDate} até ${formattedEndDate}`

  const appointmentPrice = useMemo(() => {
    if (selectedPaymentMethod === 'credit_card') {
      return appointmentFromB2C?.credit_amount_with_discount
    }

    return appointmentFromB2C?.amount_with_discount
  }, [selectedPaymentMethod, appointmentFromB2C])

  return (
    <Styled.Box>
      <Styled.BoxTitle>Resumo</Styled.BoxTitle>
      <NameRow>
        <GiStethoscope />
        <div>
          <Styled.TextDetail color={temporaryTheme.colors.link} className="professional_name">
            <span>{currentPrescriber?.professional_name}</span>
          </Styled.TextDetail>

          <Tooltip overlayInnerStyle={{ fontFamily: 'Poppins' }} placement="bottomLeft" title={expertiseText}>
            <Styled.TextDetail fontSize="16px" style={{ width: '100%' }}>
              <TruncatedText fontSize="16px" style={{ width: '100%' }}>
                {expertiseText}
              </TruncatedText>
            </Styled.TextDetail>
          </Tooltip>
        </div>
      </NameRow>
      <Styled.WhiteBox>
        <Styled.BasicContent>
          <Styled.TextDetail>
              <span>
                <IoCalendarClearOutline />
                {formattedDateTime}
              </span>
            </Styled.TextDetail>
            <Styled.TextDetail>
              <p>Paciente:</p>
              <span>{currentPatientName || '-'}</span>
            </Styled.TextDetail>
            <Styled.TextDetail>
              <p>Consulta Online - </p>
              <span color={temporaryTheme.colors.link}>Cannect</span>
              {/* <span style={{ fontWeight: '600' }}>{appointmentPrice && formatToBRL(appointmentPrice)}</span> */}
            </Styled.TextDetail>
        </Styled.BasicContent>
        {appointmentFromB2C?.event_link && (
          <Styled.Content>
            <Styled.TextDetail>
              <p>Informações de participação:</p>
            </Styled.TextDetail>
            <Styled.TextDetail>
              <span>Link da video chamada:</span>
            </Styled.TextDetail>
            <Styled.TextDetail>
              <span color={temporaryTheme.colors.link}>{appointmentFromB2C.event_link}</span>
            </Styled.TextDetail>
          </Styled.Content>
        )}
      </Styled.WhiteBox>
      <Styled.TextDetail color="#777777">
        <p>Você receberá essas informações também pelo e-mail cadastrado.</p>
      </Styled.TextDetail>
    </Styled.Box>
  )
}

export default AppointmentResume
