import * as Styles from './styles'
import Heading from 'new-components/Heading'
import TextDanger from 'new-components/partnesComponents/TextDanger'
import { chatIcon, circleIcon } from 'assets/svg'

import {
  cannectCuida01,
  cannectCuida02,
  cannectCuidaSandroRicardo,
  cannectCuidaDanielFixa,
  cannectCuidaVitorAzevedo,
  passePelaTriagem
} from 'assets/img'

import {
  cuidaPatientBenefits,
  cuidaProfessionalBenefits,
  patientReports,
  professionalReports
} from './mock-cannect-cuida'
import ReactPlayer from 'react-player'

export default function CannectCuida() {
  return (
    <Styles.Wrapper>
      <Styles.SectionBackground>
        <Styles.Section>
          <Styles.SectionHorizontal>
            <Styles.SectionHorizontalContent>
              <Heading elementType="h1" fontSize="45px" defaultColor="darkGrey">
                Cannect <span>Cuida</span>
              </Heading>

              <TextDanger
                fontWeight={400}
                fontSize="17px"
                color="#3D3D3D"
                text="Um time de <strong>enfermeiros especialistas dedicados a acompanhar o paciente</strong> durante o tratamento com a cannabis
            medicinal, oferecendo <strong>assistência inteiramente gratuita</strong> e em <strong>total conjunto e sincronia com o médico ou
            cirurgião-dentista prescritor</strong>."
              />

              <Styles.SectionHorizontalContentButtons>
                <Styles.SectionButtonPrimary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511916398162', '_blank')
                  }}
                >
                  Para pacientes
                </Styles.SectionButtonPrimary>
                <Styles.SectionContentButtonSecondary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511956283030', '_blank')
                  }}
                >
                  Para Profissionais da saúde
                </Styles.SectionContentButtonSecondary>
              </Styles.SectionHorizontalContentButtons>
            </Styles.SectionHorizontalContent>

            <Styles.SectionHorizontalContent className="cannect-cuida-video">
              <ReactPlayer
                url="https://s3.amazonaws.com/cannect.portal.upload/videos/cannect_cuida_v1.mp4"
                width="100%"
                height="auto"
                controls
              />
            </Styles.SectionHorizontalContent>
          </Styles.SectionHorizontal>

          <Styles.SectionHorizontal>
            <Styles.SectionHorizontalContent>
              <img src={cannectCuida01} alt="Para pacientes" />

              <Heading
                className="font-family-poppins"
                elementType="h3"
                fontSize="30px"
                fontWeight={500}
                defaultColor="darkGrey"
                verticalSpace="small"
              >
                Para
                <span>
                  <strong> pacientes</strong>
                </span>
              </Heading>

              <TextDanger
                justify="justify"
                fontWeight={400}
                fontSize="17px"
                color="#3D3D3D"
                text="<strong>Cuidado coordenado. Amparo. Segurança. Comodidade. Essas são algumas palavras-chave que definem o Cannect Cuida</strong>. Isso porque acreditamos que a sua jornada em direção a uma vida melhor não termina após a prescrição. <strong>Alinhados com as recomendações do prescritor, nossa equipe garante apoio e acolhimento ao paciente e à família</strong>, tanto em caso de dúvidas quanto para oferecer suporte em situações de eventuais efeitos colaterais."
              />
            </Styles.SectionHorizontalContent>

            <Styles.SectionHorizontalContent>
              <img src={cannectCuida02} alt="Para profissionais da saúde" />

              <Heading
                className="font-family-poppins"
                elementType="h3"
                fontSize="30px"
                fontWeight={500}
                defaultColor="darkGrey"
                verticalSpace="small"
              >
                Para
                <span>
                  <strong> profissionais da saúde</strong>
                </span>
              </Heading>

              <TextDanger
                justify="justify"
                fontSize="17px"
                fontWeight={400}
                color="#3D3D3D"
                text="Em total <strong>harmonia e sincronia</strong> com o médico ou cirurgião-dentista prescritor, o Cannect Cuida oferece aos nossos profissionais parceiros monitoramento, <strong>suporte e reforço da adesão terapêutica de seus pacientes, buscando garantir as orientações feitas na consulta sem interferir na comunicação entre o paciente e o prescritor</strong>."
              />
            </Styles.SectionHorizontalContent>
          </Styles.SectionHorizontal>

          <Styles.SectionBenefits>
            <Heading elementType="h3" fontSize="45px" verticalSpace="small" defaultColor="darkGrey">
              Vantagens <span>para você</span>
            </Heading>

            <Styles.SectionBenefitsCards>
              <Styles.SectionContentCard>
                <Heading
                  verticalSpace="small"
                  elementType="h4"
                  fontWeight={800}
                  fontSize="22px"
                  defaultColor="darkGrey"
                >
                  <span>Paciente</span>
                </Heading>

                <Styles.SectionBenefitsCardList>
                  {cuidaPatientBenefits &&
                    cuidaPatientBenefits.map(benefit => {
                      return (
                        <div className="benefits-list-text">
                          <div className="benefits-list-icon">
                            <img src={benefit.image} alt={benefit.name} />
                          </div>

                          <span>{benefit.name}</span>
                        </div>
                      )
                    })}
                </Styles.SectionBenefitsCardList>

                <Styles.SectionButtonPrimary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511916398162', '_blank')
                  }}
                >
                  Fale com nosso time!
                </Styles.SectionButtonPrimary>
              </Styles.SectionContentCard>

              <Styles.SectionContentCard>
                <Heading
                  verticalSpace="small"
                  elementType="h4"
                  fontWeight={700}
                  fontSize="22px"
                  defaultColor="darkGrey"
                >
                  <span>Profissional da Saúde</span>
                </Heading>
                <Styles.SectionBenefitsCardList>
                  {cuidaProfessionalBenefits &&
                    cuidaProfessionalBenefits.map(benefit => {
                      return (
                        <div className="benefits-list-text">
                          <div className="benefits-list-icon">
                            <img src={benefit.image} alt={benefit.name} />
                          </div>

                          <span>{benefit.name}</span>
                        </div>
                      )
                    })}
                </Styles.SectionBenefitsCardList>

                <Styles.SectionButtonPrimary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511956283030', '_blank')
                  }}
                >
                  Fale com nosso time!
                </Styles.SectionButtonPrimary>
              </Styles.SectionContentCard>
            </Styles.SectionBenefitsCards>
          </Styles.SectionBenefits>
        </Styles.Section>
      </Styles.SectionBackground>

      <Styles.SectionQuestion>
        <Styles.SectionQuestionColumns>
          <img src={passePelaTriagem} alt="Duvidas? Passe pela nossa Triagem" />
        </Styles.SectionQuestionColumns>
        <Styles.SectionQuestionColumns>
          <Heading verticalSpace="small" elementType="h5" fontWeight={700} fontSize="22px" defaultColor="white">
            Dúvidas?
          </Heading>

          <Heading verticalSpace="small" elementType="h6" fontWeight={700} fontSize="22px" defaultColor="white">
            Passe pela nossa <span>Triagem</span>
          </Heading>

          <p>Nesse espaço você pode tirar todas as suas dúvidas com a gente</p>
          <p>O processo é sem custos e não pede outras informações, é apenas para tirar as sua duvidas mesmo!</p>

          <Styles.SectionButtonPrimary
            onClick={() => {
              window.open('https://atendimento.cannect.life/triagem', '_blank')
            }}
          >
            <img src={chatIcon} alt="Iniciar minha triagem" />
            Iniciar minha triagem
          </Styles.SectionButtonPrimary>
        </Styles.SectionQuestionColumns>
      </Styles.SectionQuestion>

      <Styles.SectionBackground>
        <Styles.Section>
          <Styles.SectionCollaborators>
            <Heading elementType="h3" fontWeight={800} fontSize="55px">
              Conheça <span>nossa/sua equipe!</span>
            </Heading>
            <Styles.SectionCollaboratorsColumns>
              
            <Styles.SectionHorizontalContent className="section-column">
                <img src={cannectCuidaSandroRicardo} alt="Sandro Ricardo Batista - Head do Cannect Cuida" />

                <div className="section-content-head">
                  <h5>Sandro Ricardo Batista</h5>
                  <span>Head do Cannect Cuida</span>
                </div>

                <TextDanger
                  justify="justify"
                  fontFamily="Poppins"
                  fontWeight={500}
                  fontSize="14px"
                  color="#3D3D3D"
                  text="Enfermeiro com MBA em gestão hospitalar, pós-graduado em gerenciamento de projetos pela Fundação Getúlio Vargas (FGV) e em Gestão de custos pela Universidade de Mogi das Cruzes, é especialista em gestão de projetos, fluxos e processos hospitalares, com experiência em práticas assistenciais, linhas de cuidado, protocolos clínicos gerenciados, qualidade e mais. Sua experiência na coordenação de processos de certificação de programas clínicos internacionais e implementação de linhas de cuidado lhe deu vivência operacional e liderança  nas áreas assistenciais. ”"
                />
              </Styles.SectionHorizontalContent>

              <Styles.SectionHorizontalContent className="section-column">
                <img src={cannectCuidaVitorAzevedo} alt="Vitor Azevedo - Enfermeiro do Cannect Cuida" />

                <div className="section-content-head">
                  <h5>Vitor Azevedo</h5>
                  <span>Enfermeiro do Cannect Cuida</span>
                </div>

                <TextDanger
                  justify="justify"
                  fontFamily="Poppins"
                  fontWeight={500}
                  fontSize="14px"
                  color="#3D3D3D"
                  text="Enfermeiro graduado pela Universidade Cidade de São Paulo, com especialização em Saúde da Família pelo Centro Universitário Celso Lisboa. Com experiência sólida na prestação de cuidados de saúde, busco aplicar meu conhecimento e habilidades para oferecer suporte e promover a melhora na qualidade de vida das pessoas. “Diante dos desafios, lembro-me sempre: 'Recalcular rota: adaptar, superar, avançar' é o caminho para o sucesso.”"
                />
              </Styles.SectionHorizontalContent>

              <Styles.SectionHorizontalContent className="section-column">
                <img src={cannectCuidaDanielFixa} alt="Daniel Fixa - Enfermeiro do Cannect Cuida" />

                <div className="section-content-head">
                  <h5>Daniel Fixa</h5>
                  <span>Enfermeiro do Cannect Cuida</span>
                </div>

                <TextDanger
                  justify="justify"
                  fontFamily="Poppins"
                  fontWeight={500}
                  fontSize="14px"
                  color="#3D3D3D"
                  text="Comprometido com a excelência na saúde, sou Daniel Alves Fixa, graduado pela UNICID e pós-graduado em Urgência e Emergência, assim como em Enfermagem do Trabalho pela Universidade Celso Lisboa. Minha jornada é guiada pela dedicação ao cuidado e constante busca pelo aprimoramento. Vamos construir um futuro mais saudável juntos!"
                />
              </Styles.SectionHorizontalContent>
            </Styles.SectionCollaboratorsColumns>
          </Styles.SectionCollaborators>

          <Styles.SectionHorizontal>
            <Styles.SectionContentCard>
              <Heading verticalSpace="small" elementType="h5" fontWeight={900} fontSize="30px" defaultColor="darkGrey">
                Relatos de nossos <span>pacientes!</span>
              </Heading>

              <Styles.SectionReportsCards>
                {patientReports &&
                  patientReports.map(patient => {
                    return (
                      <Styles.SectionReportsCard>
                        <Styles.SectionReportsCardHead>
                          <img src={patient.image} alt={`${patient.name} - ${patient.responsibility}`} />
                        </Styles.SectionReportsCardHead>
                        <Styles.SectionReportsCardBody>
                          <h4>{patient.name}</h4>
                          <span className="responsibility">{patient.responsibility}</span>
                          <TextDanger
                            justify="justify"
                            fontWeight={400}
                            fontSize="13px"
                            color="#3D3D3D"
                            text={patient.report}
                          />
                        </Styles.SectionReportsCardBody>
                      </Styles.SectionReportsCard>
                    )
                  })}
              </Styles.SectionReportsCards>

              <Styles.SectionReportsFooter className="patient-footer">
                <p>Quer ter a sua historia?</p>

                <Styles.SectionButtonPrimary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511916398162', '_blank')
                  }}
                >
                  Agendar consulta
                </Styles.SectionButtonPrimary>
              </Styles.SectionReportsFooter>
            </Styles.SectionContentCard>
            <Styles.SectionContentCard>
              <Heading verticalSpace="small" elementType="h5" fontWeight={900} fontSize="30px" defaultColor="darkGrey">
                Relatos de <span>Profissionais da Saúde!</span>
              </Heading>

              <Styles.SectionReportsCards>
                {professionalReports &&
                  professionalReports.map(professional => {
                    return (
                      <Styles.SectionReportsCard>
                        <Styles.SectionReportsCardHead>
                          <img src={professional.image} alt={`${professional.name} - ${professional.responsibility}`} />
                        </Styles.SectionReportsCardHead>
                        <Styles.SectionReportsCardBody>
                          <h4>{professional.name}</h4>
                          <span className="responsibility">{professional.responsibility}</span>
                          <TextDanger
                            justify="justify"
                            fontWeight={400}
                            fontSize="13px"
                            color="#3D3D3D"
                            text={professional.report}
                          />
                        </Styles.SectionReportsCardBody>
                      </Styles.SectionReportsCard>
                    )
                  })}
              </Styles.SectionReportsCards>

              <Styles.SectionReportsFooter className="professional-footer">
                <p>Quer ter a sua historia?</p>

                <Styles.SectionButtonPrimary
                  onClick={() => {
                    window.open('https://api.whatsapp.com/send?phone=5511916398162', '_blank')
                  }}
                >
                  Agendar consulta
                </Styles.SectionButtonPrimary>
              </Styles.SectionReportsFooter>
            </Styles.SectionContentCard>
          </Styles.SectionHorizontal>
        </Styles.Section>
      </Styles.SectionBackground>
    </Styles.Wrapper>
  )
}
