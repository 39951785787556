import { recipeBox } from 'assets/svg'
import InputFile from 'components/InputFile'
import Text from 'components/Text'
import { getUserUuid } from 'hooks/useSessionUser'
import UploadedFileInfo from 'pages/BlankRecipe/UploadedFileInfo'
import { useState } from 'react'
import api from 'services/api'

import { toast } from 'react-toastify'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'

import { useHistory } from 'react-router'
import { useTokenApi } from 'hooks/useTokenApi'
import * as Style from './styles'

export default function SendApproval() {
  const { deleteTokenApi } = useTokenApi()
  const history = useHistory()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const uuid = getUserUuid()
  const orderId = localStorage.getItem('@CANNECT:ORDERID')
  const basePathDocuments = `/order/document`

  const [getAnvisaAuth, setGetAnvisaAuth] = useState<{ name: string }[]>([])

  const [documents, setDocuments] = useState<{ documents: { id: number | null }[] }>({
    documents: []
  })

  const handleAnvisaAuthorization = async (files: Array<Blob>) => {
    const data = files as unknown as { name: string }[]
    setGetAnvisaAuth(data)

    const formData = new FormData()
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      formData.append('file', file)
    }
    try {
      const { data } = await api.post(`${basePathDocuments}/${orderId}?type=ANVISA`, formData)

      const holdData = documents

      if (data.id) {
        holdData?.documents.push({
          id: data.id
        })

        setDocuments(holdData)
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    }
  }

  const handleRemove = (file: string) => {
    const fixFiles = getAnvisaAuth?.filter(e => e.name !== file)

    setGetAnvisaAuth(fixFiles)
  }

  const alertDocument = async () => {
    toast.error('Insira seus documentos para continuar')
  }

  const handleSubmit = async () => {
    if (getAnvisaAuth.length === 0) {
      alertDocument()
      return
    }

    try {
      await api.put(`/order/${orderId}`, documents)

      history.push('/receita-branca/confirmacao-pedido')
    } catch (e: any) {
      if (e?.response?.status === 401) {
        deleteTokenApi()
      }
    }
  }

  const handleSendLater = async () => {
    history.push('/receita-branca/confirmacao-pedido')
  }

  return (
    <Style.Container>
      {/* <Style.Header isMobile={isMobile}>
        <Text type="lg">Caso você não tenha em mãos a autorização, você poderá enviá-la depois em seu </Text>
        <Style.RefButton onClick={() => console.log('button')}>histórico de pedidos</Style.RefButton>
      </Style.Header> */}
      <Text type="lg" bold color="tertiary">
        Envie abaixo sua autorização para importação da Anvisa
      </Text>
      &nbsp;
      <InputFile handleUpload={handleAnvisaAuthorization} updateComponent={getAnvisaAuth} type="ANVISA" />
      &nbsp;
      {getAnvisaAuth.length === 0 ? (
        <>
          <img src={recipeBox} alt="" />
          &nbsp;
          <Text type="lg" bold>
            Envie uma receita acima
          </Text>
        </>
      ) : (
        <UploadedFileInfo files={getAnvisaAuth} removeFile={handleRemove} />
      )}
      <Style.SubmitButtonWrapper>
        <Style.SubmitAfterButton disabled={getAnvisaAuth.length > 0} onClick={handleSendLater}>
          Enviar os documentos depois
        </Style.SubmitAfterButton>
        <Style.SubmitButton onClick={handleSubmit}>Continuar</Style.SubmitButton>
      </Style.SubmitButtonWrapper>
      <Style.TitleWrapper>
        <Text type="lg" bold color="tertiary">
          Para importar produtos cannábicos é necessário uma autorização pela Anvisa, comprovando o uso para fins
          medicinais.
        </Text>
      </Style.TitleWrapper>
      <Style.HelpWrapper isMobile={isMobile}>
        <Style.CardWrapper onClick={handleSendLater}>
          <Text type="lg" bold color="tertiary">
            Quero ajuda da Cannect
          </Text>
          <Text type="lg">A cannect apoiará você durante o processo, nosso atendimento entrará em contato.</Text>
        </Style.CardWrapper>
        <Style.LinkCardWrapper href="https://youtu.be/cA9OQbNffME" target="_blank">
          <Text type="lg" bold color="tertiary">
            Orientação de como pedir a autorização
          </Text>
          <Text type="lg">Veja um passo a passo de como pedir autorização da anvisa.</Text>
        </Style.LinkCardWrapper>
      </Style.HelpWrapper>
    </Style.Container>
  )
}
