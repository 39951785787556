import { IoIosCloseCircleOutline, IoMdClose } from 'react-icons/io'
import * as Styles from './styles'

interface Props {
  setOpenModal: any
  children: any
  isOpen?: boolean
}

export default function ModalHome({ isOpen, setOpenModal, children }: Props) {
  return (
    <Styles.DialogRoot open={isOpen} onOpenChange={setOpenModal}>
      <Styles.DialogPortal>
        <Styles.DialogOverlay />
        <Styles.DialogContent>
          <Styles.DialogClose asChild>
            <Styles.ButtonClose onClick={() => setOpenModal(false)}>
              <IoMdClose />
            </Styles.ButtonClose>
          </Styles.DialogClose>
          <Styles.ContentChildren>{children}</Styles.ContentChildren>
        </Styles.DialogContent>
      </Styles.DialogPortal>
    </Styles.DialogRoot>
  )
}
