import { theme } from 'design-cannect'
import styled from 'styled-components'
import { MOBILE_WIDTH } from 'utils/constants'

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: auto;

  ul {
    display: flex;
    list-style-type: none;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.5rem;
      height: 35px;
      width: 35px;
      border-radius: 100px;
      color: #bcbcbc;
      cursor: pointer;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: 'Inter', sans-serif;
        font-weight: 800;
        @media ${MOBILE_WIDTH} {
          font-size: 14px;
        }
      }
    }

    li.selected {
      background: #21976e;
      color: #fff;
    }

    li.disabled {
      display: none;
    }
    li.next,
    li.previous {
      display: flex;
      background-color: transparent;
      svg {
        font-size: 24px;
        color: #21976e;
        @media ${MOBILE_WIDTH} {
          font-size: 17px;
        }
      }
    }
  }
`
