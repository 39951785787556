// import { useEffect } from 'react'
import {
  endeavorLogo,
  paymentAmex,
  paymentBoleto,
  paymentElo,
  paymentHiper,
  paymentMaster,
  paymentPix,
  paymentVisa
} from 'assets/img'
import logo from '../../assets/img/footer-logo.png'
import facebook from '../../assets/img/home/logo-facebook.png'
import instagram from '../../assets/img/home/logo-instagram.png'
import twitter from '../../assets/img/home/logo-twitter.png'
import linkedin from '../../assets/img/home/logo-linkedin.png'
import reclame from '../../assets/img/home/image-reclame.png'
import { usePublicRender } from 'utils/renderActions'
import When from 'utils/when'
import * as Style from './styles'
import { useHistory } from 'react-router'
import { useEffect } from 'react'

function Footer() {
  const { isPublic, isLowerEqualMd } = usePublicRender()
  const history = useHistory()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://cannect13369.activehosted.com/f/embed.php?id=60'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Style.Container>
      <Style.Content>
        <div className="block max-w-96 md:hidden">
          <Style.Title>Esteja sempre informado sobre a #revoluçãoverde</Style.Title>
          <When expr={isLowerEqualMd}>
            <Style.FormContent className="_form_60" />
          </When>
        </div>

        <div className="ml-2 mr-10 mt-10 hidden md:block xl:mt-24">
          <Style.ImgWrapper>
            <Style.Img src={logo} alt="logo-cannect" />
          </Style.ImgWrapper>
        </div>

        <div className="container-links">
          <When expr={!isPublic}>
            <Style.Title className="mb-8">Acesso rápido:</Style.Title>
            <Style.QuickAccessContainer>
              <Style.QuickAccessContent>
                <Style.TextLink onClick={() => history.push('/sobre-nos')}>Sobre nós</Style.TextLink>
                <Style.TextLink onClick={() => history.push('/ecossistema-cannect')}>Cannabis medicinal</Style.TextLink>
                <Style.TextLink onClick={() => history.push('/produtos/1')}>Produtos</Style.TextLink>
                <Style.TextLink onClick={() => history.push('/blog')}>Blog</Style.TextLink>
                <Style.TextLink onClick={() => history.push('/area-profissionais')}>
                  Profissionais da saúde
                </Style.TextLink>
                <Style.TextLink onClick={() => history.push('/area-parceiros')}>Parceiros</Style.TextLink>
                <Style.TextLink onClick={() => history.push('/politica-de-privacidade')}>
                  Políticas de privacidade
                </Style.TextLink>
                <Style.TextLink onClick={() => history.push('/perguntas-frequentes')}>FAQ</Style.TextLink>
              </Style.QuickAccessContent>
            </Style.QuickAccessContainer>
          </When>
        </div>

        <div className="container-links max-w-60">
          <div className="mb-9 hidden md:block">
            <Style.Title className="mb-8">Meios de pagamentos:</Style.Title>
            <div className="flex flex-wrap gap-4">
              <img src={paymentVisa} alt="meios de pagamento" height="28" />
              <img src={paymentMaster} alt="meios de pagamento" height="28" />
              <img src={paymentAmex} alt="meios de pagamento" height="28" />
              <img src={paymentElo} alt="meios de pagamento" height="28" />
              <img src={paymentHiper} alt="meios de pagamento" height="28" />
              <img src={paymentPix} alt="meios de pagamento" height="28" />
              <img src={paymentBoleto} alt="meios de pagamento" height="28" />
            </div>
          </div>
          <div className="mb-9">
            <Style.Title className="mb-4">Fale conosco:</Style.Title>
            <Style.TextLink href="mailto:faleconosco@cannect.com.br">faleconosco@cannect.com.br</Style.TextLink>
          </div>
          <div className="hidden md:block">
            <Style.Title className="mb-4">Redes sociais:</Style.Title>
            <div className="flex gap-3">
              <a href="https://www.facebook.com/cannect.life/" target="_blank" rel="noreferrer">
                <img src={facebook} alt="logo-facebook" width={36} height={36} />
              </a>
              <a href="https://twitter.com/CannectLife" target="_blank" rel="noreferrer">
                <img src={twitter} alt="logo-twitter" width={36} height={36} />
              </a>
              <a href="https://www.instagram.com/cannect.life/" target="_blank" rel="noreferrer">
                <img src={instagram} alt="logo-instagram" width={36} height={36} />
              </a>
              <a
                href="https://www.linkedin.com/company/cannect-saude/"
                target="_blank"
                rel="noreferrer"
                className="pl-[2.5px] pt-[2.5px]">
                <img src={linkedin} alt="logo-linkedin" width={31} height={31} />
              </a>
            </div>
          </div>
        </div>

        <div className="container-links max-w-72">
          <div className="hidden md:block">
            <Style.Title>Esteja sempre informado sobre a #revoluçãoverde</Style.Title>
            <Style.FormContent className="_form_60" />
          </div>

          <div>
            <Style.Title>Fazemos parte do programa:</Style.Title>

            <div className="mt-4 flex items-center gap-6">
              <a
                href="https://www.reclameaqui.com.br/empresa/cannect-servicos-de-internet-s-a/?utm_source=referral&utm_medium=embbed&utm_campaign=reputacao&utm_term=vertical"
                target="_blank"
                rel="noreferrer">
                <img src={reclame} alt="reclame nota alta" />
              </a>
              <img src={endeavorLogo} alt="endeavor logo" width="145" height="51" />
            </div>
          </div>
        </div>

        <div className="block md:hidden">
          <Style.Title className="mb-4">Redes sociais:</Style.Title>
          <div className="flex gap-3">
            <a href="https://www.facebook.com/cannect.life/" target="_blank" rel="noreferrer">
              <img src={facebook} alt="logo-facebook" width={36} height={36} />
            </a>
            <a href="https://twitter.com/CannectLife" target="_blank" rel="noreferrer">
              <img src={twitter} alt="logo-twitter" width={36} height={36} />
            </a>
            <a href="https://www.instagram.com/cannect.life/" target="_blank" rel="noreferrer">
              <img src={instagram} alt="logo-instagram" width={36} height={36} />
            </a>
            <a
              href="https://www.linkedin.com/company/cannect-saude/"
              target="_blank"
              rel="noreferrer"
              className="pl-[2.5px] pt-[2.5px]">
              <img src={linkedin} alt="logo-linkedin" width={31} height={31} />
            </a>
          </div>
        </div>

        <div className="mb-9 block md:hidden">
          <Style.Title className="mb-8">Meios de pagamentos:</Style.Title>
          <div className="flex flex-wrap gap-4">
            <img src={paymentVisa} alt="meios de pagamento" height="28" />
            <img src={paymentMaster} alt="meios de pagamento" height="28" />
            <img src={paymentAmex} alt="meios de pagamento" height="28" />
            <img src={paymentElo} alt="meios de pagamento" height="28" />
            <img src={paymentHiper} alt="meios de pagamento" height="28" />
            <img src={paymentPix} alt="meios de pagamento" height="28" />
            <img src={paymentBoleto} alt="meios de pagamento" height="28" />
          </div>
        </div>

        <div className="mr-10 mt-10 block md:hidden xl:mt-24">
          <Style.ImgWrapper>
            <Style.Img src={logo} alt="logo-cannect" />
          </Style.ImgWrapper>
        </div>
      </Style.Content>
    </Style.Container>
  )
}

export default Footer
