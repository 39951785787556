import { useGetPrescribersHighlightedList } from '@cannect/services/resources/home'
import { useGetStrapiBrands, useGetStrapiPosts } from '@cannect/services/resources/strapi'
import { useMemo } from 'react'
import { useHistory } from 'react-router'

export const useHome = () => {
  const history = useHistory()

  const { data: highlightedPrescribers } = useGetPrescribersHighlightedList()
  const { data: postsData } = useGetStrapiPosts({
    filterQuery:
      'pagination[limit]=3&filters[$or][0][title][$contains]=saude&filters[$or][1][content][$contains]=bem-estar&populate=categories&populate=image&populate=author&sort[0]=createdAt:desc'
  })

  const { data: brandsData } = useGetStrapiBrands({
    filterQuery: 'populate=logo&pagination[pageSize]=100'
  })

  const postsList = postsData?.data

  const brandsList = useMemo(() => {
    return (
      brandsData?.data?.map((item: any) => ({
        path: `${import.meta.env.VITE_APP_STRAPI_URL}${item?.attributes?.logo?.data?.attributes?.url}`,
        onClick: () => history.push(`/marcas/${item?.attributes?.slug}`)
      })) || []
    )
  }, [brandsData])

  const handleGoTo = (path: string) => {
    history.push(path)
  }

  return {
    highlightedPrescribers,
    postsList,
    handleGoTo,
    brandsList
  }
}
