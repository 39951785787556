import { Fragment, PropsWithChildren, forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'
import { LuX } from 'react-icons/lu'
import { cn } from '@cannect/lib/utils'
import { ClassValue } from 'clsx'
import { Button } from '../Button'

type TModalClasses = {
  containerClassName?: ClassValue
}
export type TModal = PropsWithChildren<{
  hiddenCloseButton?: boolean // if true will hide and wil not close when click outside
  classNames?: TModalClasses
}>

export type TModalActions = {
  open(): void
  close(): void
}

export const Modal = forwardRef<TModalActions, TModal>(({ hiddenCloseButton = false, children, classNames }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const { containerClassName } = classNames || {}
  const cancelButtonRef = useRef(null)

  const handleCloseModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleOpenModal = useCallback(() => {
    setIsOpen(true)
  }, [])

  useImperativeHandle(ref, () => ({
    open: handleOpenModal,
    close: handleCloseModal
  }))

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        data-testid="modal"
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={!hiddenCloseButton ? handleCloseModal : () => {}}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 top-[110px] z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 lg:mx-auto">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel
                className={cn(
                  'relative min-h-fit w-fit max-w-[90%] transform rounded bg-white p-8 py-8 text-left shadow-xl transition-all',
                  containerClassName
                )}>
                <div className="absolute right-3 top-3">
                  <Button
                    unstyled
                    data-testid="modal_btn_close"
                    ref={cancelButtonRef}
                    onClick={handleCloseModal}
                    className={` ${hiddenCloseButton && 'hidden'}`}>
                    <LuX className="text-gray-800" size={24} />
                  </Button>
                </div>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
})
