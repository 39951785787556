/* eslint-disable @typescript-eslint/no-non-null-assertion */

import styled, { css } from 'styled-components'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'
import { AnimatedInputProps } from '.'
import { temporaryTheme } from '../../../styles/theme'

export type StyledInput = {
  bgColor?: string
  color?: 'lightGrey' | 'dark'
}

type InputBoxProps = Pick<AnimatedInputProps, 'width'>

export const Container = styled.div``

export const InputBox = styled.div`
  position: relative;
  width: 250px;
  @media ${TABLET_WIDTH} {
    width: 47%;
  }
  @media ${MOBILE_WIDTH} {
    width: 100%;
  }
  .select-animated-filter {
    min-width: 182px;
    button {
      padding: 10px;
      border: 1.3px solid ${temporaryTheme.colors.whiteBorder};
      border-radius: 5px;
      color: ${temporaryTheme.colors.darkGrey};
      font-size: 1em;
      font-family: 'Nunito', sans-serif;
    }
    .fake-select-placeholder {
      display: flex;
      align-items: center;
      gap: 8px;
      color: ${temporaryTheme.colors.whiteBorder};
    }
    & * {
      font-family: 'Nunito', sans-serif;
    }
  }
`

export const SpanLayer = styled.span`
  position: absolute;
  left: 0;
  padding: 10px;
  pointer-events: none;
  font-size: 1em;
  color: ${temporaryTheme.colors.whiteBorder};
  transition: 0.5s;
  font-family: 'Nunito', sans-serif;
`

export const Input = styled.input<StyledInput>`
  ${({ bgColor, color }) => css`
    width: 100%;
    padding: 10px;
    border: 1.3px solid ${temporaryTheme.colors.whiteBorder};
    border-radius: 5px;
    background: white;
    outline: none;
    color: ${temporaryTheme.colors[color!]};
    font-size: 1em;
    font-family: 'Nunito', sans-serif;
    transition: 0.5s;

    &:valid ~ ${SpanLayer}, &:focus ~ ${SpanLayer} {
      color: ${temporaryTheme.colors[color!]};
      transform: translateX(10px) translateY(-7px);
      font-size: 0.65em;
      padding: 0 10px;
      background: ${bgColor || ' #dffffb'};
      border-radius: 8px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }

    &:valid {
      border-right: 1.3px solid ${temporaryTheme.colors.whiteBorder};
    }
  `}
`

export const IconBox = styled.div`
  position: absolute;
  top: 25%;
  right: 16px;

  svg {
    width: 22px;
    height: 22px;
    color: ${temporaryTheme.colors.whiteBorder};
  }
`
