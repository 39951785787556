import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'
import { MOBILE_WIDTH } from 'utils/constants'

export const Prices = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 12px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -36px;
    right: -36px;
    height: 0.7px;
    background-color: #d1cece;
    @media ${MOBILE_WIDTH} {
      left: -25px;
      right: -25px;
    }
  }
  * {
    font-family: 'Nunito', sans-serif;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 12px;
    color: #777777;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media ${MOBILE_WIDTH} {
    span {
      font-size: 12px;
    }
  }
`

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  span {
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-bottom: 12px;
    color: #777777;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  @media ${MOBILE_WIDTH} {
    span {
      font-size: 12px;
    }
  }
`

export const Total = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  * {
    font-family: 'Nunito', sans-serif;
  }

  .total-title {
    font-family: 'Spartan', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;

    color: #000000;
  }

  .total {
    font-family: 'Spartan', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.5rem;
    text-align: right;
  }

  @media ${MOBILE_WIDTH} {
    .total-title {
      font-size: 12px;
    }

    .total {
      font-size: 12px;
    }
  }
`
