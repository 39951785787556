import { useState } from 'react'
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { Checkbox, Typography } from 'antd'
import { authenticate } from 'usecases/user'
import { useTokenApi } from 'hooks/useTokenApi'
import { useAuth } from 'hooks/AuthContext'
import { toast } from 'react-toastify'
import { temporaryTheme } from 'styles/theme'
import InvisibleButton from 'components/InvisibleButton'
import { useScheduling } from 'hooks/useScheduling'
import * as Styles from './styles'
import Button from 'new-components/Button'
import Loading from 'components/Loading'
import { defaultsHeadersAxios } from 'services/api'
import { setUser } from 'hooks/useSessionUser'
import CustomInput from 'components/CustomInput'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { noop } from 'lodash'

interface ILoginForm {
  email: string
  password: string
  remind: boolean
}

const loginFormSchema = z.object({
  email: z.string().min(1, 'Campo obrigatório').email('Digite um e-mail válido'),
  password: z.string().min(1, 'Senha é obrigatória'),
  remind: z.boolean()
})

export default function LoginForm() {
  const { onForgotPassword, nextCheckoutStep, setCheckoutFormState } = useScheduling()
  const { getRoles } = useAuth()
  const { setTokenApi } = useTokenApi()
  const loginForm = useForm<ILoginForm>({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      email: '',
      password: '',
      remind: false
    },
    resolver: zodResolver(loginFormSchema)
  })
  const { handleSubmit, control } = loginForm
  const { setValue: setValueOnParentForm } = useFormContext()
  const [loading, setLoading] = useState(false)

  const handleLogin: SubmitHandler<ILoginForm> = async formData => {
    try {
      setLoading(true)
      const { email, password } = formData
      const response = await authenticate({ email, password })
      setTokenApi(response.token)
      defaultsHeadersAxios(response.token)
      await setUser(response.user)
      await setValueOnParentForm('patient', response.user)
      getRoles()

      nextCheckoutStep()
    } catch (e: any) {
      toast.error('E-mail ou senha incorretos')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styles.LoginContainer>
      <Loading loading={loading} />

      <Styles.ContentWrapper>
        <FormProvider {...loginForm}>
          <Styles.LoginForm onSubmit={handleSubmit(handleLogin)}>
            <CustomInput outlined name="email" label="E-mail *" placeholder="Digite seu e-mail aqui" />
            <CustomInput outlined name="password" label="Senha *" type="password" placeholder="Digite sua senha aqui" />

            <Styles.ForgotPasswordContainer>
              <InvisibleButton
                type="button"
                color={temporaryTheme.colors.link}
                style={{ textDecoration: 'underline' }}
                onClick={onForgotPassword}
              >
                Esqueci minha senha
              </InvisibleButton>

              <Controller
                name="remind"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Styles.StyledCheckbox checked={value} onChange={event => onChange(event.target.checked)}>
                    <Typography.Text>Lembrar do meu acesso</Typography.Text>
                  </Styles.StyledCheckbox>
                )}
              />
            </Styles.ForgotPasswordContainer>

            <ReCAPTCHA
              sitekey="6LfI-rcjAAAAAGtDe1_H4sxhfEXy-gjGIXWY_VL_"
              size="invisible"
              badge="bottomleft"
              onChange={() => noop}
            />
            <Button className="login-button" background="green" type="submit">
              Acessar conta
            </Button>
          </Styles.LoginForm>
        </FormProvider>
      </Styles.ContentWrapper>
    </Styles.LoginContainer>
  )
}