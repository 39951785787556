/* eslint-disable no-plusplus */
import { semImagem } from 'assets/svg'
import { createContext, useContext, useEffect, useState } from 'react'
import api from 'services/api'
import { formatPhone, clearMask } from 'utils/formatters'
import { generateRandomNumber } from 'utils/randomNumberGenerator'
import useQueryString from 'hooks/useQueryString'
import {
  FetchClinicLogoResponse,
  FetchLogoPositionResponse
} from 'pages/Account/components/pages/PrescriptionConfig/hooks/usePrescriptionConfig'
import useStorageState from 'hooks/useStorageState'
import {
  AdministrationWay,
  CalculationsBodyObject,
  CanContainerThc,
  Cannabinoid,
  CannectAssistantContextValues,
  CannectAssistantProviderProps,
  CreateRecipeResponse,
  DigitalSignatureToken,
  FetchAlreadyKnowProductsResponse,
  FetchNeedHelpProductsResponse,
  FetchPatientByCpfResponse,
  FetchProductsCalculationsResponse,
  IRecipeProduct,
  LogicalOperatorsBetweenProducts,
  Path,
  PatientFields,
  PrescriberFields,
  Prescription,
  Product,
  Protocol,
  Recipe,
  RecipeOptionsFields,
  ReportFields,
  StepOfHowToUse,
  TherapeuticPlanFields
} from './types'
import { PRESCRIPTION_INITIAL_STATE } from './constants'
import { addMinutes, isAfter } from 'date-fns'
import { useTokenApi } from 'hooks/useTokenApi'
import { useHistory } from 'react-router'

const CannectAssistantContext = createContext<CannectAssistantContextValues>({} as CannectAssistantContextValues)

export function CannectAssistantProvider({ children }: CannectAssistantProviderProps) {
  const { tokenApi } = useTokenApi()
  const history = useHistory()
  // General states
  const [step, setStep] = useState(0)
  const [maxStep, setMaxStep] = useState(0)
  const [path, setPath] = useState<Path>('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  // Products
  const [products, setProducts] = useState<Product[]>([])
  const [suggestedProduct, setSuggestedProduct] = useState<Product>()
  // NeedHelp states
  const [administrationWay, setAdministrationWay] = useState<AdministrationWay>()
  const [isCbdPredominant, setIsCbdPredominant] = useState(false)
  const [isThcPredominant, setIsThcPredominant] = useState(false)
  const [isCbgPredominant, setIsCbgPredominant] = useState(false)
  const [isCbdEqualsThcPredominant, setIsCbdEqualsThcPredominant] = useState(false)
  const [canContainOtherCannabinoid, setCanContainOtherCannabinoid] = useState<boolean>()
  const [canContainThc, setCanContainThc] = useState<CanContainerThc>()
  const [canContainCbd, setCanContainCbd] = useState<boolean>()
  const [dailyDosage, setDailyDosage] = useState('')
  const [dailyFrequency, setDailyFrequency] = useState('')
  const [isChosenQuestionsFinished, setIsChosenQuestionsFinished] = useState(false)
  const [indicatedType, setIndicatedType] = useState('')
  const [anotherSignature, setAnotherSignature] = useState(false)
  // Prescriptions
  const [logicalOperatorsBetweenProducts, setLogicalOperatorsBetweenProducts] = useState<
    LogicalOperatorsBetweenProducts[]
  >([])
  const [prescription, setPrescription] = useStorageState<Prescription>(
    '@CANNECT:PRESCRIPTION',
    PRESCRIPTION_INITIAL_STATE
  )
  const [createdRecipe, setCreatedRecipe] = useState<Recipe>()
  const { filters } = useQueryString()

  // This is to scroll top every time user changes the step
  useEffect(() => {
    const element = document.getElementById('page-wrapper')

    if (element) {
      element.scrollTo(0, 100)
    }
  }, [step])

  // Reset all if user logout
  const resetConextValues = () => {
    clearPrescription()
    setStep(0)
    setIsChosenQuestionsFinished(false)
    setIndicatedType('')
    setAnotherSignature(false)
    setLogicalOperatorsBetweenProducts([])
    setCreatedRecipe(undefined)
  }

  useEffect(() => {
    if (!tokenApi) {
      resetConextValues()
    }
  }, [tokenApi])

  useEffect(() => {
    if ((prescription?.products && prescription?.products?.length > 0) || prescription?.patient?.cpf) {
      if (!prescription?.createdAt) {
        setPrescription(prevState => ({
          ...prevState,
          createdAt: new Date()
        }))
      }
    }
  }, [prescription])

  useEffect(() => {
    if (prescription?.createdAt) {
      const createdAtDate = new Date(prescription?.createdAt)
      const thirtyMinutesLater = addMinutes(createdAtDate, 30)

      if (isAfter(new Date(), thirtyMinutesLater)) {
        resetConextValues()
      }
    }
  }, [prescription, history?.location.pathname])

  // This is to verify user's session and redirect to login if necessary
  useEffect(() => {
    async function fetchCurrentSession() {
      try {
        await api.get('/session')
      } catch (err: any) {
        console.error(err)
      }
    }
    if (step === 3) {
      fetchCurrentSession()
    }
  }, [step])

  useEffect(() => {
    if (step > maxStep) {
      setMaxStep(step)
    }

    if (step === 0) {
      setPath('')
      setMaxStep(0)
      resetInitialQuestionsStates()
    }

    if (step === 1 && path === 'needHelp') {
      setProducts([])
    }

    if (step === 2) {
      setMaxStep(2)
    }
  }, [step])

  useEffect(() => {
    if (step < maxStep) {
      setMaxStep(step)
    }
  }, [prescription])

  useEffect(() => {
    if (!isCbdEqualsThcPredominant && canContainThc !== 'moderate' && canContainThc !== 'low') {
      setIsChosenQuestionsFinished(false)
    }

    if (!isCbdPredominant) {
      setCanContainThc(undefined)
    }

    setCanContainOtherCannabinoid(undefined)
    setCanContainCbd(undefined)
  }, [
    administrationWay,
    isCbdPredominant,
    isThcPredominant,
    isCbgPredominant,
    isCbdEqualsThcPredominant,
    canContainThc
  ])

  useEffect(() => {
    if (maxStep >= 2) {
      setMaxStep(1)
    }
  }, [
    administrationWay,
    isCbdPredominant,
    isThcPredominant,
    isCbgPredominant,
    isCbdEqualsThcPredominant,
    canContainOtherCannabinoid,
    canContainThc,
    canContainCbd,
    dailyDosage,
    dailyFrequency
  ])

  // This is to redirect automatically to step 2 and path to AlreadyKnow if there's a template in the qs
  useEffect(() => {
    if (filters?.template) {
      setStep(3)
      setPath('alreadyKnow')
    }
  }, [filters])

  function resetInitialQuestionsStates() {
    setAdministrationWay(undefined)
    setIsCbdPredominant(false)
    setIsThcPredominant(false)
    setIsCbgPredominant(false)
    setIsCbdEqualsThcPredominant(false)
    setDailyDosage('')
    setDailyFrequency('')
    setProducts([])
    setPage(1)
  }

  async function fetchAlreadyKnowProducts(): Promise<boolean> {
    setLoading(true)
    try {
      const response = await api.get<FetchAlreadyKnowProductsResponse>(`/all_products`)

      const newProducts: Product[] = []
      response.data?.products.forEach((product: any) => {
        const newProduct = {
          id: product.id,
          name: product.translated_name,
          image: product.media[0] ? product.media[0].path : semImagem,
          posology: '',
          bottlesPerYear: '',
          estimatedMonthlyCost: '',
          costPerMg: '',
          unitaryPriceUsd: product.currency === 'USD' ? product.price : undefined,
          unitaryPriceBrl: product.currency === 'USD' ? (product.real_price as number) : product.price,
          cannectChoice: product.cannect_choice,
          includedInPrescription: true,
          howToUse: '',
          stepsOfHowToUse: [],
          mgPerDay: '',
          stock: product.stock,
          timesPerDay: '',
          administrationWay:
            product.route_of_administration === 'Sistêmico' ? 'systemic' : ('topic' as AdministrationWay),
          status_assistant: product?.status_assistant
        }

        newProducts.push(newProduct)
      })

      setProducts(newProducts)
    } catch (err: any) {
      return false
    } finally {
      setLoading(false)
    }
    return true
  }

  async function fetchProductsCalculations(): Promise<boolean> {
    setLoading(true)
    try {
      const response = await api.post<FetchProductsCalculationsResponse>(`/products_prescription`, {
        productPrescription: formatProductsCalculationsBody()
      })

      const newProducts: Product[] = []
      response.data.products.forEach(product => {
        let newMgPerDay = ''
        let newTimesPerDay = ''
        let newIncludedInPrescription = true
        let newStepsOfHowToUse: StepOfHowToUse[] = []

        const currentProduct = prescription.products.find(p => p.id === product.id)
        if (currentProduct) {
          newMgPerDay = currentProduct.mgPerDay
          newTimesPerDay = currentProduct.timesPerDay
          newIncludedInPrescription = currentProduct.includedInPrescription
          newStepsOfHowToUse = currentProduct.stepsOfHowToUse
        }
        let newProduct = {
          id: product.id,
          name: product.translated_name,
          image: product.media[0] ? product.media[0].path : semImagem,
          unitaryPriceUsd: product.currency === 'USD' ? product.price : undefined,
          unitaryPriceBrl: product.currency === 'USD' ? (product.real_price as number) : product.price,
          cannectChoice: product.cannect_choice,
          includedInPrescription: newIncludedInPrescription,
          stepsOfHowToUse: newStepsOfHowToUse,
          mgPerDay: newMgPerDay,
          timesPerDay: newTimesPerDay,
          posology: '',
          bottlesPerYear: '',
          estimatedMonthlyCost: '',
          costPerMg: '',
          howToUse: '',
          administrationWay: 'topic' as AdministrationWay,
          status_assistant: product?.status_assistant
        }
        // Product is systemic if this is true
        if (!product.calculation.message) {
          newProduct = {
            ...newProduct,
            posology: product.calculation.posology,
            bottlesPerYear:
              product.calculation.amountOfBottles === null
                ? 'Error to load'
                : product.calculation.amountOfBottles.toString(),
            estimatedMonthlyCost:
              product.calculation.costPerMonth === null ? 'Error to load' : product.calculation.costPerMonth,
            costPerMg: product.calculation.costPerMg,
            howToUse: product.calculation.posology,
            administrationWay: 'systemic',
            status_assistant: product?.status_assistant
          }
        }

        newProducts.push(newProduct)
      })

      setPrescription({ ...prescription, products: newProducts })
    } catch (err: any) {
      return false
    } finally {
      setLoading(false)
    }
    return true
  }

  function formatProductsCalculationsBody(): CalculationsBodyObject[] {
    const formattedBody: CalculationsBodyObject[] = []

    prescription.products.forEach(product => {
      formattedBody.push({
        id: product.id,
        dailyPosology: parseFloat(product.mgPerDay),
        dailyFrequency: parseFloat(product.timesPerDay)
      })
    })

    return formattedBody
  }

  async function fetchNeedHelpProducts(page: number): Promise<number> {
    setLoading(true)
    const newProducts: Product[] = []

    try {
      const params = formatProductsRequestParams()
      const response = await api.get<FetchNeedHelpProductsResponse>(`/assistant/${page}?${params}&limit=4`)

      if (page === 1) {
        setIndicatedType(response.data.message)
        setTotalPages(Math.ceil(response.data.products.count / 4))
      }

      const productStepsOfHowToUse: StepOfHowToUse[] = []
      for (let i = 0; i < Number(dailyFrequency); i++) {
        productStepsOfHowToUse.push({
          id: generateRandomNumber(),
          posology: '',
          timeToTakeMedicine: 'após o café da manhã'
        })
      }

      response.data.products.rows.forEach((product, index) => {
        products.forEach((currentProduct, currentIndex) => {
          if (currentProduct !== undefined) {
            if (currentProduct.id === product.id) {
              delete products[currentIndex]
            }
          }
        })

        let productImage = semImagem

        if (product.path) productImage = product.path
        if (product.media) productImage = product.media[0].path

        const newProduct = {
          id: product.id,
          name: product.translated_name,
          image: productImage,
          posology: product?.calculation?.posology,
          bottlesPerYear: product?.calculation?.amountOfBottles,
          estimatedMonthlyCost: product?.calculation?.costPerMonth,
          costPerMg: product?.calculation?.costPerMg,
          unitaryPriceUsd: product.currency === 'USD' ? product.price : undefined,
          unitaryPriceBrl: product.currency === 'USD' ? (product.real_price as number) : product.price,
          cannectChoice: product.cannect_choice,
          includedInPrescription: true,
          howToUse: product?.calculation?.posology,
          stepsOfHowToUse: productStepsOfHowToUse,
          mgPerDay: '',
          stock: product.stock,
          timesPerDay: '',
          administrationWay: administrationWay as AdministrationWay,
          status_assistant: product?.status_assistant
        }

        if (index === 0 && page === 1) {
          setSuggestedProduct(newProduct)
        }

        newProducts.push(newProduct)
      })
      setProducts(newProducts)

      if (response.data.products.rows.length === 0) {
        setSuggestedProduct(undefined)
      }
    } catch (err: any) {
      return 0
    } finally {
      setLoading(false)
    }
    return newProducts.length
  }

  async function createRecipe(useDigitalSignature?: boolean): Promise<boolean> {
    setLoading(true)
    let digitalSignatureParams = ''
    let digitalSignatureProvider = ''
    if (useDigitalSignature) {
      if (localStorage.getItem('@CANNECT_BIRDID_TOKEN')) {
        const birdIdToken: DigitalSignatureToken = JSON.parse(localStorage.getItem('@CANNECT_BIRDID_TOKEN') as string)
        digitalSignatureParams = `?signature_token=${birdIdToken.token}`
        digitalSignatureProvider = 'bird_id'
      }
      if (localStorage.getItem('@CANNECT_VIDAAS_TOKEN')) {
        const vidaasToken: DigitalSignatureToken = JSON.parse(localStorage.getItem('@CANNECT_VIDAAS_TOKEN') as string)
        digitalSignatureParams = `?signature_token=${vidaasToken.token}`
        digitalSignatureProvider = 'vidaas'
      }
    }
    try {
      const response = await api.post<CreateRecipeResponse>(`/recipe${digitalSignatureParams}`, {
        cpf_user: clearMask(prescription?.patient?.cpf),
        name_user: prescription.patient.name,
        phone_user: `+55${prescription.patient.phone}`,
        email_user: prescription.patient.email,
        protocol: prescription.therapeuticPlan.protocol,
        laudo_name_sickness: prescription.report.deseaseName,
        laudo_cd_cid: prescription.report.cid,
        laudo_description: prescription.report.caseDescription,
        laudo_justify: prescription.report.justificationForUsingCannabinoid,
        laudo_previus_treatments: prescription.report.previousTreatments,
        sendSms: prescription.recipeOptions.sendSmsToPatient,
        recommendation: prescription.recomendations,
        logoPosition: prescription.prescriber.logoPosition,
        products: formatPrescriptionProducts(),
        useDigitalSignature,
        provider: digitalSignatureProvider
      })

      if (response) {
        await api.post(`/recipe_email`, {
          patient_email: prescription.patient.email,
          patient_name: prescription.patient.name,
          token: response.data.token
        })
      }
      setCreatedRecipe(response.data)
      window.open(response?.data?.link_pdf)
      return true
    } catch (err: any) {
      return false
    } finally {
      setLoading(false)
    }
  }

  function formatPrescriptionProducts(): IRecipeProduct[] {
    const selectedPrescriptionProducts = prescription.products.filter(product => product.includedInPrescription)

    const newRecipeRequestProducts: IRecipeProduct[] = []
    let newRecipeRequestProduct: IRecipeProduct = {
      product_id: 0,
      prescription: '',
      required_bottles: 0,
      alternatives: []
    }
    selectedPrescriptionProducts.forEach((product, index) => {
      if (index === 0) {
        newRecipeRequestProduct = {
          product_id: product.id,
          prescription: product.howToUse,
          required_bottles: Number(product.bottlesPerYear),
          alternatives: []
        }
        return
      }
      if (logicalOperatorsBetweenProducts[index - 1] === 'or') {
        newRecipeRequestProduct = {
          ...newRecipeRequestProduct,
          alternatives: [
            ...newRecipeRequestProduct.alternatives,
            {
              product_id: product.id,
              prescription: product.howToUse,
              required_bottles: Number(product.bottlesPerYear)
            }
          ]
        }
      } else {
        newRecipeRequestProducts.push(newRecipeRequestProduct)
        newRecipeRequestProduct = {
          product_id: product.id,
          prescription: product.howToUse,
          required_bottles: Number(product.bottlesPerYear),
          alternatives: []
        }
      }
    })
    newRecipeRequestProducts.push(newRecipeRequestProduct)

    return newRecipeRequestProducts
  }

  function formatProductsRequestParams() {
    let params = `administrationWay=${administrationWay}&predominantCannabinoid=`

    // Defining predominant cannabinoid
    if (isCbdPredominant) {
      params += `cbd&canContainThc=${canContainThc}`

      if (canContainThc === 'no') {
        params += `&canContainOtherCannabinoid=${canContainOtherCannabinoid}`
      }
    } else if (isThcPredominant) {
      params += `thc&canContainCbd=${canContainCbd}`
    } else if (isCbgPredominant) {
      params += `cbg&canContainOtherCannabinoid=${canContainOtherCannabinoid}`
    } else if (isCbdEqualsThcPredominant) {
      params += 'cbd:thc'
    }

    // Defining posology and frequency
    if (administrationWay === 'systemic') {
      params += `&dailyPosology=${dailyDosage}&dailyFrequency=${dailyFrequency}`
    }

    return params
  }

  async function fetchPatientByCpf(cpf: string) {
    setLoading(true)
    try {
      const response = await api.get<FetchPatientByCpfResponse>(`/recipe/user/${cpf}`)

      setPrescription(state => ({
        ...state,
        patient: {
          cpf: state.patient.cpf,
          name: response?.data?.user?.name,
          phone: formatPhone(response?.data?.user?.phone),
          email: response?.data?.user?.email,
          recipes: response?.data?.user?.recipes
        }
      }))
    } catch (err: any) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  async function fetchPrescriberData() {
    try {
      const fetchLogoPosition = api.get<FetchLogoPositionResponse>(`/prescriber/logo-position`)
      const fetchClinicLogo = api.get<FetchClinicLogoResponse>(`/prescriber/prescriber_clinic_logo`)

      const [logoPositionResponse, clinicLogoResponse] = await Promise.all([fetchLogoPosition, fetchClinicLogo])

      setPrescription(state => ({
        ...state,
        prescriber: {
          logoPosition: logoPositionResponse.data.logoPosition,
          profilePicture: clinicLogoResponse.data.clinic_logo ?? ''
        }
      }))
    } catch (err: any) {
      console.error(err)
    }
  }

  function handlePredominantCannabinoidChange(cannabinoid: Cannabinoid) {
    switch (cannabinoid) {
      case 'cbd':
        setIsCbdPredominant(true)
        setIsCbdEqualsThcPredominant(false)
        setIsCbgPredominant(false)
        setIsThcPredominant(false)
        break
      case 'thc':
        setIsCbdPredominant(false)
        setIsCbdEqualsThcPredominant(false)
        setIsCbgPredominant(false)
        setIsThcPredominant(true)
        break
      case 'cbg':
        setIsCbdPredominant(false)
        setIsCbdEqualsThcPredominant(false)
        setIsCbgPredominant(true)
        setIsThcPredominant(false)
        break
      case 'cbdEqualsThc':
        setIsCbdPredominant(false)
        setIsCbdEqualsThcPredominant(true)
        setIsCbgPredominant(false)
        setIsThcPredominant(false)
        break
      default:
        break
    }
  }

  function changeProductHowToUse(id: number, howToUse: string) {
    setPrescription(state => {
      const newPrescription = {
        ...state,
        products: state.products.map(product =>
          product.id === id
            ? {
                ...product,
                howToUse
              }
            : product
        )
      }
      return newPrescription
    })
  }

  function changeProductBottlesPerYear(id: number, bottlesPerYear: string) {
    setPrescription(state => {
      const newPrescription = {
        ...state,
        products: state.products.map(product =>
          product.id === id
            ? {
                ...product,
                bottlesPerYear
              }
            : product
        )
      }
      return newPrescription
    })
  }

  function changeProductMgPerDay(id: number, mgPerDay: string) {
    setPrescription({
      ...prescription,
      products: prescription.products.map(product =>
        product.id === id
          ? {
              ...product,
              mgPerDay
            }
          : product
      )
    })
  }

  function changeProductTimesPerDay(product: Product, timesPerDay: string) {
    const newStepsOfHowToUse: StepOfHowToUse[] = []
    for (let i = 0; i < Number(timesPerDay); i++) {
      newStepsOfHowToUse.push({
        id: generateRandomNumber(),
        posology: '',
        timeToTakeMedicine: 'após o café da manhã'
      })
    }

    setPrescription({
      ...prescription,
      products: prescription.products.map(prescriptionProduct =>
        prescriptionProduct.id === product.id
          ? {
              ...prescriptionProduct,
              timesPerDay,
              stepsOfHowToUse: newStepsOfHowToUse
            }
          : prescriptionProduct
      )
    })
  }

  function changeStepsOfHowToUse(product: Product, id: string, posology: string, timeToTakeMedicine: string) {
    const newStepsOfHowToUse = product.stepsOfHowToUse.map(step =>
      step.id === id
        ? {
            ...step,
            posology,
            timeToTakeMedicine
          }
        : step
    )

    setPrescription({
      ...prescription,
      products: prescription.products.map(prescriptionProduct =>
        prescriptionProduct.id === product.id
          ? {
              ...prescriptionProduct,
              stepsOfHowToUse: newStepsOfHowToUse
            }
          : prescriptionProduct
      )
    })
  }

  function saveHowToUse(product: Product) {
    let newHowToUse = ''
    product.stepsOfHowToUse.forEach(step => {
      if (step.posology.trim().length === 0) return
      newHowToUse += `${step.posology} ${step.timeToTakeMedicine}, `
    })
    if (newHowToUse.endsWith(', ')) {
      newHowToUse = newHowToUse.slice(0, newHowToUse.length - 2)
    }

    setPrescription({
      ...prescription,
      products: prescription.products.map(prescriptionProduct =>
        prescriptionProduct.id === product.id
          ? {
              ...prescriptionProduct,
              howToUse: newHowToUse
            }
          : prescriptionProduct
      )
    })
  }

  function changeProductIncludedInPrescription(id: number) {
    setPrescription({
      ...prescription,
      products: prescription.products.map(product =>
        product.id === id
          ? {
              ...product,
              includedInPrescription: !product.includedInPrescription
            }
          : product
      )
    })
  }

  function addProductToPrescription(product: Product) {
    setPrescription(state => {
      const existingProductIndex = state.products.findIndex(p => p.id === product.id)

      if (existingProductIndex !== -1) {
        return state // Product is already in the prescription
      }

      const newProduct = {
        ...product,
        includedInPrescription: true
      }

      const newPrescription = {
        ...state,
        products: [...state.products, newProduct]
      }

      return newPrescription
    })
  }

  function addProductsToPrescription(products: Product[]) {
    setPrescription(prevPrescription => {
      const newPrescription = {
        ...prevPrescription,
        products: [...prevPrescription.products, ...products]
      }
      return newPrescription
    })
  }

  function removeProductFromPrescription(id: number) {
    setPrescription(state => {
      const newPrescription = {
        ...state,
        products: state.products.filter(product => product.id !== id)
      }
      return newPrescription
    })
  }

  function changeIsTemplateApplied(isTemplateApplied: boolean) {
    setPrescription(prevPrescription => ({
      ...prevPrescription,
      isTemplateApplied
    }))
  }

  function clearPrescription() {
    setPrescription(PRESCRIPTION_INITIAL_STATE)
  }

  function changePrescriptionOrder(
    productToChangeOrder: Product,
    indexToInsert: number,
    currentIndexOfProduct: number
  ) {
    setPrescription(state => {
      const newPrescriptionProducts = [...state.products]
      newPrescriptionProducts.splice(currentIndexOfProduct, 1)
      newPrescriptionProducts.splice(indexToInsert, 0, productToChangeOrder)

      const newPrescription = {
        ...state,
        products: newPrescriptionProducts
      }

      return newPrescription
    })
  }

  function changePrescriptionRecomendation(recomendations: string) {
    setPrescription(prevPrescription => {
      const newPrescription = {
        ...prevPrescription,
        recomendations
      }

      return newPrescription
    })
  }

  function changePrescriptionReport(fieldToChange: ReportFields, newValue: string) {
    setPrescription({
      ...prescription,
      report: {
        ...prescription.report,
        [fieldToChange]: newValue
      }
    })
  }

  function changePrescriptionPatient(fieldToChange: PatientFields, newValue: string) {
    setPrescription(state => ({
      ...state,
      patient: {
        ...state.patient,
        [fieldToChange]: newValue
      }
    }))
  }

  function changePrescriptionTherapeuticPlan(fieldToChange: TherapeuticPlanFields, newValue: boolean | Protocol) {
    setPrescription({
      ...prescription,
      therapeuticPlan: {
        ...prescription.therapeuticPlan,
        [fieldToChange]: newValue
      }
    })
  }

  function changeWholePrescriptionPatient({
    cpf,
    name,
    phone,
    email
  }: {
    cpf: string
    name: string
    phone: string
    email: string
  }) {
    setPrescription(state => ({
      ...state,
      patient: {
        ...state.patient,
        cpf,
        name,
        phone,
        email
      }
    }))
  }

  function changePrescriptionPrescriber(fieldToChange: PrescriberFields, newValue: string) {
    setPrescription({
      ...prescription,
      prescriber: {
        ...prescription.prescriber,
        [fieldToChange]: newValue
      }
    })
  }

  function changePrescriptionOptions(fieldToChange: RecipeOptionsFields, newValue: boolean | number) {
    setPrescription({
      ...prescription,
      recipeOptions: {
        ...prescription.recipeOptions,
        sendSmsToPatient: true,
        [fieldToChange]: newValue
      }
    })
  }

  function changeTemplateName(templateName: string) {
    setPrescription(prevPrescription => ({
      ...prevPrescription,
      templateName
    }))
  }

  const changeDigitalSignature = (isDigitalSignature: boolean) => {
    if (isDigitalSignature) {
      setPrescription({
        ...prescription,
        recipeOptions: {
          ...prescription.recipeOptions,
          useDigitalSignature: true
        }
      })
    }
  }

  const changeProfilePicture = (pictureLogo: string) => {
    if (pictureLogo) {
      setPrescription({
        ...prescription,
        prescriber: {
          ...prescription.prescriber,
          profilePicture: pictureLogo
        }
      })
    }
  }

  const getPatientList = async ({
    search,
    limit = 6,
    page = 1
  }: {
    search?: string
    limit?: number
    page?: number
  }) => {
    const params = {
      search,
      limit,
      page
    }
    try {
      const { data } = await api.get('/prescriber/patients', {
        params
      })
      return data
    } catch (e) {
      return { success: false, message: 'Erro ao buscar lista de pacientes' }
    }
  }

  const getPatientByCpf = async (document: string) => {
    try {
      const { data } = await api.get(`/recipe/user/${document}`)

      if (data?.success) {
        const { user } = data
        setPrescription(state => ({
          ...state,
          patient: {
            ...state.patient,
            cpf: user?.cpf,
            email: user?.email,
            phone: user?.phone,
            name: user?.name,
            recipes: user?.recipes
          }
        }))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const changeNewProductList = async (recipeId: string) => {
    try {
      const { data } = await api.get(`/recipe/${recipeId}`)
      const productList = data?.recipe?.Products || []
      const description = data?.recipe?.recommendation

      if (data.success && !!recipeId) {
        const newProducts = productList.map((product: any) => ({
          id: product.id,
          name: product.translated_name,
          image: product.media[0] ? product.media[0].path : semImagem,
          posology: '',
          bottlesPerYear: product?.cn_recipe_products?.required_bottles,
          estimatedMonthlyCost: '',
          costPerMg: '',
          unitaryPriceUsd: product.currency === 'USD' ? product.price : undefined,
          unitaryPriceBrl: product.currency === 'USD' ? (product.real_price as number) : product.price,
          cannectChoice: product.cannect_choice,
          includedInPrescription: true,
          howToUse: product?.cn_recipe_products?.prescription,
          stepsOfHowToUse: [],
          mgPerDay: '',
          stock: product.stock,
          timesPerDay: '',
          administrationWay:
            product.route_of_administration === 'Sistêmico' ? 'systemic' : ('topic' as AdministrationWay),
          status_assistant: product?.status_assistant
        }))
        setPrescription({
          ...prescription,
          products: newProducts,
          recomendations: description
        })
      }
    } catch (e) {
      setPrescription({
        ...prescription,
        products: [],
        recomendations: ''
      })
    }
  }

  return (
    <CannectAssistantContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        changeNewProductList,
        changeProfilePicture,
        getPatientByCpf,
        getPatientList,
        anotherSignature,
        setAnotherSignature,
        step,
        setStep,
        page,
        setPage,
        totalPages,
        path,
        setPath,
        loading,
        setLoading,
        maxStep,
        administrationWay,
        setAdministrationWay,
        logicalOperatorsBetweenProducts,
        setLogicalOperatorsBetweenProducts,
        isCbdPredominant,
        isThcPredominant,
        isCbgPredominant,
        isCbdEqualsThcPredominant,
        handlePredominantCannabinoidChange,
        canContainOtherCannabinoid,
        setCanContainOtherCannabinoid,
        createdRecipe,
        canContainThc,
        setCanContainThc,
        canContainCbd,
        setCanContainCbd,
        dailyDosage,
        setDailyDosage,
        dailyFrequency,
        setDailyFrequency,
        indicatedType,
        isChosenQuestionsFinished,
        setIsChosenQuestionsFinished,
        changeProductHowToUse,
        changeProductMgPerDay,
        changeProductTimesPerDay,
        changeStepsOfHowToUse,
        saveHowToUse,
        changeProductIncludedInPrescription,
        changePrescriptionOrder,
        changePrescriptionRecomendation,
        changePrescriptionReport,
        changePrescriptionPatient,
        changePrescriptionTherapeuticPlan,
        changePrescriptionPrescriber,
        changePrescriptionOptions,
        changeIsTemplateApplied,
        changeTemplateName,
        suggestedProduct,
        setSuggestedProduct,
        changeWholePrescriptionPatient,
        fetchAlreadyKnowProducts,
        fetchNeedHelpProducts,
        fetchProductsCalculations,
        createRecipe,
        fetchPatientByCpf,
        fetchPrescriberData,
        products,
        prescription,
        addProductToPrescription,
        addProductsToPrescription,
        removeProductFromPrescription,
        clearPrescription,
        changeProductBottlesPerYear,
        changeDigitalSignature
      }}
    >
      {children}
    </CannectAssistantContext.Provider>
  )
}

export function useCannectAssistant(): CannectAssistantContextValues {
  return useContext(CannectAssistantContext)
}
