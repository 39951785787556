import { ReactNode } from 'react'
import { svgCloseIcon } from 'assets/svg/index'
import * as Style from './styles'

interface FilterCardProps {
  onClick: () => void
}
export default function Button({ onClick }: FilterCardProps) {
  return (
    <Style.ButtonContainer type="button" onClick={onClick}>
      <img src={svgCloseIcon} alt="X" />
    </Style.ButtonContainer>
  )
}
