import styled from 'styled-components'
import { theme } from 'design-cannect'
import { MOBILE_WIDTH, TABLET_WIDTH } from 'utils/constants'

export const BreadCrumpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .page {
    margin: 16px 0;
  }

  @media (${TABLET_WIDTH}) {
    padding: 0 42px;
  }

  @media (${MOBILE_WIDTH}) {
    padding: 0 30px;
  }
`

export const Container = styled.div<any>`
  width: 100%;
  display: flex;
  align-items: ${props => props.isMobile && 'center'};
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  .empty {
    width: 100%;
    display: flex;
    align-items: center;
    height: 480px;
  }
`
