import { TFileItem } from '@cannect/new-components/molecules/FileItem/types'

export type TFormats =
  | '.pdf'
  | '.png'
  | '.jpg'
  | '.jpeg'
  | '.csv'
  | '.xlsx'
  | '.mp4'
  | '.zip'
  | '.doc'
  | '.docx'
  | '.txt'

export const acceptDefaults = {
  'application/pdf': ['.pdf'],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'text/csv': ['.csv'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'video/mp4': ['.mp4'],
  'application/zip': ['.zip'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'text/plain': ['.txt']
}

export const typeErrors: Record<TErrorType, TItemError> = {
  DEFAULT: {
    title: 'Erro ao fazer upload',
    subtitle: 'Tente novamente mais tarde'
  },
  UPLOAD: {
    title: 'Arquivo não aceito',
    subtitle: 'Não foi possível fazer o upload deste tipo de arquivo.'
  },
  SIZE: {
    title: 'Limite ultrapassado',
    subtitle: 'Não é possível enviar arquivos com mais de 20MB'
  }
}

export type TAcceptDefaults = typeof acceptDefaults

export type TAcceptFormats = keyof TAcceptDefaults

export type InputFileProps = {
  onUpload?: (file: File) => void
  label?: string
  isInvalid?: boolean
  isDisabled?: boolean
  canRemove?: boolean
  canDownload?: boolean
  canView?: boolean
  message?: string
  acceptFormats?: TFormats[]
  showDescriptionFilesAccept?: boolean
  showDescriptionLimit?: boolean
  showListFiles?: boolean
  maxFiles?: number
  value?: TFileItem[]
  onChange(value: TFileItem[]): void
}

export type TErrorType = 'DEFAULT' | 'UPLOAD' | 'SIZE'

export type TItemError = {
  title: string
  subtitle: string
}
