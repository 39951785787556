import ConfirmationDialogue from 'components/ConfirmationDialogue'
import Loading from 'components/Loading'
import { Button } from 'design-cannect'
import { useCannectAssistant } from 'hooks/useCannectAssistant'
import { ButtonsContainer } from 'pages/CannectAssistant/styles'
import { useState } from 'react'
import SectionsWrapper from '../../SectionsWrapper'
import Section from '../../SectionsWrapper/Section'
import NewProductSelection from '../NewProductSelection'

export default function ProductsSelection() {
  return <NewProductSelection />
}
