import styled from 'styled-components'

export const SelectedPillContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  background: transparent;
  border: 1px solid #21976e;
  color: #21976e;

  border-radius: 999px;
  font-size: 1rem;
  width: fit-content;

  padding: 0.5rem 0.75rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: transparent;
    border: none;
    outline: none;

    cursor: pointer;
    transition: 0.3s;

    svg {
      width: 1rem;
      height: 1rem;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    &:not(:disabled):hover {
      transform: scale(1.04);
    }
  }
`
