import React from 'react'
import Text from 'components/Text'
import Heading from 'new-components/Heading'
import * as Style from './style'

function BannerFlores() {
  return (
    <Style.Container>
      <div>
        <Heading elementType="h2" defaultColor="darkGrey" fontSize="20px">
          COMUNICADO
        </Heading>
      </div>
      <Style.Content>
        A Nota Técnica nº 35/2023 da Anvisa (Agência Nacional de Vigilância Sanitária), publicada na última quarta-feira
        (19),{' '}
        <span>
          restringe a emissão de novas autorizações de importação de produtos compostos pela planta de Cannabis in
          natura ou partes de planta, incluindo flores, a partir de 20 de julho.
        </span>
        <br />
        <br />
        Tal determinação estabelece que os pedidos de produtos supracitados serão processados somente se acompanhados de
        Autorização de Importação Excepcional Anvisa com <span>data de emissão anterior a 20/07/2023</span>, devendo ter
        seu processo de importação encerrado até o dia <span>20/09/2023.</span>
        <br />
        <br />
        Recomendamos, portanto, que pacientes em posse dos documentos exigidos e válidos no contexto determinado pela
        Nota Técnica antecipem seus processos de importação, utilizando nosso canal de atendimento, evitando
        constrangimentos relacionados à disponibilidade de produto ou intercorrências do processo logístico.
        <br />
        <br />
        Você pode ler o comunicado oficial da Cannect
        <Style.Link
          href="https://s3.amazonaws.com/cannect.portal.upload/pdfs/NOTA+OFICIAL+-+FLORES+-+VF+(2).pdf"
          target="_blank"
        >
          clicando aqui.
        </Style.Link>
      </Style.Content>
    </Style.Container>
  )
}

export default BannerFlores
