/* eslint-disable import/no-unresolved */
import Texts from 'components/Text'
import { IStrapiHistory } from 'dtos/History'
import { useEffect, useState } from 'react'
import { getHistoryById } from 'usecases/histories'
import useMediaQuery from 'hooks/useMediaQuery'
import { MOBILE_WIDTH } from 'utils/constants'
import { IStrapiCategoryPosts } from 'dtos/Category'
import OtherHistories from '../OtherHistories'

import * as Styles from './styles'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

interface PostsCategoryProps {
  history: IStrapiCategoryPosts[]
  storyId?: number
}

function History({ history, storyId }: PostsCategoryProps) {
  const [historyContent, setHistoryContent] = useState<IStrapiCategoryPosts>({} as IStrapiCategoryPosts)

  const loadHistory = async (id: number) => {
    try {
      const responsePosts = await getHistoryById({ id })

      setHistoryContent(responsePosts.data)
    } catch (e) {
      console.log(e)
    }
  }

  function handleHistory(id: number) {
    loadHistory(id)
  }

  useEffect(() => {
    if (Object?.keys(historyContent)?.length === 0 && history?.length > 0) {
      setHistoryContent(history[0])
    }
  })

  useEffect(() => {
    if (storyId) {
      handleHistory(storyId)
    }
  }, [storyId])

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    <Styles.Container>
      <Styles.ContainerHistory isMobile={isMobile}>
        <div className="image">
          <img
            src={
              historyContent &&
              `${import.meta.env.VITE_APP_STRAPI_URL}${historyContent?.attributes?.image.data.attributes.url}`
            }
            alt=""
          />
        </div>

        <div className="content">
          <Texts type="h1" color="tertiary" bold>
            {historyContent && historyContent?.attributes?.title}
          </Texts>

          <div dangerouslySetInnerHTML={{ __html: historyContent && historyContent?.attributes?.content }} />
        </div>
      </Styles.ContainerHistory>

      <Texts type="h2" bold color="tertiary">
        Leia outras histórias
      </Texts>

      <Styles.ContainerOtherHistories>
        {history?.map((history, i) => {
          return (
            <button onClick={() => handleHistory(history.id)} aria-label={`${history.attributes.title}`}>
              <OtherHistories url={history?.attributes.image.data.attributes.url} />
            </button>
          )
        })}
      </Styles.ContainerOtherHistories>
    </Styles.Container>
  )
}

export default History
