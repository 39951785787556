import { FetchUserResponse, User } from './types'

export const INITIAL_USER: User = {
  id: 0,
  uuid: '',
  name: '',
  email: '',
  phone: '',
  cellphone: '',
  nationality: 'brasileira',
  gender_identity: '',
  preferred_name: '',
  registration_type: 1,
  expertises: [],
  listed_prescriber: false,
  address: '',
  rg: '',
  birthday: '',
  rne: '',
  cpf: '',
  photo: '',
  accepted_terms_of_use: false
}

export const fromResponseUserToUser = (data: FetchUserResponse): User => {
  return {
    id: data.user.id,
    uuid: data.user.uuid,
    accepted_terms_of_use: data.user.accepted_terms_of_use,
    name: data.person.name,
    email: data.user.email,
    phone: data.person.phone,
    cellphone: data.person.whatsapp,
    preferred_name: data.person.preferred_name,
    registration_type: data.prescriber !== null ? 2 : 1,
    expertises: data.prescriber !== null ? data.prescriber.expertises : [],
    listed_prescriber: data.prescriber !== null ? data.prescriber.listed : false,
    gender_identity: data.person.gender_identity,
    address: data.person.address,
    birthday: data.person.birthday,
    nationality: data.person.nationality,
    cpf: data.person.cpf,
    photo: data.user.avatar,
    rg: data.person.rg,
    rne: data.person.rne,
    prescriber:
      data.prescriber !== null
        ? {
            id: data.prescriber.id,
            council: data.prescriber.council,
            nr_council: data.prescriber.nr_council,
            uf_council: data.prescriber.uf_council,
            listed: data.prescriber.listed,
            already_prescribed: data.prescriber.already_prescribed
          }
        : undefined
  }
}
