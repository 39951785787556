import Button from 'components/Button'
import Input from 'components/Input'
import Select from 'components/Select'
import { FormEvent, useEffect, useState } from 'react'
import { pngVidaaSLogo } from 'assets/img'
import { clearMask, formatCpf, keepOnlyDigits } from 'utils/formatters'
import api from 'services/api'
import Loading from 'components/Loading'
import { DigitalSignatureToken } from 'hooks/useCannectAssistant/types'
import * as Style from './styles'

const expirationOptions = [
  {
    value: 3600, // expiração em segundos: 1 hora * 60 minutos * 60 segundos
    label: 'Expirar login em 1 hora'
  },
  {
    value: 7200, // expiração em segundos: 2 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 2 horas'
  },
  {
    value: 14400, // expiração em segundos: 4 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 4 horas'
  },
  {
    value: 28800, // expiração em segundos: 8 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 8 horas'
  },
  {
    value: 43200, // expiração em segundos: 12 horas * 60 minutos * 60 segundos
    label: 'Expirar login em 12 horas'
  }
]

const VIDAAS_ID_TOKEN = '@CANNECT_VIDAAS_TOKEN'

type Expiration = 3600 | 7200 | 14400 | 28800 | 43200

type VidaasProps = {
  onSign: (success: boolean) => void
}

type FetchAuthorizationResponse = {
  code: string
}

type FetchAuthenticationResponse = {
  token: string
}

export default function VidaaS({ onSign }: VidaasProps) {
  const [expirationTime, setExpirationTime] = useState<Expiration>(3600)
  const [cpf, setCpf] = useState('')
  const [vidaasToken, setVidaasToken] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('@CANNECT_SIGNED_USER')) {
      const connectedUser = JSON.parse(localStorage.getItem('@CANNECT_SIGNED_USER') as string)
      setCpf(connectedUser.cpf)
      handleSendNotification(connectedUser.cpf)
    }
  }, [])

  async function handleSignature(event?: FormEvent) {
    if (event) {
      event.preventDefault()
    }
    setLoading(true)
    try {
      const response = await api.post<FetchAuthenticationResponse>(`/signature_authentication`, {
        username: clearMask(cpf),
        password: vidaasToken,
        lifetime: expirationTime,
        provider: 'vidaas',
        qrcode: false
      })
      const tokenObject = {
        token: response.data.token,
        expiration: calculateExpiration()
      }
      localStorage.setItem(VIDAAS_ID_TOKEN, JSON.stringify(tokenObject))
      onSign(true)
    } catch (err) {
      console.error(err)
      onSign(false)
    }
    setLoading(false)
  }

  async function handleSendNotification(doctorCpf: string) {
    setLoading(true)
    try {
      const response = await api.post<FetchAuthorizationResponse>(`/recipe/signature/authorization`, {
        username: doctorCpf,
        provider: 'vidaas'
      })
      console.log(response)
      setVidaasToken(response.data.code.slice(5))
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
  }

  function calculateExpiration() {
    let currentDate = new Date().getTime()
    currentDate += expirationTime * 1000
    return currentDate
  }

  return (
    <Style.VidaasContainer onSubmit={event => handleSignature(event)}>
      <img src={pngVidaaSLogo} alt="VidaaS" />
      <h1>Validação de token</h1>
      <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
        <p>
          Abra o VIDaaS em seu dispositivo registrado para
          <br />
          autenticar o Token.
          <br />
          <br />
          Uma vez autenticado, clique no botão abaixo:
        </p>
      </div>
      <Button type="submit" style={{ marginTop: '2rem' }}>
        TOKEN VALIDADO NO MEU DISPOSITIVO
      </Button>
      {loading && <Loading loading={loading} />}
    </Style.VidaasContainer>
  )
}
