import { Badge, Button, Typography } from '@cannect/new-components/atoms'
import { THighlitedPrescriberItem } from '@cannect/services/resources/home'
import { formatToReal } from '@cannect/utils/number'
import { useHistory } from 'react-router'

export type HighlitedPrescriberCardProps = {
  prescriberDetail: THighlitedPrescriberItem
}

export const HighlitedPrescriberCard = ({ prescriberDetail }: HighlitedPrescriberCardProps) => {
  const history = useHistory()
  return (
    <div className="flex h-[280px] w-[300px] flex-col justify-between gap-2 rounded-2xl bg-gray-50 p-6 md:w-[318px]">
      <div className="flex gap-2">
        <img
          className="h-[84px] min-h-[84px] w-[84px] min-w-[84px] rounded-full object-cover md:h-[104px] md:min-h-[104px] md:w-[104px] md:min-w-[104px]"
          alt="prescriber-photo"
          src={prescriberDetail?.photo}
        />
        <div className="flex flex-col gap-4 text-left">
          <div className="min-h-24">
            <Typography.Text type="paragraphTwo" className="line-clamp-2 font-bold text-primary-900 lg:text-base">
              {prescriberDetail?.professional_name}
            </Typography.Text>
            <Typography.Text type="paragraphTwo" className="line-clamp-2 font-semibold text-gray-500">
              {prescriberDetail?.prescriber_expertises[0]?.name}
            </Typography.Text>
          </div>
          <div>
            <Typography.Text type="paragraphOne" className="font-semibold text-primary-1000">
              {formatToReal(prescriberDetail?.online_appointment_value)}
            </Typography.Text>
            <Badge variant="outline" rounded="full" className="mt-2">
              {prescriberDetail?.appointment_duration} min
            </Badge>
          </div>
        </div>
      </div>
      <Button
        className="mt-2 min-h-8 font-sans"
        size="md"
        variant="default"
        onClick={() => history.push(`agendamento?prescriber_id=${prescriberDetail?.id}`)}>
        Ver detalhes
      </Button>
    </div>
  )
}
