import { FiMinusCircle } from 'react-icons/fi'
import * as Styles from './styles'

interface SelectedPillProps {
  onClick: () => void
  label: string
  disabled: boolean
}
function SelectedPill({ onClick, label, disabled }: SelectedPillProps) {
  return (
    <Styles.SelectedPillContainer>
      {label}
      <button type="button" onClick={onClick} disabled={disabled} aria-label="Pílula selecionada">
        <FiMinusCircle color="#e24444" />
      </button>
    </Styles.SelectedPillContainer>
  )
}

export default SelectedPill
