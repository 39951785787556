import styled from 'styled-components'
import { theme } from 'design-cannect'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 20px;
`
