import { cookieIcon } from 'assets/svg'
import useMediaQuery from 'hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import * as Styles from './styles'
import { Button } from '@cannect/new-components/atoms'

function CookiesConsent() {
  const [cookiesAccept, setCookiesAccepts] = useState<boolean>(false)
  useEffect(() => {
    const cookiesInStorage = localStorage.getItem('@COOKIES_ACCEPTED')

    if (!cookiesInStorage) {
      setCookiesAccepts(false)
    } else {
      setCookiesAccepts(true)
    }
  }, [])

  const accept = () => {
    localStorage.setItem('@COOKIES_ACCEPTED', 'true')
    setCookiesAccepts(true)
  }

  const isMobile = useMediaQuery(MOBILE_WIDTH)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {cookiesAccept ? null : (
        <Styles.CookiesConsent id="cookies-consent" isMobile={isMobile}>
          <div className="cookie-bar">
            <div className="cookie-bar-content">
              <div className="header-cookies">
                <img src={cookieIcon} alt="cookie consent icon" width="59px" height="59px" />
                <h2>Esse site usa cookies</h2>
              </div>

              <p>
                Armazenamos dados temporariamente para melhorar a sua experiência de navegação. Ao utilizar nossos
                serviços, você concorda com tal monitoramento.
              </p>
              <div className="footer-cookies">
                <a href="https://www.cannect.life/politica-de-privacidade">Política de privacidade </a>
                <Button onClick={accept} size="lg">
                  Aceitar
                </Button>
              </div>
            </div>
          </div>
        </Styles.CookiesConsent>
      )}
    </>
  )
}

export default CookiesConsent
