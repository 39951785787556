/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import Input from 'components/Input'
import Texts from 'components/Text'
import TextArea from 'components/TextArea'
import api from 'services/api'
import { toast } from 'react-toastify'
import { FaSpinner } from 'react-icons/fa'
import * as Styles from './styles'

function FormSendHistory() {
  const [currentForm, setCurrentForm] = useState('')

  const [loading, setLoading] = useState(false)

  const [formValue, setFormValue] = useState<any>({
    nome: '',
    email: '',
    telefone: '',
    historia: ''
  })

  const { nome, email, telefone, historia } = formValue

  const handleValue = (target: any) => {
    const { id, value } = target
    setFormValue({ ...formValue, [id]: value })
  }

  async function handleSubmit(e: any) {
    e.preventDefault()
    const data = formValue
    try {
      const params = {
        nome: data.nome,
        email: data.email,
        telefone: data.telefone,
        historia: data.historia
      }
      setLoading(true)
      api.post('/send_history', params).then(res => {
        toast.success('Recebemos sua história')
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      toast.error('Erro ao enviar história')
    }
  }

  return (
    <Styles.Container>
      <Texts type="h2" bold color="primary">
        Quer contar a sua? Nos escreva!
      </Texts>
      <Styles.ContainerForm onSubmit={(e: any) => handleSubmit(e)}>
        <Input required placeholder="Nome" id="nome" value={nome} onChange={(e: any) => handleValue(e.target)} />
        <Input
          required
          type="email"
          placeholder="Email"
          id="email"
          value={email}
          onChange={(e: any) => handleValue(e.target)}
        />
        <Input
          required
          placeholder="Telefone"
          id="telefone"
          value={telefone}
          onChange={(e: any) => handleValue(e.target)}
        />
        <TextArea
          required
          placeholder="Nos conte sua história!"
          id="historia"
          value={historia}
          onChange={(e: any) => handleValue(e.target)}
        />
        <Styles.Button loading={loading}>
          {loading && (
            <span className="spinner">
              <FaSpinner />
            </span>
          )}
          {loading ? 'Enviando...' : 'Enviar'}
        </Styles.Button>
      </Styles.ContainerForm>
    </Styles.Container>
  )
}

export default FormSendHistory
