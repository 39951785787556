import { MdCheck } from 'react-icons/md'
import * as Styles from './styles'

interface SearchSelectOptionProps {
  label: string
  isSelected: boolean
  onClick: () => void
}

function SearchSelectOption({ label, isSelected, onClick }: SearchSelectOptionProps) {
  return (
    <Styles.SearchSelectOptionContainer isSelected={isSelected} type="button" onClick={onClick}>
      <span>{label}</span>
      {isSelected && <MdCheck />}
    </Styles.SearchSelectOptionContainer>
  )
}

export default SearchSelectOption
