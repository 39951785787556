import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
type VariantProps<T> = {
  [key: string]: T
}

export const getTailwindVariantsOptions = <T extends object>(variants: VariantProps<T>, key: keyof VariantProps<T>) => {
  return Object.keys(variants[key])
}
