import { media } from 'helpers/mediaQueryStyle'
import styled from 'styled-components'
import { MAX_PAGE_WIDTH } from 'utils/constants'

export const Container = styled.body`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  margin-top: 74px;

  @media (min-width: 768px) {
    margin-top: 106px;
  }
` 

export const Wrapper = styled.div`
  height: calc(100vh - 74px);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media (min-width: 768px) {
    height: calc(100vh - 106px);
  }
`

interface PagesWrapperProps {
  applyMaxWidth: boolean
}

export const PagesWrapper = styled.div<PagesWrapperProps>`
  height: auto;
  width: 100%;
  max-width: ${({ applyMaxWidth }) => (applyMaxWidth ? MAX_PAGE_WIDTH : '100%')};
`
